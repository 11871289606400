import styled from 'styled-components';

export const StyledMainWrapper = styled.div`
  padding-top: 8vh;
  width: 100%;
  height: 95%;
  background-color: ${(props) => props.theme.backGroundColor};
`;

export const StyledTopWrapper = styled.div`
  width: 100%;
  height: 10%;
  padding: 5%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
`;

export const StyledTopText = styled.text`
  font-size: 1rem;
  font-weight: 400;
  color: ${(props) => props.theme.fourthColor};
`;

export const StyledBottomWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
`;

export const StyledCarImgWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 3vh;
`;

export const StyledCarImg = styled.img`
  width: 80%;
  object-fit: fill;
`;
