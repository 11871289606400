import React, { memo } from 'react';
import { StyledMainWrapper, StyledAllImgWrapper, StyledAllImg } from './styles';
import { StyledSpace } from 'utils/styledSpace';

const Method = () => {
  return (
    <StyledMainWrapper>
      <StyledSpace height="2vh" />
      <StyledAllImgWrapper>
        <StyledAllImg src={process.env.PUBLIC_URL + '/icons/method.png'} />
      </StyledAllImgWrapper>
    </StyledMainWrapper>
  );
};

export default memo(Method);
