import { useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { getDateSubtract } from 'utils/dateFn';
import { IUserInfo } from 'types/User.type';
import { userInfoAtom } from 'store/user';
import { handleGetLoginUserInfo } from 'libs/apis/user.api';

const useHome = () => {
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useRecoilState<IUserInfo>(userInfoAtom);
  const userStartValidation = useRef<string>('/signin');

  const navigateToSignUpPage = () => {
    navigate(userStartValidation.current);
  };

  const navigateToLoginPage = () => {
    navigate('/signin');
  };

  const syncUserInfo = async () => {
    const userInfo = JSON.parse(String(localStorage.getItem('userInfo')));
    try {
      const userData: IUserInfo = (
        await handleGetLoginUserInfo(Number(userInfo.userId))
      ).data;
      const userDataTemp = {
        userId: userInfo.userId,
        userName: userData.userName,
        email: userData.email,
        recommendationId: userData.recommendationId,
        subscriptionIdList: userData.subscriptionIdList,
        isAdult: userData.isAdult,
        customerKey: userData.customerKey,
        cardStatus: userData.cardStatus,
      };
      setUserInfo(userDataTemp);
      localStorage.setItem('userInfo', JSON.stringify(userDataTemp));
    } catch (e: any) {}
  };

  const checkFirstTimeLogin = useCallback(async () => {
    localStorage.setItem('kind', '당월');
    const userInfo = JSON.parse(String(localStorage.getItem('userInfo')));
    if (userInfo !== null) {
      if (getDateSubtract() >= 180) {
        // 로그아웃
        userStartValidation.current = '/signin';
      } else {
        // 자동로그인
        try {
          const userData: IUserInfo = (
            await handleGetLoginUserInfo(Number(userInfo.userId))
          ).data;
          const currentDate = new Date();
          const currentDateString = currentDate.toISOString().substring(0, 10);
          const userDataTemp = {
            userId: userInfo.userId,
            userName: userData.userName,
            email: userData.email,
            recommendationId: userData.recommendationId,
            subscriptionIdList: userData.subscriptionIdList,
            isAdult: userData.isAdult,
            customerKey: userData.customerKey,
            cardStatus: userData.cardStatus,
          };
          setUserInfo(userDataTemp);
          localStorage.setItem('userInfo', JSON.stringify(userDataTemp));
          localStorage.setItem('loginDate', currentDateString);
          userStartValidation.current = '/main';
        } catch (e: any) {
          userStartValidation.current = '/signin';
        }
      }
    }
  }, [setUserInfo]);

  return {
    navigateToSignUpPage,
    checkFirstTimeLogin,
    userStartValidation,
    navigateToLoginPage,
    navigate,
    syncUserInfo,
    userInfo,
  };
};

export default useHome;
