import customAxios from 'libs/CustomAxios';
import {
  ICouponInfoListReponse,
  ICouponUserRequest,
  ICouponInfo,
  ICouponUsedAndWineInfoRequest,
} from 'types/Coupon.tpye';
import { IResponse } from 'types/Response.type';

export const handleGetCouponList = async (
  userId: number,
): Promise<ICouponInfoListReponse> => {
  const url: string = `/voucher/findbyuser?userId=${userId}`;
  const { data } = await customAxios.get(url);
  return data;
};

export const handleUseCoupon = async (
  req: ICouponUserRequest,
): Promise<IResponse> => {
  const url: string = `/voucher/use`;
  const { data } = await customAxios.post(url, req);
  return data;
};

export const handleCheckCouponUserd = async (
  userId: number,
  subscriptionId: number,
): Promise<ICouponUsedAndWineInfoRequest[]> => {
  const url: string = `/voucher/check?userId=${userId}&subscriptionId=${subscriptionId}`;
  const { data } = await customAxios.get(url);
  return data.data;
};
