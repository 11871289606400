import styled from 'styled-components';

export const StyledTabWrapper = styled.div`
  width: 100%;
  height: 8vh;
  display: flex;
  align-items: flex-start;
  margin-top: 10vh;
  position: fixed;
  top: 0;
  background-color: ${(props) => props.theme.backGroundColor};
  z-index: 999999 !important;
`;

export const StyledPageWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.backGroundColor};
`;

export const StyledMainWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.backGroundColor};
`;

export const StyledModalText = styled.div`
  font-size: 1.125rem;
`;

export const StyledTabImg1 = styled.img`
  object-fit: fill;
  width: 33%;
  border-bottom-right-radius: 16px;
`;

export const StyledTabImg2 = styled.img`
  object-fit: fill;
  width: 33%;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
`;

export const StyledTabImg3 = styled.img`
  object-fit: fill;
  width: 33%;
  border-bottom-left-radius: 16px;
`;
