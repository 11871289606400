import styled from 'styled-components';

export const StyledMainWrapper = styled.div`
  padding-top: 10vh;
  width: 100%;
  height: 95%;
  background-color: ${(props) => props.theme.backGroundColor};
`;

export const StyledTopWrapper = styled.div`
  width: 100%;
  height: 10%;
  padding: 5%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
`;

export const StyledBottomWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const StyledCarImgWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
`;

export const StyledCarImg = styled.img`
  width: 100%;
  object-fit: fill;
`;

export const StyledCardTextWrapper = styled.div`
  width: 100%;
  height: 50%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledCardTextWrapper1 = styled.div`
  width: 65%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledCardTextWrapper2 = styled.div`
  width: 35%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledCardText1 = styled.text`
  font-size: 1rem;
  font-weight: 400;
  color: #7c7c7c;
  white-space: pre-wrap;
`;

export const StyledCardText2 = styled.text`
  font-size: 1rem;
  font-weight: 900;
  color: #5c1e37;
  white-space: pre-wrap;
`;
