import styled from 'styled-components';

export const StyledMainWrapper = styled.div`
  padding-top: 10vh;
  width: 100%;
  background-color: ${(props) => props.theme.backGroundColor};
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const StyledMainTextWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledMainText = styled.text`
  font-size: 1.25rem;
  font-weight: 700;
  color: ${(props) => props.theme.fourthColor};
`;

export const StyledMainTextImg = styled.img`
  width: 70%;
  object-fit: fill;
`;

export const StyledCouponWrapper = styled.div<{ paddingCount?: boolean }>`
  /* padding-top: ${(props) => (props.paddingCount ? '15vh' : undefined)}; */
  width: 100%;
  /* height: 100%; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* min-height: 0; */
  /* overflow-y: auto; */
`;

export const StyledTopWrapper = styled.div`
  width: 100%;
  height: 10%;
  padding: 5%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
`;
