import React, {
  memo,
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
} from 'react';
import Header from 'components/Header';
import {
  StyledMainWrapper,
  StyledCarImgWrapper,
  StyledCarImg,
  StyledBottomImgWrapper,
  StyledBottomImg,
  StyledBtnImg,
  StyledBtnImgWrapper,
  StyledCardMainWrapper,
  StyledCardWrapper,
  StyledWapper,
  StyledWineImgWrapper,
  StyledWineImg,
  StyledCardLast,
  StyledTextWrapper,
  StyledTextTopWrapper,
  StyledTextBottomWrapper,
  StyledTopText,
  StyledBottomText,
} from './styles';
import useSubscription from 'hooks/useSubscription';
import { ISubscriptionInfo } from 'types/Subscription.type';
import { StyledSpace } from 'utils/styledSpace';
import PortalForOption from 'components/common/ModalForOption/PortalForOption';
import ModalForOption from 'components/common/ModalForOption';
import ModalForUse from './ModalForUse';
import PortalForUse from './ModalForUse/PortalForUse';
import ModalForUnSubscription from './ModalForUnSubscription';
import PortalForUnSubscription from './ModalForUnSubscription/PortalForUnSubscription';

interface Props {
  item: ISubscriptionInfo;
  setSelectedSubscription: Dispatch<SetStateAction<ISubscriptionInfo>>;
}

const SubscriptionDetailInfo = ({ item, setSelectedSubscription }: Props) => {
  const {
    getSubscriptionList,
    openUploadModal,
    setOpenUploadModal,
    openUploadModalForUse,
    setOpenUploadModalForUse,
    openUploadModalForUnSubscription,
    setOpenUploadModalForUnSubscription,
    userInfo,
    handlerUnSubscription,
    handlerCheckCouponUserd,
    usedCheck,
    wineInfo,
  } = useSubscription();
  const monthIndex: number = new Date().getMonth() + 1;

  useEffect(() => {
    getSubscriptionList();
    handlerCheckCouponUserd(item.subscriptionId);
  }, []);

  const ModalContent = () => {
    return (
      <PortalForOption>
        <ModalForOption
          width="50%"
          height="30%"
          isNoButton
          isYesButton
          onClose={() => setOpenUploadModal(false)}
          onNo={() => setOpenUploadModal(false)}
          onYes={() => {}}>
          {/* <StyledModalText>{'삭제하시겠습니까?'}</StyledModalText> */}
        </ModalForOption>
      </PortalForOption>
    );
  };

  const ModalContentForUse = () => {
    return (
      <PortalForUse>
        <ModalForUse
          width="100%"
          height="30%"
          onClose={() => setOpenUploadModalForUse(false)}
        />
      </PortalForUse>
    );
  };

  const ModalContentForUnSubscription = () => {
    return (
      <PortalForUnSubscription>
        <ModalForUnSubscription
          userInfo={userInfo}
          width="100%"
          height="80%"
          onClose={() => setOpenUploadModalForUnSubscription(false)}
          item={item}
          handlerUnSubscription={() => {
            handlerUnSubscription(userInfo.userId, item.subscriptionId);
          }}
        />
      </PortalForUnSubscription>
    );
  };

  return (
    <StyledMainWrapper>
      <Header handler={setOpenUploadModal} />
      <StyledCarImgWrapper>
        <StyledCarImg
          onClick={() => {
            setSelectedSubscription({
              subscriptionId: 0,
              name: '',
              description: '',
              price: 0,
              month: 0,
            });
          }}
          src={
            process.env.PUBLIC_URL +
            '/icons/subscription/subInfo_' +
            String(item.month) +
            '_' +
            item.name.split('구독권')[1] +
            '.png'
          }
        />
      </StyledCarImgWrapper>

      <StyledSpace height="5vh" />
      {usedCheck ? (
        <StyledWapper>
          <StyledBottomImg
            src={
              process.env.PUBLIC_URL +
              '/icons/subscriptiondetail/' +
              String(monthIndex) +
              '.png'
            }
          />
          <StyledCardMainWrapper>
            {wineInfo.map((item) => {
              return (
                <StyledCardWrapper>
                  <StyledSpace width="7%" backGroundColor="#DECFD4" />
                  <StyledWineImgWrapper>
                    <StyledWineImg
                      src={
                        'https://wine-project-prod.s3.ap-northeast-2.amazonaws.com/wine/' +
                        item.url
                      }
                    />
                  </StyledWineImgWrapper>
                  <StyledSpace width="7%" backGroundColor="#DECFD4" />
                  <StyledTextWrapper>
                    <StyledTextTopWrapper>
                      <StyledTopText>{item.nameEn}</StyledTopText>
                    </StyledTextTopWrapper>
                    <StyledTextBottomWrapper>
                      <StyledBottomText>
                        {'국가, 지역 : ' + item.country}
                      </StyledBottomText>
                      <StyledBottomText>
                        {'스타일 : ' + item.kind}
                      </StyledBottomText>
                      <StyledBottomText>
                        {'품종 : ' + item.race}
                      </StyledBottomText>
                      <StyledBottomText>
                        {'형태, 용량 : ' + item.amount + 'ml'}
                      </StyledBottomText>
                      <StyledBottomText>
                        {'도수 : ' + item.degree}
                      </StyledBottomText>
                    </StyledTextBottomWrapper>
                  </StyledTextWrapper>
                  <StyledSpace width="7%" backGroundColor="#DECFD4" />
                </StyledCardWrapper>
              );
            })}
            <StyledCardLast />
          </StyledCardMainWrapper>
        </StyledWapper>
      ) : (
        <StyledBottomImgWrapper>
          <StyledBottomImg
            src={
              process.env.PUBLIC_URL +
              '/icons/subscriptiondetail/sub' +
              String(monthIndex) +
              '.png'
            }
          />
        </StyledBottomImgWrapper>
      )}

      <StyledSpace height="10vh" />
      <StyledBtnImgWrapper
        onClick={() => {
          setOpenUploadModalForUnSubscription(true);
        }}>
        <StyledBtnImg
          src={process.env.PUBLIC_URL + '/icons/unSubscription.png'}
        />
      </StyledBtnImgWrapper>

      <StyledSpace height="3vh" />
      {openUploadModal && ModalContent()}
      {openUploadModalForUse && ModalContentForUse()}
      {openUploadModalForUnSubscription && ModalContentForUnSubscription()}
    </StyledMainWrapper>
  );
};

export default memo(SubscriptionDetailInfo);

//'https://wine-project-prod.s3.ap-northeast-2.amazonaws.com/wine/' +
