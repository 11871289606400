import { useCallback, useState } from 'react';
import { useRecoilState } from 'recoil';
import { categoryAtom } from 'store/faq';
import { INameValue } from 'types/Utils.type';
import { IQuestionsByCategory, IFAQInfo } from 'types/Faq.type';
import { questionsByCategoryAtom } from 'store/faq';
import { handleGetFaqList } from 'libs/apis/faq.api';

const useFAQ = () => {
  const [selectedCateogry, setSelectedCategory] =
    useRecoilState<INameValue>(categoryAtom);
  const [faqList, setFaqList] = useRecoilState<IQuestionsByCategory[]>(
    questionsByCategoryAtom,
  );
  const [selectedFAQ, setSelectedFAQ] = useState<number>(0);
  const [openUploadModal, setOpenUploadModal] = useState<boolean>(false);

  const getFAQAllList = useCallback(async () => {
    try {
      const data: IQuestionsByCategory[] = await handleGetFaqList();
      let allTemp: IQuestionsByCategory = {
        category: '전체',
        faqList: [],
      };
      data.forEach((categoryFAQ: IQuestionsByCategory) => {
        categoryFAQ.faqList.forEach((item: IFAQInfo) => {
          allTemp.faqList = [...allTemp.faqList, item];
        });
      });
      data.push(allTemp);
      setFaqList(data);
    } catch (e: any) {}
  }, [faqList, setFaqList]);

  return {
    selectedCateogry,
    setSelectedCategory,
    faqList,
    getFAQAllList,
    selectedFAQ,
    setSelectedFAQ,
    openUploadModal,
    setOpenUploadModal,
  };
};

export default useFAQ;
