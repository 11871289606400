import styled from 'styled-components';

export const StyledTopImgWrapper = styled.div`
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledTopImg = styled.img<{ filter?: boolean }>`
  filter: brightness(${(props) => (props.filter ? '70%' : '100%')});
  object-fit: fill;
  width: 100%;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
`;

export const StyledBottomWrapper = styled.div`
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledBottomImg = styled.img`
  object-fit: fill;
  width: 100%;
  border-bottom-right-radius: 16px;
  border-bottom-left-radius: 16px;
`;
