import { IThemeConfig } from "styled-components";

export const ThemeConfig: IThemeConfig = {
  mainColor: "#B689B0",
  secondColor: "#D5A4CF",
  thirdColor: "#F9BCDD",
  fourthColor: "#74445A",
  backGroundColor: "#EEE5E8",
  black: "#000000",
  white: "#ffffff",
  gray: "#ebedee",
  navBg: "#ffc078",
  lightMainColor: "#fdf1e8",
  darkGray: "#635f5f",
  fontGray: "#a5a5a5",
  interfaceBg: "#f7f9fc",
  red: "#FF0000",
};
