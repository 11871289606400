import * as React from 'react';
import { StyledFooterWrapper, StyledSubscriptionButton } from './styles';

interface Props {
  handleClick: () => void | Promise<void>;
}

const Footer = ({ handleClick }: Props) => {
  return (
    <StyledFooterWrapper>
      <StyledSubscriptionButton
        src={process.env.PUBLIC_URL + '/icons/footer.png'}
        onClick={handleClick}
      />
    </StyledFooterWrapper>
  );
};

export default React.memo(Footer);
