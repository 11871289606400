import styled from 'styled-components';

export const StyledSignUpWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.backGroundColor};
`;

export const StyledHeaderWrapper = styled.div`
  width: 100%;
  height: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
`;

export const StyledHeaderTextWrapper = styled.div`
  width: 40%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledHeaderText = styled.text`
  font-size: 1.5rem;
  font-weight: 700;
`;

export const StyledItemWrapper = styled.div`
  height: 35%;
  width: 100%;
  background-color: ${(props) => props.theme.backGroundColor};
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const StyledPlaceHoldWrapper = styled.div`
  height: 20%;
  width: 90%;
  background-color: #cfcfcf;
`;

export const StyledPassWordInputBox = styled.input`
  height: 20%;
  width: 90%;
  border: none;
  padding-left: 5%;
`;

export const StyledButtonWrapper = styled.div`
  width: 100%;
  height: 15%;
  background-color: ${(props) => props.theme.backGroundColor};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const StyledButton = styled.button<{ backColor?: boolean }>`
  height: 40%;
  width: 80%;
  background-color: ${(props) =>
    props.backColor ? props.theme.backGroundColor : props.theme.fourthColor};
  outline: 0;
  border: none;
  border-radius: 100px;
  &:focus {
    border: none !important;
    outline: 2px solid ${({ theme }) => theme.mainColor};
  }
`;

export const StyledMainButtonText = styled.text<{ textColor: string }>`
  font-size: 1.5rem;
  font-weight: 700;
  color: ${(props) => props.textColor};
`;
