import { atom } from 'recoil';
import { INameValue } from 'types/Utils.type';
import { IQuestionsByCategory } from 'types/Faq.type';

export const categoryAtom = atom<INameValue>({
  key: 'categoryAtom',
  default: {
    name: '전체',
    value: '1',
  },
});

export const questionsByCategoryAtom = atom<IQuestionsByCategory[]>({
  key: 'questionsByCategoryAtom',
  default: [],
});
