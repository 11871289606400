import React, { memo, useState, useEffect } from 'react';
import Header from 'components/Header';
import {
  StyledMainWrapper,
  StyledCategoryListWrapper,
  StyledCategoryBtn,
  StyledFAQListWrapper,
  StyledFAQItemWrapper,
  StyledTextWrapper,
  StyledBtnWrapper,
  StyledBtnImg,
  StyledMainText,
  StyledSubText,
  StyledItemMainWrapper,
} from './styles';
import { StyledSpace } from 'utils/styledSpace';
import { FAQCategoryList } from 'constants/constants';
import { INameValue } from 'types/Utils.type';
import { IFAQInfo } from 'types/Faq.type';
import useFAQ from 'hooks/useFAQ';
import PortalForOption from 'components/common/ModalForOption/PortalForOption';
import ModalForOption from 'components/common/ModalForOption';
import { moveToTop } from 'utils/moveToTop';

const SubscriptionInfo = () => {
  const {
    selectedCateogry,
    setSelectedCategory,
    faqList,
    getFAQAllList,
    selectedFAQ,
    setSelectedFAQ,
    openUploadModal,
    setOpenUploadModal,
  } = useFAQ();

  useEffect(() => {
    moveToTop();
    getFAQAllList();
  }, []);

  const ModalContent = () => {
    return (
      <PortalForOption>
        <ModalForOption
          width="50%"
          height="30%"
          isNoButton
          isYesButton
          onClose={() => setOpenUploadModal(false)}
          onNo={() => setOpenUploadModal(false)}
          onYes={() => {}}>
          {/* <StyledModalText>{'삭제하시겠습니까?'}</StyledModalText> */}
        </ModalForOption>
      </PortalForOption>
    );
  };

  return (
    <StyledMainWrapper>
      <Header handler={setOpenUploadModal} />
      <StyledCategoryListWrapper>
        {FAQCategoryList.map((item: INameValue) => {
          return (
            <StyledCategoryBtn
              selected={selectedCateogry.value === item.value}
              onClick={() => {
                setSelectedCategory(item);
              }}>
              {item.name}
            </StyledCategoryBtn>
          );
        })}
      </StyledCategoryListWrapper>

      <StyledSpace height="5%" />

      <StyledFAQListWrapper>
        {selectedCateogry.value === '1'
          ? faqList
              .find((e) => e.category === '전체')
              ?.faqList.map((item: IFAQInfo) => {
                return (
                  <StyledItemMainWrapper>
                    <StyledFAQItemWrapper
                      onClick={() => {
                        selectedFAQ === item.faqId
                          ? setSelectedFAQ(0)
                          : setSelectedFAQ(item.faqId);
                      }}>
                      <StyledSpace width="3%" backGroundColor="white" />
                      <StyledTextWrapper>
                        <StyledMainText>{item.category}</StyledMainText>
                        <StyledSubText>{item.question}</StyledSubText>
                      </StyledTextWrapper>
                      <StyledBtnWrapper>
                        <StyledBtnImg
                          src={
                            process.env.PUBLIC_URL + '/icons/vectorRight.png'
                          }
                        />
                      </StyledBtnWrapper>
                    </StyledFAQItemWrapper>
                    {selectedFAQ === item.faqId && (
                      <StyledTextWrapper answer={true}>
                        <StyledSubText answer={true}>
                          {'A.' + item.answer}
                        </StyledSubText>
                      </StyledTextWrapper>
                    )}
                  </StyledItemMainWrapper>
                );
              })
          : faqList
              .find((e) => e.category === selectedCateogry.name)
              ?.faqList.map((item: IFAQInfo) => {
                return (
                  <StyledItemMainWrapper>
                    <StyledFAQItemWrapper
                      onClick={() => {
                        selectedFAQ === item.faqId
                          ? setSelectedFAQ(0)
                          : setSelectedFAQ(item.faqId);
                      }}>
                      <StyledSpace width="3%" backGroundColor="white" />
                      <StyledTextWrapper>
                        <StyledMainText>{item.category}</StyledMainText>
                        <StyledSubText>{item.question}</StyledSubText>
                      </StyledTextWrapper>
                      <StyledBtnWrapper>
                        <StyledBtnImg
                          src={
                            process.env.PUBLIC_URL + '/icons/vectorRight.png'
                          }
                        />
                      </StyledBtnWrapper>
                    </StyledFAQItemWrapper>
                    {selectedFAQ === item.faqId && (
                      <StyledTextWrapper answer={true}>
                        <StyledSubText answer={true}>
                          {'A.' + item.answer}
                        </StyledSubText>
                      </StyledTextWrapper>
                    )}
                  </StyledItemMainWrapper>
                );
              })}
      </StyledFAQListWrapper>
      {openUploadModal && ModalContent()}
    </StyledMainWrapper>
  );
};

export default memo(SubscriptionInfo);
