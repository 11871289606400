import styled from 'styled-components';

export const StyledMainWrapper = styled.div`
  padding-top: 10vh;
  width: 100%;
  height: 95%;
  background-color: ${(props) => props.theme.backGroundColor};
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const StyledTopWrapper = styled.div`
  width: 95%;
  height: 30%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const StyledCheckImgWrapper = styled.div`
  width: 100%;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledCheckImg = styled.img`
  height: 80%;
  aspect-ratio: 1/1;
  object-fit: contain;
`;

export const StyledTopTextWrapper = styled.div`
  height: 50%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledTopText = styled.text`
  font-size: 1.8rem;
  font-weight: 900;
  color: ${(props) => props.theme.fourthColor};
  white-space: pre-wrap;
`;

export const StyledBottomWrapper = styled.div`
  width: 95%;
  height: 50%;
  background-color: white;
`;

export const StyledBtnWrapper = styled.div`
  width: 95%;
  height: 10vh;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const StyledBtn = styled.div`
  width: 100%;
  height: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: 0;
  border: none;
  background-color: ${(props) => props.theme.fourthColor};
  &:focus {
    border: none !important;
    outline: 2px solid ${({ theme }) => theme.mainColor};
  }
  font-size: 1.5rem;
  font-weight: 700;
  color: white;
`;

export const StyledBottomHeaderAndFooter = styled.div`
  height: 20%;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid #b8b8b8;
`;

export const StyledBottomCardWrapper = styled.div`
  width: 100%;
  height: 30%;
  border-bottom: 1px solid #b8b8b8;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const StyledCardTextWrapper = styled.div`
  padding-left: 3%;
  width: 60%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
`;

export const StyledCardMainText = styled.text`
  font-size: 1.5rem;
  color: ${(props) => props.theme.fourthColor};
  font-weight: 700;
`;

export const StyledCardSubText = styled.text`
  font-size: 1rem;
  color: gray;
  font-weight: 400;
  white-space: pre-wrap;
`;

export const StyledImgWrapper = styled.div`
  width: 40%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledCardImg = styled.img<{ size?: boolean }>`
  width: ${(props) => (props.size ? '40%' : '50%')};
  object-fit: contain;
  background-color: white;
  border-radius: 8px;
`;

export const StyledOptionWrapper = styled.div`
  width: 40%;
  height: 100%;
  border-right: 1px solid #b8b8b8;
`;

export const StyledOptionTextWrapper = styled.div`
  width: 100%;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledOptionText = styled.text`
  font-size: 1.5rem;
  font-weight: 700;
  color: ${(props) => props.theme.fourthColor};
`;

export const StyledOptionValueWrapper = styled.div`
  width: 60%;
  height: 100%;
  display: flex;
  justify-items: center;
  align-items: center;
  flex-direction: column;
`;

export const StyledOptionValueTextWrapper = styled.div`
  width: 100%;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledOptionValueText = styled.text`
  font-size: 1.2rem;
  font-weight: 700;
  color: ${(props) => props.theme.fourthColor};
  white-space: pre-wrap;
`;

export const StyledAmountTextWrapper = styled.div`
  width: 40%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledAmountWrapper = styled.div`
  width: 60%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 5%;
`;

export const StyledAmount = styled.text`
  font-size: 1.5rem;
  font-weight: 700;
  color: red;
`;

export const StyledSuccesButtonWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

export const StyledSuccessTopButtonWrapper = styled.div`
  width: 100%;
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledSuccessTopButton = styled.button`
  width: 48%;
  height: 100%;
  border-radius: 4px;
  outline: 0;
  border: none;
  background-color: #decfd4;
  &:focus {
    border: none !important;
    outline: 2px solid ${({ theme }) => theme.mainColor};
  }
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
`;

export const StyledSuccesTopText = styled.text`
  font-size: 1rem;
  font-weight: 700;
  color: ${(props) => props.theme.fourthColor};
`;

export const StyledSuccessBottomButton = styled.div`
  width: 100%;
  height: 50%;
  outline: 0;
  border: none;
  background-color: ${(props) => props.theme.fourthColor};
  &:focus {
    border: none !important;
    outline: 2px solid ${({ theme }) => theme.mainColor};
  }
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
`;

export const StyledSuccessBottomText = styled.text`
  font-size: 1.5rem;
  font-weight: 700;
  color: white;
`;

export const StyledMainImgWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledMainImg = styled.img`
  width: 95%;
  object-fit: fill;
`;

export const StyledProgressWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: gray;
  opacity: 0.5;
`;
