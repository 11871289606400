import styled from 'styled-components';

export const StyledModalBackground = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  text-align: center;
  position: fixed;
  bottom: 0;
  background-color: ${({ theme }) => theme.black}95;
  z-index: 99999;
`;

export const StyledModal = styled.div<{
  width: string;
  height: string;
}>`
  position: relative;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.backGroundColor};
`;

export const StyledCodeInputBoxWrapper = styled.div`
  width: 100%;
  height: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledCodeInputBox = styled.input`
  height: 100%;
  width: 90%;
  border: none;
  padding-left: 5%;
`;

export const StyledButtonWrapper = styled.div`
  height: 30%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledButton = styled.button<{ expired?: boolean }>`
  height: 60%;
  width: 90%;
  background-color: ${(props) =>
    props.expired ? '#ABC71A' : props.theme.fourthColor};
  outline: 0;
  border: none;
  border-radius: 100px;
  &:focus {
    border: none !important;
    outline: 2px solid ${({ theme }) => theme.mainColor};
  }
  color: white;
  font-size: 1.2rem;
  font-weight: 700;
`;

export const StyledTextImgWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledTextImg = styled.img`
  width: 100%;
  object-fit: fill;
`;
