import React, { useEffect } from 'react';
import {
  StyledSignUpWrapper,
  StyledHeaderWrapper,
  StyledHeaderTextWrapper,
  StyledBackButton,
  StyledHeaderText,
  StyledButtonText,
  StyledPlaceHoldWrapper,
  StyledItemWrapper,
  StyledPassWordInputBox,
  StyledButtonWrapper,
  StyledButton,
  StyledMainButtonText,
  StyledNameText,
} from './styles';
import { StyledSpace } from 'utils/styledSpace';
import useLogin from 'hooks/useLogin';

const SignUp = () => {
  const {
    passwordValue,
    signUp,
    navigate,
    idValue,
    startUserInfo,
    nameValue,
    encode,
    setEncode,
    encodeMbr,
  } = useLogin();

  useEffect(() => {
    const encodeName = JSON.parse(String(localStorage.getItem('encode')));
    if (encodeName.customNumber === null || encodeName.userName === null) {
      window.location.replace('/');
      return;
    }
    setEncode(encodeName.userName);
    nameValue.current = encodeName.userName;
    encodeMbr.current = encodeName.customNumber;
  }, []);

  return (
    <StyledSignUpWrapper>
      <StyledHeaderWrapper>
        <StyledSpace width="30%" />
        <StyledHeaderTextWrapper>
          <StyledHeaderText>회원 가입</StyledHeaderText>
        </StyledHeaderTextWrapper>
        <StyledBackButton
          onClick={() => {
            navigate(-1);
          }}>
          <StyledButtonText>Back</StyledButtonText>
        </StyledBackButton>
      </StyledHeaderWrapper>

      <StyledItemWrapper>
        <StyledPassWordInputBox
          placeholder={'이름'}
          maxLength={5}
          defaultValue={encode}
          selected={true}
          disabled={true}
        />
        <StyledPassWordInputBox
          onChange={(e) => {
            idValue.current = e.target.value;
          }}
          placeholder={'ID (사번)'}
          maxLength={20}
        />
        <StyledPassWordInputBox
          type="password"
          onChange={(e) => {
            passwordValue.current[0] = e.target.value;
          }}
          placeholder={'Password (영문/숫자/특수문자 혼합 8-16자)'}
          maxLength={16}
        />
        <StyledPassWordInputBox
          type="password"
          onChange={(e) => {
            passwordValue.current[1] = e.target.value;
          }}
          placeholder={'Confirm Password'}
          maxLength={16}
        />
      </StyledItemWrapper>

      <StyledSpace height="30vh" />

      <StyledButtonWrapper>
        <StyledButton onClick={signUp}>
          <StyledMainButtonText>회원가입</StyledMainButtonText>
        </StyledButton>
      </StyledButtonWrapper>
    </StyledSignUpWrapper>
  );
};

export default SignUp;
