import styled from 'styled-components';

export const StyledMainWrapper = styled.div`
  padding-top: 8vh;
  width: 100%;
  height: 95%;
  background-color: ${(props) => props.theme.backGroundColor};
`;

export const StyledTopWrapper = styled.div`
  width: 100%;
  height: 70%;
  padding: 5%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const StyledCardWrapper = styled.div`
  width: 100%;
  height: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 6px;
  flex-direction: column;
`;

export const StyledCardItemWapper = styled.div<{
  back?: string;
  topRadius?: string;
  bottomRadius?: string;
  flex?: string;
}>`
  padding-left: 10%;
  height: 25%;
  width: 100%;
  background-color: ${(props) => (props.back ? props.back : 'white')};
  border: 1px solid #74445a;
  display: flex;
  justify-content: ${(props) => (props.flex ? 'flex-start' : 'center')};
  align-items: ${(props) => (props.flex ? 'center' : 'flex-start')};
  border-top-right-radius: ${(props) =>
    props.topRadius ? props.topRadius : undefined};
  border-top-left-radius: ${(props) =>
    props.topRadius ? props.topRadius : undefined};
  border-bottom-right-radius: ${(props) =>
    props.bottomRadius ? props.bottomRadius : undefined};
  border-bottom-left-radius: ${(props) =>
    props.bottomRadius ? props.bottomRadius : undefined};
  flex-direction: ${(props) => (props.flex ? props.flex : 'column')};
`;

export const StyledCardText = styled.text<{ size: string; color: string }>`
  font-size: ${(props) => props.size};
  color: ${(props) => props.color};
  font-weight: 900;
  white-space: pre-wrap;
`;

export const StyledBottomWrapper = styled.div`
  width: 100%;
  height: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const StyledButtonItem = styled.button<{ backGroundColor: string }>`
  width: 90%;
  height: 20%;
  background-color: ${(props) => props.backGroundColor};
  border: none;
  border-radius: 4px;
  cursor: pointer;
  &:focus {
    border: none !important;
    outline: 2px solid ${({ theme }) => theme.mainColor};
  }
`;

export const StyledButtonItemText = styled.text<{ color: string }>`
  font-size: 1rem;
  font-weight: 700;
  color: ${(props) => props.color};
`;

export const StyledMBTIImgWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  position: relative;
`;

export const StyledMBTIImg = styled.img`
  width: 100%;
  object-fit: fill;
`;

export const StyledBtnWrapper = styled.div`
  width: 25%;
  height: 100%;
  position: absolute;
`;

export const StyledCardTextWrapper = styled.div`
  width: 90%;
  height: 50%;
  position: absolute;
`;
