import customAxios from 'libs/CustomAxios';
import { IBillingInfo } from 'types/Billing.type';

export const handleGetBillingList = async (
  userId: number,
): Promise<IBillingInfo[]> => {
  const url: string = `/billing?userId=${userId}`;
  const { data } = await customAxios.get(url);
  return data.data;
};
