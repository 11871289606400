import styled from 'styled-components';

export const StyledMainWrapper = styled.div`
  padding-top: 8vh;
  width: 100%;
  height: 95%;
  background-color: ${(props) => props.theme.backGroundColor};
`;

export const StyledCarImgWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledCarImg = styled.img`
  width: 80%;
  object-fit: fill;
`;
//
export const StyledBottomImgWrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledBottomImg = styled.img`
  width: 80%;
  object-fit: fill;
`;
//
export const StyledWapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const StyledCardMainWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const StyledCardWrapper = styled.div`
  width: 80%;
  height: 12vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #decfd4;
  border-left: 1px solid #74445a;
  border-right: 1px solid #74445a;
`;

export const StyledWineImgWrapper = styled.div`
  height: 10vh;
  width: 19%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border: 1px solid #74445a;
`;

export const StyledWineImg = styled.img`
  height: 90%;
  width: 40%;
  object-fit: fill;
`;
//
export const StyledTextWrapper = styled.div`
  height: 10vh;
  width: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border: 1px solid #74445a;
  flex-direction: column;
`;

export const StyledTextTopWrapper = styled.div`
  height: 25%;
  width: 100%;
  background-color: #74445a;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledTextBottomWrapper = styled.div`
  padding-left: 5%;
  height: 75%;
  width: 100%;
  background-color: white;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
`;

export const StyledTopText = styled.text`
  color: white;
  font-size: 0.75rem;
  font-weight: 400;
  overflow: hidden;
  white-space: nowrap;
`;

export const StyledBottomText = styled.text`
  color: #74445a;
  line-height: 120%;
  font-size: 0.5rem;
  font-weight: 900;
  white-space: pre-wrap;
`;

//
export const StyledCardLast = styled.div`
  width: 80%;
  height: 2vh;
  border-left: 1px solid #74445a;
  border-right: 1px solid #74445a;
  border-bottom: 1px solid #74445a;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  background-color: #decfd4;
`;
//
export const StyledBtnImgWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledBtnImg = styled.img`
  width: 80%;
  object-fit: fill;
`;
