import styled from 'styled-components';

export const StyledType1Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
`;

export const StyledImgWrapper = styled.div`
  width: 100%;
`;

export const StyledImg = styled.img`
  width: 100%;
  object-fit: fill;
`;

export const StyledBottomWrapper = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.background};
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const StyledBTNNOImg = styled.img`
  width: 50%;
  object-fit: fill;
`;

export const StyledBTNYESImg = styled.img`
  width: 50%;
  object-fit: fill;
`;
