import React, { memo, Dispatch, SetStateAction } from 'react';
import {
  StyledWineImg,
  StyledMainText,
  StyledHeaderLeftWrapper,
  StyledHeaderRightWrapper,
  StyledSpace,
  StyledLinkBtn,
  StyledBtnImg,
  StyledHeaderImg,
} from './styles';
import { NavigateFunction } from 'react-router-dom';

interface Props {
  handler?: Dispatch<SetStateAction<boolean>>;
  navigate: NavigateFunction;
}

const HeaderType1 = ({ handler, navigate }: Props) => {
  return (
    <>
      <StyledHeaderLeftWrapper>
        <StyledHeaderImg
          src={process.env.PUBLIC_URL + '/icons/headerImg.png'}
          onClick={() => {
            navigate('/main');
          }}
        />
      </StyledHeaderLeftWrapper>
      <StyledHeaderRightWrapper>
        <StyledLinkBtn to={'/coupon'}>
          <StyledBtnImg src={process.env.PUBLIC_URL + '/icons/header1.png'} />
        </StyledLinkBtn>
        <StyledBtnImg
          src={process.env.PUBLIC_URL + '/icons/header2.png'}
          onClick={() => {
            handler!(true);
          }}
        />
      </StyledHeaderRightWrapper>
    </>
  );
};

export default memo(HeaderType1);
