import React, { memo, useLayoutEffect } from 'react';
import {
  StyledMainWrapper,
  StyledButtonWrapper,
  StyledButton,
  StyledButtonText,
  StyledLoginWrapper,
  StyledLoginText,
  StyledMainImgWrapper,
  StyledMainImg,
} from './styles';
import { StyledSpace } from 'utils/styledSpace';
import useHome from 'hooks/useHome';

const Home = () => {
  const {
    navigateToSignUpPage,
    checkFirstTimeLogin,
    navigateToLoginPage,
    navigate,
  } = useHome();

  useLayoutEffect(() => {
    checkFirstTimeLogin();
  }, []);

  return (
    <StyledMainWrapper>
      <StyledSpace height="10vh" />
      <StyledMainImgWrapper>
        <StyledMainImg src={process.env.PUBLIC_URL + '/icons/homePage.png'} />
      </StyledMainImgWrapper>

      <StyledButtonWrapper>
        <StyledButton onClick={navigateToSignUpPage}>
          <StyledButtonText>시작하기</StyledButtonText>
        </StyledButton>
      </StyledButtonWrapper>

      <StyledLoginWrapper>
        <StyledLoginText
          onClick={() => {
            navigate('/signup');
          }}>
          회원가입 하러가기
        </StyledLoginText>
      </StyledLoginWrapper>
    </StyledMainWrapper>
  );
};

export default memo(Home);
