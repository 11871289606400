import customAxios from 'libs/CustomAxios';
import { IDecode } from 'types/Utils.type';

export const getAuthorityByToken = async () => {
  const url: string = `/admin/me`;
  const { adminAuthority } = (await customAxios.get(url)).data.data;
  return adminAuthority;
};

export const handleDecodor = async (req: IDecode): Promise<any> => {
  const url: string = `/auth/dec`;
  const data: any = await customAxios.post(url, req);
  return data.data.data;
};
