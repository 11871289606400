import { atom } from "recoil";
import { IWidthHeigth } from "types/Utils.type";

export const globalWidthHeight = atom<IWidthHeigth>({
  key: "globalWidthHeight",
  default: {
    width: 0,
    height: 0,
  },
});
