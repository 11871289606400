import styled from 'styled-components';

export const StyledModalBackground = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: fixed;
  bottom: 0;
  background-color: ${({ theme }) => theme.black}95;
  z-index: 99999;
`;

export const StyledModal = styled.div<{}>`
  width: 80%;
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #f6f6f6;
  border: 4px solid ${(props) => props.theme.fourthColor};
`;

export const StyledImgWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledImg = styled.img`
  width: 100%;
  object-fit: fill;
`;

export const StyledCloseBtnWrapper = styled.div`
  position: fixed;
  display: flex;
  justify-content: flex-end;
  width: 80%;
  height: 5%;
`;

export const StyledCloseBtn = styled.div`
  display: flex;
  width: 20%;
  height: 100%;
`;
