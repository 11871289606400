import React, { useRef, useEffect, useState } from 'react';
import * as Pages from 'pages';
import { Route, Routes } from 'react-router-dom';
import { BrowserView, MobileView } from 'react-device-detect';
import getWidthHeight from 'utils/getWidthHeight';
import useQueryString from 'hooks/useQueryString';
import useLogin from 'hooks/useLogin';

const App = () => {
  const { setUseWidthHeight } = getWidthHeight();
  const { encodeLpntCustNo, encodeMbrNm } = useQueryString();
  const { handlerDecodor, handlerMaintain, maintainValidation, customNumber } =
    useLogin();
  const [comePath, setComePath] = useState<boolean>(true);

  useEffect(() => {
    setUseWidthHeight({
      width: window.innerWidth,
      height: window.innerHeight,
    });
    if (
      window.location.pathname === '/' &&
      (encodeMbrNm === undefined || encodeLpntCustNo === undefined)
    ) {
      setComePath(false);
      return;
    }
    const arr1 = String(encodeMbrNm!).split(' ');
    const arr2 = String(encodeLpntCustNo!).split(' ');
    let res1: string = '';
    let res2: string = '';
    arr1.forEach((item: any, index: number) => {
      if (index === 0) {
        res1 += item;
      } else {
        res1 += '+' + item;
      }
    });
    arr2.forEach((item: any, index: number) => {
      if (index === 0) {
        res2 += item;
      } else {
        res2 += '+' + item;
      }
    });

    handlerDecodor(res1, res2);
    handlerMaintain();
  }, []);

  return (
    <>
      {maintainValidation?.numbers.find((e) => e === String(customNumber)) ===
        undefined &&
      maintainValidation?.numbers.find((e) => e === String(customNumber)) ===
        undefined &&
      maintainValidation?.status ? (
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <img
            src={process.env.PUBLIC_URL + '/icons/maintain.png'}
            style={{ width: '90%', objectFit: 'fill' }}
          />
        </div>
      ) : (
        <>
          <MobileView style={{ height: '100vh', width: '100vw' }}>
            {comePath ? (
              <Routes>
                <Route path="/" element={<Pages.HomePage />} />
                <Route path="/main" element={<Pages.MainPage />} />
                <Route path="/signup" element={<Pages.SignUpPage />} />
                <Route path="/signin" element={<Pages.SignInPage />} />
                <Route
                  path="/subscriptioninfo"
                  element={<Pages.SubscirptionInfoPage />}
                />
                <Route path="/testwine" element={<Pages.TestWinePage />} />
                <Route path="/mypage" element={<Pages.MyPagePage />} />
                <Route path="/billing" element={<Pages.BillingPage />} />
                <Route path="/faq" element={<Pages.FAQPage />} />
                <Route
                  path="/billingdetail"
                  element={<Pages.BillingDetailPage />}
                />
                <Route path="/coupon" element={<Pages.CouponPage />} />
                <Route path="/niceauth" element={<Pages.NiceAuthPage />} />
                <Route path="/success" element={<Pages.SuccessPages />} />
                <Route
                  path="/billingsuccess"
                  element={<Pages.BillingSuccessPage />}
                />
                <Route
                  path="/billinginfo"
                  element={<Pages.BillingInfoPage />}
                />
                <Route path="/fail" element={<Pages.FailPage />} />
                <Route path="/notice" element={<Pages.NoticePage />} />
                <Route path="/*" element={<Pages.NotFoundPage />} />
              </Routes>
            ) : (
              <div
                style={{
                  height: '100%',
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: '#DECFD4',
                }}>
                <img
                  src={process.env.PUBLIC_URL + '/icons/prevent.png'}
                  style={{ width: '90%', objectFit: 'fill' }}
                />
              </div>
            )}
          </MobileView>
          <BrowserView>
            <Routes>
              <Route path="/" element={<Pages.PcQrPage />} />
            </Routes>
          </BrowserView>
        </>
      )}
    </>
  );
};

export default App;
