import {
  StyledImgWrapper,
  StyledImg,
  StyledBottomWrapper,
  StyledType1Wrapper,
  StyledBTNNOImg,
  StyledBTNYESImg,
} from './styles';

interface IModal {
  onClose?: () => void;
  onYes?: () => void;
  type?: number;
  onNo?: () => void;
  month?: number;
  later?: string;
}

const MainModalType2 = ({
  onClose,
  onYes,
  type,
  onNo,
  month,
  later,
}: IModal) => {
  return (
    <StyledType1Wrapper>
      <StyledImgWrapper>
        <StyledImg
          src={
            type === 4
              ? later === '당월'
                ? process.env.PUBLIC_URL + '/icons/modal/' + month + '.png'
                : process.env.PUBLIC_URL + '/icons/modal/123.png'
              : process.env.PUBLIC_URL + '/icons/modal' + String(type) + '.png'
          }
        />
      </StyledImgWrapper>
      <StyledBottomWrapper>
        <StyledBTNNOImg
          src={process.env.PUBLIC_URL + '/icons/type2NO.png'}
          onClick={onNo}
        />
        <StyledBTNYESImg
          src={process.env.PUBLIC_URL + '/icons/type2YES.png'}
          onClick={onYes}
        />
      </StyledBottomWrapper>
    </StyledType1Wrapper>
  );
};

export default MainModalType2;
