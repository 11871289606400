import { useCallback, useState, useEffect, useRef } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { handleGetCouponList, handleUseCoupon } from 'libs/apis/coupon.api';
import {
  ICouponInfo,
  ICouponInfoListReponse,
  ICouponUserRequest,
} from 'types/Coupon.tpye';
import { couponInfoAtom } from 'store/coupon';
import Toast from 'libs/Toast';
import { userInfoAtom } from 'store/user';

const useCoupon = () => {
  const [openUploadModal, setOpenUploadModal] = useState<boolean>(false);
  const [openUploadModalHeader, setOpenUploadModalHeader] =
    useState<boolean>(false);
  const [couponList, setCouponList] = useState<ICouponInfo[]>([]);
  const [selectedCoupon, setSelectedCoupon] =
    useRecoilState<ICouponInfo>(couponInfoAtom);
  const passwordCodeValue = useRef<string>('');
  const userInfo = JSON.parse(String(localStorage.getItem('userInfo')));
  const [couponStatusValidation, setCouponStatusValidation] =
    useState<boolean>(false);

  const getCouponList = useCallback(async () => {
    try {
      const data: ICouponInfoListReponse = await handleGetCouponList(
        userInfo.userId,
      );
      setCouponList(data.data);
      let flag = false;
      data.data.forEach((item: ICouponInfo) => {
        if (item.status !== 'USED') {
          flag = true;
        }
      });
      setCouponStatusValidation(flag);
    } catch (e: any) {}
  }, []);

  const checkPasswordCode = () => {
    if (selectedCoupon.code === passwordCodeValue.current) {
      return true;
    } else {
      return false;
    }
  };

  const checkPasswordCodeAndUseCoupon = useCallback(async () => {
    if (!checkPasswordCode()) {
      Toast.errorToast('코드가 틀렸습니다.');
      return;
    }

    try {
      const currentDate = new Date();
      currentDate.setHours(currentDate.getHours() + 9);
      const req: ICouponUserRequest = {
        voucherId: selectedCoupon.voucherId,
        // userId: userId,
        userId: 2,
        useDateTime: currentDate,
        voucherCode: selectedCoupon.code,
      };
      await handleUseCoupon(req);
      Toast.successToast('사용완료 됐습니다.');
      window.location.reload();
    } catch (e: any) {
      Toast.errorToast('통신 오류입니다.');
    }
  }, [passwordCodeValue]);

  return {
    openUploadModal,
    setOpenUploadModal,
    getCouponList,
    couponList,
    selectedCoupon,
    setSelectedCoupon,
    passwordCodeValue,
    checkPasswordCodeAndUseCoupon,
    userInfo,
    openUploadModalHeader,
    setOpenUploadModalHeader,
    couponStatusValidation,
  };
};

export default useCoupon;
