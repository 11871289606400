import { useCallback, useState, useRef } from 'react';
import { handleAuthCheck } from 'libs/apis/login.api';
import { useNavigate } from 'react-router-dom';

const useNiceAuth = () => {
  const navigate = useNavigate();
  const [adultCheck, setAdultCheck] = useState<boolean>(false);
  const [loadProgress, setLoadProgress] = useState<boolean>(false);
  const adultCheckValidation = useRef<boolean>(false);

  const goToNiceAuth = () => {
    const userInfo = JSON.parse(String(localStorage.getItem('userInfo')));
    let url =
      'https://auth.winelotteshopping.com/checkplus_main?userId=' +
      String(userInfo.userId);
    url += '&env=' + process.env.REACT_APP_NICE_AUTH;
    window.open(
      url,
      '_blank',
      'width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no',
    );
  };

  const checkAdultAuth = useCallback(async () => {
    const userInfo = JSON.parse(String(localStorage.getItem('userInfo')));
    try {
      const data = await handleAuthCheck(userInfo.userId);
      if (data.data) {
        adultCheckValidation.current = true;
        setLoadProgress(true);
        setAdultCheck(true);
        setTimeout(() => {
          setLoadProgress(false);
        }, 1500);
        const newUserInfo = { ...userInfo, isAdult: 'Y' };
        localStorage.setItem('userInfo', JSON.stringify(newUserInfo));
      } else {
        setAdultCheck(false);
      }
    } catch (e: any) {}
  }, [adultCheck, setAdultCheck, loadProgress, setLoadProgress]);

  return {
    goToNiceAuth,
    adultCheck,
    checkAdultAuth,
    navigate,
    loadProgress,
    adultCheckValidation,
  };
};

export default useNiceAuth;
