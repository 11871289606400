import React, {
  CSSProperties,
  memo,
  ReactNode,
  RefObject,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import {
  StyledButtonWrapper,
  StyledCloseImage,
  StyledModal,
  StyledModalBackground,
  StyledModalContent,
  StyledItemWrapper,
  StyledItemText,
} from './styles';
import PortalForOption from './PortalForOption';
import { OptionList } from 'constants/constants';
import { optionListAtom } from 'store/main';
import { useRecoilState } from 'recoil';
import { INameValue } from 'types/Utils.type';

interface IModal {
  children: ReactNode;
  onClose: () => void;
  onYes?: () => void;
  onNo?: () => void;
  width?: string;
  height?: string;
  customStyle?: CSSProperties;
  isYesButton?: boolean;
  isNoButton?: boolean;
  yesComment?: string;
  noComment?: string;
  isClose?: boolean;
}

const ModalForOption = ({
  children,
  onYes,
  onNo,
  onClose,
  customStyle,
  width = '700px',
  height = '400px',
  isYesButton = false,
  isNoButton = false,
  yesComment = '예',
  noComment = '아니요',
  isClose = false,
}: IModal) => {
  const modalRef: RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);
  const [selectedOption, setSelectedOption] =
    useRecoilState<INameValue>(optionListAtom);

  const onClickOutSide = useCallback(
    (e: MouseEvent): void => {
      if (modalRef.current && !modalRef.current.contains(e.target as Node)) {
        onClose();
      }
    },
    [onClose],
  );

  useEffect(() => {
    document.addEventListener('mousedown', onClickOutSide);
    // 바깥영역클릭시 모달 종료
    return () => {
      document.removeEventListener('mousedown', onClickOutSide);
    };
  }, [onClickOutSide]);

  return (
    <PortalForOption>
      <StyledModalBackground>
        <StyledModal
          width={width}
          height={height}
          style={customStyle}
          ref={modalRef}>
          {OptionList.map((item: INameValue, index: number) => {
            return (
              <StyledItemWrapper
                key={index}
                to={item.value}
                onClick={() => {
                  setSelectedOption(item);
                }}
                selected={selectedOption.name === item.name}>
                <StyledItemText selected={selectedOption.name === item.name}>
                  {item.name}
                </StyledItemText>
              </StyledItemWrapper>
            );
          })}
        </StyledModal>
      </StyledModalBackground>
    </PortalForOption>
  );
};

export default memo(ModalForOption);
