import styled from 'styled-components';

export const StyledItemWrapper = styled.div<{
  height: string;
  backColor?: string;
}>`
  padding-left: 5%;
  width: 100%;
  height: ${(props) => props.height};
  background-color: ${(props) =>
    props.backColor ? props.backColor : props.theme.backGroundColor};
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const StyledText = styled.text<{
  size: string;
  weight: string;
  color: string;
  lineHeight?: string;
  height?: string;
}>`
  height: ${(props) => (props.height ? props.height : undefined)};
  font-size: ${(props) => props.size};
  font-weight: ${(props) => props.weight};
  color: ${(props) => props.color};
  white-space: pre-wrap;
  line-height: 1.5rem;
`;

export const StyledBigBoxWrapper = styled.div`
  width: 100%;
  height: 15%;
  background-color: #decfd4;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const StyledBigBoxMainTextWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const StyledBigBoxCardWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const StyledCardImg = styled.img<{ size?: boolean }>`
  width: ${(props) => (props.size ? '25%' : '20%')};
  object-fit: contain;
  background-color: #decfd4;
  border-radius: 8px;
`;

export const StyledCardTextWrapper = styled.div`
  width: 65%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
`;

export const StyledCardMainText = styled.text`
  font-size: 1.5rem;
  color: ${(props) => props.theme.fourthColor};
  font-weight: 700;
`;

export const StyledCardSubText = styled.text`
  font-size: 1rem;
  color: gray;
  font-weight: 400;
  white-space: pre-wrap;
`;

export const StyledButton = styled.div<{ color: string; fontColor: string }>`
  width: 95%;
  height: 25%;
  color: ${(props) => props.fontColor};
  background-color: ${(props) => props.color};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  font-weight: 700;
  white-space: pre-wrap;
  cursor: pointer;
  &:focus {
    border: none !important;
    outline: 2px solid ${({ theme }) => theme.mainColor};
  }
  text-align: center;
`;

export const StyledMBTIImgWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledMBTIImg = styled.img`
  width: 95%;
  object-fit: fill;
`;

export const StyledRecommendTextImg = styled.img`
  width: 100%;
  object-fit: fill;
`;

export const StyledSubscriptionImgWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledSubscriptionImg = styled.img`
  width: 100%;
  object-fit: fill;
`;

export const StyledBtnImgWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledBtnImg = styled.img`
  width: 95%;
  object-fit: fill;
`;
