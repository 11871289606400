import { atom } from 'recoil';
import { IUserInfo } from 'types/User.type';

export const startUserInfoAtom = atom<string>({
  key: 'startUserInfoAtom',
  default: '',
});

export const userInfoAtom = atom<IUserInfo>({
  key: 'userInfoAtom',
  default: {
    userId: 0,
    userName: '',
    email: '',
    recommendationId: 0,
    subscriptionIdList: '',
    isAdult: '',
    customerKey: '',
    cardStatus: '',
  },
});
