import styled from 'styled-components';

export const StyledModalBackground = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: fixed;
  bottom: 0;
  background-color: ${({ theme }) => theme.black}95;
  z-index: 99999;
`;

export const StyledModal = styled.div<{}>`
  position: relative;
  width: 80%;
  display: flex;
  flex-direction: column;
  background-color: #f6f6f6;
  border: 4px solid ${(props) => props.theme.fourthColor};
  border-radius: 6px;
`;
