import React from 'react';
import {
  StyledSignUpWrapper,
  StyledHeaderWrapper,
  StyledHeaderTextWrapper,
  StyledHeaderText,
  StyledPlaceHoldWrapper,
  StyledItemWrapper,
  StyledPassWordInputBox,
  StyledButtonWrapper,
  StyledButton,
  StyledMainButtonText,
} from './styles';
import { StyledSpace } from 'utils/styledSpace';
import useLogin from 'hooks/useLogin';

const SignIn = () => {
  const { passwordValue, navigate, idValue, handleLogin } = useLogin();

  return (
    <StyledSignUpWrapper>
      <StyledHeaderWrapper>
        <StyledSpace width="30%" />
        <StyledHeaderTextWrapper>
          <StyledHeaderText>환영합니다</StyledHeaderText>
        </StyledHeaderTextWrapper>
        <StyledSpace width="30%" />
      </StyledHeaderWrapper>

      <StyledSpace height="25%" />

      <StyledItemWrapper>
        <StyledPassWordInputBox
          onChange={(e) => {
            idValue.current = e.target.value;
          }}
          placeholder={'ID'}
          maxLength={20}
        />
        <StyledSpace height="10%" />
        <StyledPassWordInputBox
          type="password"
          onChange={(e) => {
            passwordValue.current[0] = e.target.value;
          }}
          placeholder={'Password'}
          maxLength={16}
        />
      </StyledItemWrapper>

      <StyledSpace height="15%" />

      <StyledButtonWrapper>
        <StyledButton onClick={handleLogin}>
          <StyledMainButtonText textColor="white">로그인</StyledMainButtonText>
        </StyledButton>
        <StyledButton
          backColor={true}
          onClick={() => {
            navigate('/signup');
          }}>
          <StyledMainButtonText textColor="#7C7C7C">
            회원가입
          </StyledMainButtonText>
        </StyledButton>
      </StyledButtonWrapper>
    </StyledSignUpWrapper>
  );
};

export default SignIn;
