import { atom } from 'recoil';
import { ICouponInfo } from 'types/Coupon.tpye';

export const couponInfoAtom = atom<ICouponInfo>({
  key: 'couponInfoAtom',
  default: {
    voucherId: 0,
    code: '',
    userName: '',
    userMail: '',
    month: 0,
    subscriptionName: '',
    status: '',
    description: '',
  },
});
