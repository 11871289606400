import styled from 'styled-components';

export const StyledMainWrapper = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.backGroundColor};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const StyledMainImgWrapper = styled.div`
  width: 95%;
`;

export const StyledMainImg = styled.img`
  width: 100%;
  object-fit: fill;
  border-radius: 6px;
  margin-top: 20px;
`;

export const StyledAllImgWrapper = styled.div`
  width: 95%;
  display: flex;
`;

export const StyledAllImg = styled.img`
  width: 100%;
  border-radius: 6px;
  object-fit: fill;
`;

export const StyledMain2Img = styled.img`
  width: 100%;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  object-fit: fill;
`;

export const StyledTestWineImgWrapper = styled.div`
  width: 95%;
  background-color: #f8f8f8;
  display: flex;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 1vh;
`;

export const StyledTestWineImg = styled.img`
  width: 95%;
  object-fit: fill;
`;

export const StyledInfoWrapper = styled.div`
  width: 100%;
  background-color: #f8f8f8;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const StyledInfoTextWrapper = styled.div`
  padding-top: 2vh;
  width: 90%;
  padding-bottom: 2vh;
`;

export const StyledMain4ImgWrapper = styled.div`
  width: 100%;
  background-color: #f8f8f8;
`;

export const StyledMain4Img = styled.img`
  width: 100%;
  object-fit: fill;
`;
