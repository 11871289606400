import React, { memo, useEffect } from 'react';
import {
  StyledBigBoxWrapper,
  StyledSmallBoxMainText,
  StyledBigBoxMainTextWrapper,
  StyledRadioBtn,
  StyledSelectMainText,
  StyledSelectSubText,
  StyledAmtText,
  StyledAmtWrapper,
  StyledSelectedRadioBtn,
  StyledWineCardWrapper,
  StyledWineCard,
  StyledOptionWrapper,
  StyledOptionTextImg,
  StyledOptionSelectWrapper,
  StyledoptionSelectTopWrapper,
  StyledoptionSelectBottomWrapper,
  StyledSelectImg,
  StyledAmountWrapper,
  StyledMainTextWrapper,
  StyledMainTextImg,
  StyledAmountTextImg,
} from './styles';
import { StyledSpace } from 'utils/styledSpace';
import useBilling from 'hooks/useBilling';
import convertFN from 'utils/convertFN';

const BillingDetail = () => {
  const {
    selectedSubscription,
    selectedSubscriptionBilling,
    selectKindOfSubscription,
    setSelectedSubscriptionBilling,
  } = useBilling();
  const { convertMoneyString } = convertFN();

  useEffect(() => {
    selectKindOfSubscription('당월');
  }, []);

  useEffect(() => {
    if (selectedSubscriptionBilling.subscriptionId === 0) {
      setSelectedSubscriptionBilling(
        JSON.parse(String(localStorage.getItem('subscription'))),
      );
    }
  }, [selectedSubscriptionBilling]);

  return (
    <>
      <StyledSpace height="1%" />
      <StyledBigBoxWrapper>
        <StyledMainTextWrapper>
          <StyledMainTextImg
            src={process.env.PUBLIC_URL + '/icons/cardText.png'}
          />
        </StyledMainTextWrapper>

        <StyledWineCardWrapper>
          <StyledWineCard
            src={
              process.env.PUBLIC_URL +
              '/icons/subscription' +
              selectedSubscriptionBilling.name.split('구독권')[1] +
              '_W.png'
            }
          />
        </StyledWineCardWrapper>

        <StyledOptionWrapper>
          <StyledOptionTextImg
            src={process.env.PUBLIC_URL + '/icons/option.png'}
          />
          <StyledOptionSelectWrapper>
            <StyledoptionSelectTopWrapper
              onClick={() => {
                selectKindOfSubscription('당월');
              }}>
              <StyledSelectImg
                src={
                  selectedSubscription === '당월'
                    ? process.env.PUBLIC_URL + '/icons/option1Y.png'
                    : process.env.PUBLIC_URL + '/icons/option1N.png'
                }
              />
            </StyledoptionSelectTopWrapper>
            <StyledoptionSelectBottomWrapper
              onClick={() => {
                selectKindOfSubscription('익월');
              }}>
              <StyledSelectImg
                src={
                  selectedSubscription === '익월'
                    ? process.env.PUBLIC_URL + '/icons/option2Y.png'
                    : process.env.PUBLIC_URL + '/icons/option2N.png'
                }
              />
            </StyledoptionSelectBottomWrapper>
          </StyledOptionSelectWrapper>
        </StyledOptionWrapper>

        <StyledAmountWrapper>
          <StyledAmountTextImg
            src={process.env.PUBLIC_URL + '/icons/amount.png'}
          />
          <StyledAmtWrapper>
            <StyledAmtText>
              {convertMoneyString(String(selectedSubscriptionBilling.price)) +
                '원'}
            </StyledAmtText>
          </StyledAmtWrapper>
        </StyledAmountWrapper>
      </StyledBigBoxWrapper>
    </>
  );
};

export default memo(BillingDetail);
