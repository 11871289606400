import React, { memo, Dispatch, SetStateAction, useLayoutEffect } from 'react';
import { StyledHeaderWrapper, StyledButton, StyledBackImg } from './styles';
import { useNavigate } from 'react-router-dom';
import HeaderType1 from './HeaderType1';
import HeaderType2 from './HeaderType2';
import HeaderType3 from './HeaderType3';
import useLogin from 'hooks/useLogin';

interface Props {
  handler: Dispatch<SetStateAction<boolean>>;
}

const Header = ({ handler }: Props) => {
  const navigate = useNavigate();
  const { authorityCheck, path } = useLogin();

  useLayoutEffect(() => {
    if (path !== '/niceauth') {
      authorityCheck();
    }
  }, [authorityCheck]);

  return (
    <StyledHeaderWrapper>
      <HeaderType1 handler={handler} navigate={navigate} />
    </StyledHeaderWrapper>
  );
};

export default memo(Header);
