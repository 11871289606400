import React, { useState, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { MbtiResult } from 'constants/constants';
import { IMbtiResult } from 'types/Utils.type';
import { handleGetSubscriptionByMonthAndName } from 'libs/apis/subscription.api';
import { ISubscriptionInfo } from 'types/Subscription.type';
import { useRecoilState } from 'recoil';
import { subscriptionInfoAtom } from 'store/subscription';
import { handleMBTI } from 'libs/apis/user.api';

const useTestWine = () => {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState<number>(0);
  const selectedMBTIAnswer = useRef<any>([
    {
      type: 'tendency',
      A: 0,
      B: 0,
    },
    { type: 'kind', A: 0, B: 0 },
    { type: 'sweet', A: 0, B: 0 },
  ]);
  const mbtiResult = useRef<IMbtiResult>({
    value: 0,
    type: '',
    text: '',
    subscription: '',
    description: '',
  });
  const [selectedSubscriptionBilling, setSelectedSubscriptionBilling] =
    useRecoilState<ISubscriptionInfo>(subscriptionInfoAtom);

  const checkMBTI = () => {
    if (
      selectedMBTIAnswer.current.find((e: any) => e.type === 'tendency').A >
      selectedMBTIAnswer.current.find((e: any) => e.type === 'tendency').B
    ) {
      if (
        selectedMBTIAnswer.current.find((e: any) => e.type === 'kind').A >
        selectedMBTIAnswer.current.find((e: any) => e.type === 'kind').B
      ) {
        if (
          selectedMBTIAnswer.current.find((e: any) => e.type === 'sweet').A >
          selectedMBTIAnswer.current.find((e: any) => e.type === 'sweet').B
        ) {
          return MbtiResult.find((e) => e.type === 'A');
        } else {
          return MbtiResult.find((e) => e.type === 'B');
        }
      } else {
        if (
          selectedMBTIAnswer.current.find((e: any) => e.type === 'sweet').A >
          selectedMBTIAnswer.current.find((e: any) => e.type === 'sweet').B
        ) {
          return MbtiResult.find((e) => e.type === 'C');
        } else {
          return MbtiResult.find((e) => e.type === 'D');
        }
      }
    } else {
      if (
        selectedMBTIAnswer.current.find((e: any) => e.type === 'kind').A >
        selectedMBTIAnswer.current.find((e: any) => e.type === 'kind').B
      ) {
        if (
          selectedMBTIAnswer.current.find((e: any) => e.type === 'sweet').A >
          selectedMBTIAnswer.current.find((e: any) => e.type === 'sweet').B
        ) {
          return MbtiResult.find((e) => e.type === 'E');
        } else {
          return MbtiResult.find((e) => e.type === 'F');
        }
      } else {
        if (
          selectedMBTIAnswer.current.find((e: any) => e.type === 'sweet').A >
          selectedMBTIAnswer.current.find((e: any) => e.type === 'sweet').B
        ) {
          return MbtiResult.find((e) => e.type === 'G');
        } else {
          return MbtiResult.find((e) => e.type === 'H');
        }
      }
    }
  };

  const selectAnswer = useCallback(
    async (selected: string, index: number, type: string) => {
      if (selected === 'A') {
        selectedMBTIAnswer.current.find((e: any) => e.type === type).A++;
      } else {
        selectedMBTIAnswer.current.find((e: any) => e.type === type).B++;
      }

      if (index === 7) {
        mbtiResult.current = checkMBTI()!;
        const userInfo = JSON.parse(String(localStorage.getItem('userInfo')));
        await handleMBTI(userInfo.userId, mbtiResult.current.value);
        const newUserInfo = {
          ...userInfo,
          recommendationId: mbtiResult.current.value,
        };
        localStorage.setItem('userInfo', JSON.stringify(newUserInfo));
      }
      setCurrentStep(index + 1);
    },
    [currentStep, setCurrentStep, selectedMBTIAnswer, mbtiResult, checkMBTI],
  );

  const getSubscriptionList = useCallback(async () => {
    try {
      const currentDate = new Date();
      const data: ISubscriptionInfo = await handleGetSubscriptionByMonthAndName(
        Number(currentDate.getMonth() + 1),
        mbtiResult.current.subscription,
      );

      localStorage.setItem('subscription', JSON.stringify(data));
      setSelectedSubscriptionBilling(data);
      navigate('/billingdetail');
    } catch (e: any) {}
  }, []);

  return {
    currentStep,
    setCurrentStep,
    navigate,
    selectAnswer,
    mbtiResult,
    getSubscriptionList,
  };
};

export default useTestWine;
