import styled from 'styled-components';

export const StyledMainWrapper = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.backGroundColor};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledText = styled.text`
  padding-top: 10vh;
  font-size: 1rem;
  color: black;
  line-height: 120%;
  white-space: pre-wrap;
`;
