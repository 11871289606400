import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const StyledMainWrapper = styled.div`
  padding-top: 10vh;
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.backGroundColor};
`;

export const StyledSmallBoxWrapper = styled.div`
  width: 100%;
  height: 7%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledSmallBoxMainTextWrapper = styled.div`
  width: 35%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const StyledSmallBoxMainText = styled.div`
  font-size: 1.5rem;
  font-weight: 700;
`;

export const StyledSmallBoxSubTextWrapper = styled.div`
  width: 55%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const StyledSmallBoxSubText = styled.div`
  font-size: 1.5rem;
  font-weight: 700;
  color: ${(props) => props.theme.fourthColor};
`;

export const StyledBoxWrapper = styled.div`
  width: 100%;
  height: 24%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
`;

export const StyledBoxMainTextWrapper = styled.div`
  width: 100%;
  height: 20%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const StyledBoxSubTextWrapper = styled.div`
  width: 100%;
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-top: 1px solid gray;
  flex-direction: row;
`;

export const StyledBoxSubTextRightWrapper = styled.div`
  width: 95%;
  height: 95%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
`;

export const StyledBoxSubTextItemWrapper = styled.div`
  width: 100%;
  height: 10%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
`;

export const StyledInfoImgWrapper = styled.div`
  width: 100%;
`;

export const StyledInfoImg = styled.img`
  width: 100%;
  object-fit: fill;
`;

export const StyledBoxSubText = styled.text<{
  fontColor?: boolean;
  underLine?: boolean;
}>`
  font-size: 1.2rem;
  color: ${(props) => (props.fontColor ? props.theme.fourthColor : 'gray')};
  text-decoration: ${(props) => (props.underLine ? 'underline' : undefined)};
  white-space: pre-wrap;
  font-weight: 400;
`;

export const StyledBtnWrapper = styled.div`
  width: 100%;
  height: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledBtn = styled.button`
  width: 80%;
  height: 60%;
  background-color: ${(props) => props.theme.fourthColor};
  color: white;
  font-size: 1.5rem;
  font-weight: 700;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  &:focus {
    border: none !important;
    outline: 2px solid ${({ theme }) => theme.mainColor};
  }
`;

export const StyledProgressWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: gray;
  opacity: 0.5;
`;
