import styled from 'styled-components';

export const StyledMainWrapper = styled.div`
  padding-top: 10vh;
  width: 100%;
  height: 95%;
  background-color: ${(props) => props.theme.backGroundColor};
`;

export const StyledSommelierInfoImgWrapper = styled.div`
  width: 100%;
`;

export const StyledSommelierInfoImg = styled.img`
  width: 100%;
  object-fit: fill;
`;
