import styled from 'styled-components';

export const StyledMainWrapper = styled.div`
  margin-top: 2vh;
  width: 100%;
  background-color: ${(props) => props.theme.backGroundColor};
  padding-bottom: 15vh;
`;

export const StyledMainImg = styled.img`
  width: 100%;
  aspect-ratio: 1/1;
`;

export const StyledBottomWrapper = styled.div`
  width: 100%;
  height: 25%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
`;

export const StyledCardWrapper = styled.div`
  width: 40%;
  height: 80%;
  border-radius: 10px;
  background-color: #e1e7e7;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledTextWrapper = styled.div`
  width: 90%;
  height: 80%;
`;

export const StyledVideoBtnWrapper = styled.div`
  width: 100%;
  height: 5%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.backGroundColor};
`;

export const StyledVideoBtn = styled.div<{ selected?: boolean }>`
  height: 40%;
  aspect-ratio: 1/1;
  border-radius: 100px;
  background-color: ${(props) =>
    props.selected ? props.theme.fourthColor : '#C4C4C4'};
`;

export const StyledVideoWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledSommelierInfoImgWrapper = styled.div`
  width: 100%;
`;

export const StyledSommelierInfoImg = styled.img`
  width: 100%;
  object-fit: fill;
`;
