import React, {
  CSSProperties,
  memo,
  useState,
  RefObject,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import {
  StyledModal,
  StyledModalBackground,
  StyledTitleWrapper,
  StyledTitle,
  StyledBottomWrapper,
  StyledBtnWrapper,
  StyledBtnImg,
  StyledText,
  StyledCarImgWrapper,
  StyledCarImg,
  StyledTextImgWrapper,
  StyledTextImg,
  StyledTextNameWrapper,
} from './styles';
import PortalForUnSubscription from './PortalForUnSubscription';
import { StyledSpace } from 'utils/styledSpace';
import { ISubscriptionInfo } from 'types/Subscription.type';
import { IUserInfo } from 'types/User.type';
import MainModal from 'components/MainModal';
import PortalForMainModal from 'components/MainModal/PortalForMainModal';

interface IModal {
  onClose: () => void;
  width?: string;
  height?: string;
  customStyle?: CSSProperties;
  item: ISubscriptionInfo;
  userInfo: IUserInfo;
  handlerUnSubscription: () => void;
}

const ModalForUnSubscription = ({
  onClose,
  customStyle,
  width = '700px',
  height = '400px',
  item,
  userInfo,
  handlerUnSubscription,
}: IModal) => {
  const currentMonth = new Date().getMonth() + 1;
  const modalRef: RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);
  const [openUploadMainModal, setOpenUploadMainModal] =
    useState<boolean>(false);

  const onClickOutSide = useCallback(
    (e: MouseEvent): void => {
      if (
        modalRef.current &&
        !modalRef.current.contains(e.target as Node) &&
        openUploadMainModal
      ) {
        onClose();
      }
    },
    [onClose],
  );

  useEffect(() => {
    document.addEventListener('mousedown', onClickOutSide);
    // 바깥영역클릭시 모달 종료
    return () => {
      document.removeEventListener('mousedown', onClickOutSide);
    };
  }, [onClickOutSide]);

  const MainModalContent = () => {
    return (
      <PortalForMainModal>
        <MainModal
          type={8}
          onClose={() => setOpenUploadMainModal(false)}
          onYes={handlerUnSubscription}
          onNo={() => setOpenUploadMainModal(false)}
        />
      </PortalForMainModal>
    );
  };

  return (
    <PortalForUnSubscription>
      <StyledModalBackground>
        <StyledModal
          width={width}
          height={height}
          style={customStyle}
          ref={modalRef}>
          <StyledTitleWrapper>
            <StyledTitle>{'구독 해지'}</StyledTitle>
          </StyledTitleWrapper>

          <StyledCarImgWrapper>
            <StyledCarImg
              src={
                process.env.PUBLIC_URL +
                '/icons/subscription/subInfo_' +
                String(item.month) +
                '_' +
                item.name.split('구독권')[1] +
                '.png'
              }
            />
          </StyledCarImgWrapper>

          <StyledTextImgWrapper>
            <StyledTextImg
              src={
                process.env.PUBLIC_URL +
                '/icons/unsubscription/' +
                (item.month === currentMonth
                  ? String(item.month + 1)
                  : String(item.month)) +
                '_' +
                item.name.split('구독권')[1] +
                '.png'
              }
            />
            <StyledTextNameWrapper>
              <StyledText size="1.5rem" weight={900} color="#74445A">
                {userInfo.userName}
              </StyledText>
            </StyledTextNameWrapper>
          </StyledTextImgWrapper>

          <StyledBtnWrapper>
            <StyledBtnImg
              src={process.env.PUBLIC_URL + '/icons/sub.png'}
              onClick={onClose}
            />
            <StyledSpace height="1vh" />
            <StyledBtnImg
              src={process.env.PUBLIC_URL + '/icons/unSub.png'}
              onClick={() => {
                setOpenUploadMainModal(true);
              }}
            />
          </StyledBtnWrapper>
        </StyledModal>
      </StyledModalBackground>
      {openUploadMainModal && MainModalContent()}
    </PortalForUnSubscription>
  );
};

export default memo(ModalForUnSubscription);
