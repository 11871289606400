import styled from 'styled-components';

export const StyledSpace = styled.div<{
  height?: string;
  backGroundColor?: string;
  width?: string;
  topBorder?: string;
  flexShrink?: boolean;
}>`
  width: ${(props) => (props.width ? props.width : '100%')};
  height: ${(props) => (props.height ? props.height : '10%')};
  background-color: ${(props) =>
    props.backGroundColor
      ? props.backGroundColor
      : props.theme.backGroundColor};
  border-top: ${(props) => (props.topBorder ? props.topBorder : undefined)};
  flex-shrink: ${(props) => (props.flexShrink ? 0 : undefined)};
`;
