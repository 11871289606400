import {
  StyledImgWrapper,
  StyledImg,
  StyledBottomWrapper,
  StyledType1Wrapper,
  StyledBTNImg,
} from './styles';

interface IModal {
  onClose?: () => void;
  type?: number;
}

const MainModalType1 = ({ onClose, type }: IModal) => {
  return (
    <StyledType1Wrapper>
      <StyledImgWrapper>
        <StyledImg
          src={process.env.PUBLIC_URL + '/icons/modal' + String(type) + '.png'}
        />
      </StyledImgWrapper>
      <StyledBottomWrapper>
        <StyledBTNImg
          src={process.env.PUBLIC_URL + '/icons/okBTN.png'}
          onClick={onClose}
        />
      </StyledBottomWrapper>
    </StyledType1Wrapper>
  );
};

export default MainModalType1;
