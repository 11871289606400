import { useCallback, useRef, useState } from 'react';
import {
  ILogin,
  ILoginResponse,
  ISignUpRequest,
  IRegister,
  IMaintainResponse,
} from 'types/Login.type';
import {
  handlePostLogin,
  handleSignUp,
  handleCardCheck,
  handleMaintain,
} from 'libs/apis/login.api';
import { handleDecodor } from 'libs/apis/token.api';
import { useNavigate } from 'react-router-dom';
import Toast from 'libs/Toast';
import { useRecoilValue, useRecoilState } from 'recoil';
import { startUserInfoAtom } from 'store/user';
import cryptoFn from 'utils/cryptoFn';
import { IUserInfo, IUserPasswordChange } from 'types/User.type';
import { userInfoAtom } from 'store/user';
import {
  handleGetLoginUserInfo,
  handleCardName,
  handlePasswordChange,
  handleWithdraw,
} from 'libs/apis/user.api';
import { getDateSubtract } from 'utils/dateFn';
import usePathParams from 'hooks/usePathParams';
import { IDecode, IDecoder } from 'types/Utils.type';

const useLogin = () => {
  const path: string = usePathParams();
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useRecoilState<IUserInfo>(userInfoAtom);
  const passwordValue = useRef<string[]>(['', '']);
  const idValue = useRef<string>('');
  const startUserInfo = useRecoilValue(startUserInfoAtom);
  const nameValue = useRef<string>('');
  const { sha256FN } = cryptoFn();
  const [openUploadModal, setOpenUploadModal] = useState<boolean>(false);
  const [cardName, setCardName] = useState<string>('');
  const [clickedPasswordChange, setClickedPasswordChange] =
    useState<boolean>(false);
  const originPasswordValue = useRef<string>('');
  const [withdrawModal, setWithDrawModal] = useState<boolean>(false);
  const [encode, setEncode] = useState<string>('');
  const encodeMbr = useRef<string>('');
  const [openUploadModalForPassword, setOpenUploadModalForPassword] =
    useState<boolean>(false);
  const passwordValidation = useRef<boolean>(false);
  const [maintainValidation, setMaintainValidation] =
    useState<IMaintainResponse>();
  const [customNumber, setCustomNumber] = useState<number>(0);

  const handlerMaintain = useCallback(async (): Promise<void> => {
    try {
      const data: IMaintainResponse = await handleMaintain();
      setMaintainValidation(data);
    } catch (e: any) {}
  }, []);

  const handleLogin = useCallback(async (): Promise<void> => {
    const passwordSha256 = await sha256FN(passwordValue.current[0]);
    const loginRequest: ILogin = {
      email: idValue.current,
      pw: passwordSha256,
    };
    try {
      if (idValue.current === '') {
        throw 'ID를 입력해주세요.';
      }
      const data: ILoginResponse = await handlePostLogin(loginRequest);
      if (data.data.state === 400) {
        throw data.data.message;
      }
      const userId: number = data.data.data.loginResult.first;
      const userData: IUserInfo = (await handleGetLoginUserInfo(userId)).data;
      const cardCheck: string = await handleCardCheck(userId);
      const currentDate = new Date();
      const currentDateString = currentDate.toISOString().substring(0, 10);
      const userDataTemp = {
        userId: userId,
        userName: userData.userName,
        email: userData.email,
        recommendationId: userData.recommendationId,
        subscriptionIdList: userData.subscriptionIdList,
        isAdult: userData.isAdult,
        customerKey: userData.customerKey,
        cardStatus: cardCheck,
      };
      setUserInfo(userDataTemp);
      localStorage.setItem(
        'token',
        data.data.data.loginResult.second.accessToken,
      );
      localStorage.setItem('userInfo', JSON.stringify(userDataTemp));
      localStorage.setItem('loginDate', currentDateString);
      navigate('/main');
    } catch (e: any) {
      Toast.errorToast(e);
    }
  }, [idValue, passwordValue]);

  const handlerCardName = useCallback(async () => {
    try {
      const userInfo = JSON.parse(String(localStorage.getItem('userInfo')));
      const data = await handleCardName(userInfo.userId);
      setCardName(data);
    } catch (e: any) {}
  }, [cardName, setCardName]);

  const handleKeyPress = useCallback(
    async (e: React.KeyboardEvent<HTMLInputElement>): Promise<void> => {
      if (e.key === 'Enter') {
        await handleLogin();
      }
    },
    [handleLogin],
  );

  const checkIdValidation = () => {
    const pattern = /^[a-zA-Z0-9]{5,20}$/;
    if (!pattern.test(idValue.current)) {
      Toast.errorToast(
        '아이디는 대/소문자, 숫자로 구성된 5 ~ 20자이어야 합니다.',
      );
      return false;
    }
  };

  const checkPasswordMatch = (type?: boolean) => {
    if (passwordValue.current[0] === passwordValue.current[1]) {
      const pattern = /^(?=.*[a-z])(?=.*[$@$!%*?&])(?=.*[0-9]).{8,16}$/;
      if (!pattern.test(passwordValue.current[0])) {
        if (type) {
          Toast.error2Toast(
            '비밀번호는 소문자, 특수기호, 숫자가 포함된 8 ~ 16자이어야 합니다.',
          );
        } else {
          Toast.errorToast(
            '비밀번호는 소문자, 특수기호, 숫자가 포함된 8 ~ 16자이어야 합니다.',
          );
        }
        return false;
      }
      return true;
    } else {
      if (type) {
        Toast.error2Toast('비밀번호가 일치하지 않습니다.');
      } else {
        Toast.errorToast('비밀번호가 일치하지 않습니다.');
      }
      return false;
    }
  };

  const withdraw = useCallback(async () => {
    try {
      const userInfo = JSON.parse(String(localStorage.getItem('userInfo')));
      await handleWithdraw(userInfo.userId);
      localStorage.clear();
      window.location.replace('/signin');
    } catch (e: any) {}
  }, []);

  const changePassword = useCallback(async () => {
    try {
      if (checkPasswordMatch(true) === false) {
        return;
      }
      if (originPasswordValue.current === passwordValue.current[0]) {
        Toast.error2Toast('기존 비밀번호와 같습니다.');
        return;
      }
      const originPasswordSha256 = await sha256FN(originPasswordValue.current);
      const passwordSha256 = await sha256FN(passwordValue.current[1]);
      const req: IUserPasswordChange = {
        email: userInfo.email,
        originPassword: originPasswordSha256,
        changedPassword: passwordSha256,
      };
      const result = await handlePasswordChange(req);
      if (!result.data.status) {
        throw result.data.message;
      }
      setOpenUploadModalForPassword(true);
    } catch (e: any) {
      Toast.error2Toast(e);
    }
  }, [
    openUploadModalForPassword,
    setOpenUploadModalForPassword,
    passwordValidation,
  ]);

  const signUp = useCallback(async (): Promise<void> => {
    if (checkIdValidation() === false) {
      return;
    }
    if (checkPasswordMatch() === false) {
      return;
    }

    const passwordSha256 = await sha256FN(passwordValue.current[1]);
    const register: IRegister = {
      email: idValue.current,
      pw: passwordSha256,
      role: 'USER',
      name: nameValue.current,
      membersNumber: encodeMbr.current,
    };
    const req: ISignUpRequest = {
      register: register,
    };
    try {
      const signUpUserId = await handleSignUp(req);
      if (signUpUserId.data.state === 400) {
        if (signUpUserId.data.message === 'duplicated name') {
          throw '이미 가입한 내역이 있습니다.';
        } else {
          throw '이미 존재하는 ID 입니다.';
        }
      }
      setUserInfo({
        ...userInfo,
        userId: signUpUserId.data.data.userId,
        userName: nameValue.current,
      });
      navigate('/signin');
    } catch (e: any) {
      Toast.errorToast(e);
    }
  }, []);

  const authorityCheck = useCallback(async (): Promise<void> => {
    const userInfo = JSON.parse(String(localStorage.getItem('userInfo')));
    if (userInfo == null || userInfo.userId === 0 || getDateSubtract() >= 3) {
      window.location.replace('/');
    } else {
      setUserInfo({
        userId: userInfo.userId,
        userName: userInfo.userName,
        email: userInfo.email,
        recommendationId: userInfo.recommendationId,
        subscriptionIdList: userInfo.subscriptionIdList,
        isAdult: userInfo.isAdult,
        customerKey: userInfo.customerKey,
        cardStatus: userInfo.cardStatus,
      });
    }

    if (userInfo.isAdult === 'N') {
      window.location.replace('/niceauth');
    }
  }, [navigate]);

  const logout = () => {
    localStorage.clear();
    window.location.replace('/signin');
  };

  const handlerDecodor = useCallback(
    async (encodeMbrNm: String, encodeLpntCustNo: String) => {
      try {
        const req: IDecode = {
          encodeMbrNm: encodeMbrNm,
          encodeLpntCustNo: encodeLpntCustNo,
        };
        const data: IDecoder = await handleDecodor(req);
        localStorage.setItem('encode', JSON.stringify(data));
        setCustomNumber(data.customNumber);
      } catch (e: any) {}
    },
    [],
  );

  return {
    handleLogin,
    handleKeyPress,
    passwordValue,
    signUp,
    navigate,
    authorityCheck,
    idValue,
    startUserInfo,
    nameValue,
    userInfo,
    path,
    logout,
    openUploadModal,
    setOpenUploadModal,
    handlerCardName,
    cardName,
    clickedPasswordChange,
    setClickedPasswordChange,
    changePassword,
    originPasswordValue,
    withdraw,
    withdrawModal,
    setWithDrawModal,
    handlerDecodor,
    encode,
    setEncode,
    encodeMbr,
    openUploadModalForPassword,
    setOpenUploadModalForPassword,
    passwordValidation,
    handlerMaintain,
    maintainValidation,
    customNumber,
  };
};

export default useLogin;
