import styled from 'styled-components';

export const StyledMainWrapper = styled.div`
  padding-top: 5vh;
  padding-bottom: 5vh;
  width: 100%;
  background-color: ${(props) => props.theme.backGroundColor};
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const StyledBtnWrapper = styled.div`
  width: 95%;
  height: 10vh;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const StyledSuccessTopButtonWrapper = styled.div`
  width: 100%;
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledSuccessTopButton = styled.button`
  width: 48%;
  height: 100%;
  border-radius: 4px;
  outline: 0;
  border: none;
  background-color: #decfd4;
  &:focus {
    border: none !important;
    outline: 2px solid ${({ theme }) => theme.mainColor};
  }
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
`;

export const StyledSuccesTopText = styled.text`
  font-size: 1rem;
  font-weight: 700;
  color: ${(props) => props.theme.fourthColor};
`;

export const StyledSuccessBottomButtonImg = styled.img`
  width: 100%;
  &:focus {
    border: none !important;
    outline: 2px solid ${({ theme }) => theme.mainColor};
  }
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledSuccessBottomText = styled.text`
  font-size: 1.5rem;
  font-weight: 700;
  color: white;
`;

export const StyledMainImgWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledMainImg = styled.img`
  width: 95%;
  object-fit: fill;
`;

export const StyledProgressWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: gray;
  opacity: 0.5;
`;

export const StyledSuccessBottomButton = styled.div`
  width: 100%;
  height: 50%;
  outline: 0;
  border: none;
  background-color: ${(props) => props.theme.fourthColor};
  &:focus {
    border: none !important;
    outline: 2px solid ${({ theme }) => theme.mainColor};
  }
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
`;
