import customAxios from 'libs/CustomAxios';
import { IUserPasswordChange, IUserInfoResponse } from 'types/User.type';

export const handleGetLoginUserInfo = async (
  userId: number,
): Promise<IUserInfoResponse> => {
  const url: string = `/user/findByUserId?userId=${userId}`;
  const { data }: { data: IUserInfoResponse } = await customAxios.get(url);
  return data;
};

export const handleCardName = async (userId: number): Promise<string> => {
  const url: string = `/user/cardName?userId=${userId}`;
  const { data } = await customAxios.get(url);
  return data.data;
};

export const handlePasswordChange = async (
  req: IUserPasswordChange,
): Promise<any> => {
  const url: string = `/auth/password/change`;
  const data: any = await customAxios.post(url, req);
  return data.data;
};

export const handleWithdraw = async (userId: number): Promise<any> => {
  const req = {
    userId: userId,
  };
  const url: string = `/auth/withdraw`;
  const data: any = await customAxios.post(url, req);
  return data.data;
};

export const handleMBTI = async (
  userId: number,
  recommendationId: number,
): Promise<any> => {
  const req = {
    userId: userId,
    recommendationId: recommendationId,
  };
  const url: string = `/user/recommendation`;
  const data: any = await customAxios.post(url, req);
  return data.data;
};
