import React, { memo, useState, useEffect } from 'react';
import Header from 'components/Header';
import {
  StyledMainWrapper,
  StyledMainTextWrapper,
  StyledMainText,
  StyledCouponWrapper,
  StyledTopWrapper,
  StyledMainTextImg,
} from './styles';
import { StyledSpace } from 'utils/styledSpace';
import CouponCard from './CouponCard';
import useCoupon from 'hooks/useCoupon';
import PortalForCoupon from './ModalForCoupon/PortalForCoupon';
import ModalForCoupon from './ModalForCoupon';
import { ICouponInfo } from 'types/Coupon.tpye';
import PortalForOption from 'components/common/ModalForOption/PortalForOption';
import ModalForOption from 'components/common/ModalForOption';
import { moveToTop } from 'utils/moveToTop';

const Coupon = () => {
  const {
    openUploadModal,
    setOpenUploadModal,
    getCouponList,
    couponList,
    userInfo,
    openUploadModalHeader,
    setOpenUploadModalHeader,
  } = useCoupon();

  useEffect(() => {
    moveToTop();
    getCouponList();
  }, []);

  const ModalContentHeader = () => {
    return (
      <PortalForOption>
        <ModalForOption
          width="50%"
          height="30%"
          isNoButton
          isYesButton
          onClose={() => setOpenUploadModalHeader(false)}
          onNo={() => setOpenUploadModalHeader(false)}
          onYes={() => {}}>
          {/* <StyledModalText>{'삭제하시겠습니까?'}</StyledModalText> */}
        </ModalForOption>
      </PortalForOption>
    );
  };

  const ModalContent = () => {
    return (
      <PortalForCoupon>
        <ModalForCoupon
          width="100%"
          height="30%"
          onClose={() => setOpenUploadModal(false)}
        />
      </PortalForCoupon>
    );
  };

  return (
    <StyledMainWrapper>
      <Header handler={setOpenUploadModalHeader} />
      <StyledSpace height="2vh" />
      <StyledMainTextWrapper>
        <StyledMainText>{userInfo.userName}</StyledMainText>
        <StyledMainTextImg
          src={process.env.PUBLIC_URL + '/icons/couponHeaderText.png'}
        />
      </StyledMainTextWrapper>

      <StyledSpace height="3vh" />
      <StyledCouponWrapper>
        {couponList.length !== 0 ? (
          couponList.map((item: ICouponInfo) => {
            return (
              <>
                <CouponCard
                  item={item}
                  handler={setOpenUploadModal}
                  used={item.status}
                />
              </>
            );
          })
        ) : (
          <StyledTopWrapper>
            <text style={{ fontSize: '1rem', fontWeight: '700' }}>
              사용가능한 교환권이 없습니다.
            </text>
          </StyledTopWrapper>
        )}
      </StyledCouponWrapper>
      {openUploadModal && ModalContent()}
      {openUploadModalHeader && ModalContentHeader()}
    </StyledMainWrapper>
  );
};

export default memo(Coupon);
