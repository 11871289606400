import React, { memo, useState, useEffect } from 'react';
import Header from 'components/Header';
import {
  StyledMainWrapper,
  StyledTopWrapper,
  StyledBottomWrapper,
  StyledButtonItem,
  StyledButtonItemText,
  StyledCardWrapper,
  StyledCardItemWapper,
  StyledCardText,
  StyledMBTIImgWrapper,
  StyledMBTIImg,
  StyledBtnWrapper,
  StyledCardTextWrapper,
} from './styles';
import useLogin from 'hooks/useLogin';
import PortalForOption from 'components/common/ModalForOption/PortalForOption';
import ModalForOption from 'components/common/ModalForOption';
import { StyledSpace } from 'utils/styledSpace';
import PasswordChange from './PasswordChange';
import customTossPayments from 'libs/CustomTossPayment';
import MainModal from 'components/MainModal';
import PortalForMainModal from 'components/MainModal/PortalForMainModal';
import useCoupon from 'hooks/useCoupon';

const MyPage = () => {
  const {
    userInfo,
    logout,
    openUploadModal,
    setOpenUploadModal,
    handlerCardName,
    cardName,
    clickedPasswordChange,
    setClickedPasswordChange,
    withdraw,
    navigate,
    withdrawModal,
    setWithDrawModal,
    openUploadModalForPassword,
    setOpenUploadModalForPassword,
  } = useLogin();
  const { loadTossPaymentsPayView } = customTossPayments();
  const { getCouponList, couponStatusValidation } = useCoupon();

  useEffect(() => {
    handlerCardName();
    getCouponList();
  }, []);

  const ModalContent = () => {
    return (
      <PortalForOption>
        <ModalForOption
          width="50%"
          height="30%"
          isNoButton
          isYesButton
          onClose={() => setOpenUploadModal(false)}
          onNo={() => setOpenUploadModal(false)}
          onYes={() => {}}>
          {/* <StyledModalText>{'삭제하시겠습니까?'}</StyledModalText> */}
        </ModalForOption>
      </PortalForOption>
    );
  };

  const MainModalContent = () => {
    return (
      <PortalForMainModal>
        <MainModal
          type={
            couponStatusValidation || userInfo.subscriptionIdList.length > 1
              ? 7
              : 6
          }
          onClose={() => setWithDrawModal(false)}
          onYes={withdraw}
          onNo={() => setWithDrawModal(false)}
        />
      </PortalForMainModal>
    );
  };

  return (
    <StyledMainWrapper>
      <Header handler={setOpenUploadModal} />
      {!clickedPasswordChange && (
        <>
          <StyledTopWrapper>
            <StyledCardWrapper>
              <StyledCardItemWapper back="#74445A" topRadius="6px" flex="row">
                <StyledCardText color="white" size="1.5rem">
                  {userInfo.userName}
                </StyledCardText>
                <StyledCardText color="white" size="1rem">
                  {' 님'}
                </StyledCardText>
              </StyledCardItemWapper>

              <StyledMBTIImgWrapper>
                <StyledMBTIImg
                  src={process.env.PUBLIC_URL + '/icons/mypageID.png'}
                />
                <StyledCardTextWrapper>
                  <StyledCardText color="#74445A" size="1.5rem">
                    {userInfo.email}
                  </StyledCardText>
                </StyledCardTextWrapper>
              </StyledMBTIImgWrapper>

              <StyledMBTIImgWrapper>
                <StyledMBTIImg
                  src={
                    process.env.PUBLIC_URL +
                    '/icons/MBTI/MBTI_' +
                    userInfo.recommendationId +
                    '.png'
                  }
                />
                <StyledBtnWrapper
                  onClick={() => {
                    navigate('/testwine');
                  }}
                />
              </StyledMBTIImgWrapper>

              <StyledMBTIImgWrapper>
                <StyledMBTIImg
                  src={process.env.PUBLIC_URL + '/icons/mypageCard.png'}
                />
                <StyledBtnWrapper
                  onClick={() => loadTossPaymentsPayView('mypage')}
                />
                <StyledCardTextWrapper>
                  <StyledCardText color="#74445A" size="1.5rem">
                    {userInfo.cardStatus === 'Y' ? cardName + '카드' : '없음'}
                  </StyledCardText>
                </StyledCardTextWrapper>
              </StyledMBTIImgWrapper>
            </StyledCardWrapper>
          </StyledTopWrapper>

          <StyledBottomWrapper>
            <StyledButtonItem
              backGroundColor="#74445A"
              onClick={() => {
                setClickedPasswordChange(true);
              }}>
              <StyledButtonItemText color="#FFFFFF">
                비밀번호 변경
              </StyledButtonItemText>
            </StyledButtonItem>
            <StyledSpace height="1vh" />
            <StyledButtonItem
              backGroundColor="#DECFD4"
              onClick={() => setWithDrawModal(true)}>
              <StyledButtonItemText color="#6C3B52">
                계정 탈퇴
              </StyledButtonItemText>
            </StyledButtonItem>
            <StyledSpace height="1vh" />
            <StyledButtonItem backGroundColor="#FFFFFF" onClick={logout}>
              <StyledButtonItemText color="#5C1E37">
                로그 아웃
              </StyledButtonItemText>
            </StyledButtonItem>
          </StyledBottomWrapper>
        </>
      )}
      {clickedPasswordChange && <PasswordChange />}
      {openUploadModal && ModalContent()}
      {withdrawModal && MainModalContent()}
    </StyledMainWrapper>
  );
};

export default memo(MyPage);
