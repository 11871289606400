import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const StyledHeaderWrapper = styled.div`
  height: 10vh;
  width: 100%;
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.backGroundColor};
  z-index: 999999 !important;
`;

export const StyledButton = styled.button`
  width: 10%;
  height: 90%;
  outline: 0;
  border: none;
  cursor: pointer;
  background-color: ${(props) => props.theme.backGroundColor};
`;

export const StyledSpace = styled.div`
  height: 100%;
  width: 5%;
`;

export const StyledHeaderLeftWrapper = styled.div`
  height: 100%;
  width: 70%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const StyledHeaderRightWrapper = styled.div`
  height: 100%;
  width: 25%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
`;

export const StyledWineImg = styled.img`
  height: 100%;
  object-fit: contain;
`;

export const StyledLinkBtn = styled(Link)`
  height: 80%;
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledBtnImg = styled.img`
  height: 50%;
  object-fit: contain;
  cursor: pointer;
  &:focus {
    border: none !important;
    outline: 2px solid ${({ theme }) => theme.mainColor};
  }
`;

export const StyledMainText = styled.text`
  color: ${(props) => props.theme.fourthColor};
  font-size: 0.9rem;
  font-weight: 700;
`;

export const StyledBackImg = styled.img`
  cursor: pointer;
  object-fit: contain;
  background-color: aliceblue;
`;
