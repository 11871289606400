import styled from 'styled-components';

export const StyledMainWrapper = styled.div`
  width: 100%;
  height: 100vh;
  background-color: ${(props) => props.theme.backGroundColor};
`;

export const StyledMainImgWrapper = styled.div`
  width: 100%;
  height: 58vh;
`;

export const StyledMainImg = styled.img`
  width: 100%;
  object-fit: fill;
`;

export const StyledStepperWrapper = styled.div`
  width: 100%;
  height: 20vh;
  background-color: ${(props) => props.theme.backGroundColor};
  display: flex;
  align-items: center;
`;

export const StyledLine = styled.div`
  width: 80%;
  height: 0.8%;
  background-color: #c4c4c4;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  margin-left: 10%;
`;

export const StyledCheckedLine = styled.div<{ width: string }>`
  width: ${(props) => props.width};
  height: 0.8%;
  background-color: ${(props) => props.theme.fourthColor};
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99998;
  margin-left: 10%;
`;

export const StyledCheckedCircle = styled.div<{
  margin: string;
  selected: boolean;
}>`
  height: ${(props) => (props.selected ? '2%' : '1%')};
  aspect-ratio: 1/1;
  border-radius: 100%;
  z-index: 99999;
  margin-left: ${(props) => props.margin};
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #413d4b;
`;

export const StyledCheckedBigCircle = styled.div<{
  margin: string;
  selected: boolean;
}>`
  height: ${(props) => (props.selected ? '4%' : undefined)};
  aspect-ratio: 1/1;
  border-radius: 100%;
  z-index: 99990;
  margin-left: ${(props) => props.margin};
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.mainColor};
`;

export const StyledSelectImgWrapper = styled.div`
  height: 8vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledSelectImg = styled.img`
  width: 95%;
  object-fit: fill;
`;

export const StyledQuestionImg = styled.img`
  width: 100%;
  height: 37vh;
  object-fit: fill;
`;
