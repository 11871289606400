import styled from 'styled-components';

export const StyledModalBackground = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  text-align: center;
  position: fixed;
  bottom: 0;
  background-color: ${({ theme }) => theme.black}95;
  z-index: 9999999 !important;
`;

export const StyledModal = styled.div`
  position: relative;
  width: 100%;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.backGroundColor};
  justify-content: flex-start;
  align-items: center;
  overflow-y: scroll;
`;

export const StyledMainImg = styled.img`
  width: 100%;
  object-fit: fill;
`;

export const StyledBtnWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const StyledBtn = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledBtnSub = styled.div`
  width: 50%;
  height: 100%;
`;

export const StyledBtnImg = styled.img`
  width: 100%;
  object-fit: fill;
`;
