import React, {
  CSSProperties,
  memo,
  ReactNode,
  RefObject,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import {
  StyledModal,
  StyledModalBackground,
  StyledBtnWrapper,
  StyledBtnImg,
  StyledMainImg,
  StyledBtn,
  StyledBtnSub,
} from './styles';
import PortalForNoticeModal from './PortalForNoticeModal';

interface IModal {
  onClose?: () => void;
  onYes?: () => void;
  url?: string;
  connectLink?: string;
}

const MainModal = ({ onClose, onYes, url, connectLink }: IModal) => {
  const modalRef: RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);

  const onClickOutSide = useCallback(
    (e: MouseEvent): void => {
      if (modalRef.current && !modalRef.current.contains(e.target as Node)) {
        onClose!();
      }
    },
    [onClose],
  );

  const handleOpenNewTab = (url: string) => {
    window.open(url, '_blank', 'noopener, noreferrer');
  };

  useEffect(() => {
    document.addEventListener('mousedown', onClickOutSide);
    // 바깥영역클릭시 모달 종료
    return () => {
      document.removeEventListener('mousedown', onClickOutSide);
    };
  }, [onClickOutSide]);

  return (
    <PortalForNoticeModal>
      <StyledModalBackground>
        <StyledModal ref={modalRef}>
          <StyledMainImg
            src={url}
            onClick={() =>
              handleOpenNewTab(connectLink === undefined ? '' : connectLink)
            }
          />
          <StyledBtnWrapper>
            <StyledBtn>
              <StyledBtnSub onClick={onYes} />
              <StyledBtnSub onClick={onClose} />
            </StyledBtn>
            <StyledBtnImg
              src={process.env.PUBLIC_URL + '/icons/noticeBtn.png'}
            />
          </StyledBtnWrapper>
        </StyledModal>
      </StyledModalBackground>
    </PortalForNoticeModal>
  );
};

export default memo(MainModal);
