import customAxios from 'libs/CustomAxios';
import { IResponse } from 'types/Response.type';
import { IGetYoutubeUrlListReponse, IGetPopUpImage } from 'types/Main.type';

export const handleGetYoutubeUrlList =
  async (): Promise<IGetYoutubeUrlListReponse> => {
    const url: string = `/youtubeShorts`;
    const { data } = await customAxios.get(url);
    return data;
  };

export const handleGetPopUpIamge = async (): Promise<IGetPopUpImage> => {
  const url: string = `/popupImage`;
  const { data } = await customAxios.get(url);
  return data.data;
};
