import { loadTossPayments } from '@tosspayments/payment-sdk';
import { useRecoilValue } from 'recoil';
import { userInfoAtom } from 'store/user';

const CustomTossPayments = () => {
  const userInfo = useRecoilValue(userInfoAtom);
  const clientKey = process.env.REACT_APP_CLIENT_KEY;
  const successUrl = process.env.REACT_APP_SUCCESS_URL;
  const failUrl = process.env.REACT_APP_FAIL_URL;

  const loadTossPaymentsPayView = async (type: string) => {
    loadTossPayments(clientKey!).then((tossPayments) => {
      tossPayments
        .requestBillingAuth('카드', {
          // 결제 수단 파라미터
          // 빌링키 발급 요청을 위한 파라미터
          customerKey: userInfo.customerKey,
          successUrl: successUrl! + '&type=' + type,
          failUrl: failUrl!,
        })
        .catch(function (error: any) {
          if (error.code === 'USER_CANCEL') {
            // 결제 고객이 결제창을 닫았을 때 에러 처리
            console.log('USER_CANCEL: ', error);
          } else if (error.code === 'INVALID_CARD_COMPANY') {
            // 유효하지 않은 카드 코드에 대한 에러 처리
            console.log('INVALID_CARD_COMPANY: ', error);
          }
        });
    });
  };

  return {
    loadTossPaymentsPayView,
  };
};

export default CustomTossPayments;
