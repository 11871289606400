import React, {
  memo,
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
} from 'react';
import {
  StyledTopImgWrapper,
  StyledTopImg,
  StyledBottomWrapper,
  StyledBottomImg,
} from './styles';
import { StyledSpace } from 'utils/styledSpace';
import { ICouponInfo } from 'types/Coupon.tpye';
import useCoupon from 'hooks/useCoupon';

interface Props {
  handler: Dispatch<SetStateAction<boolean>>;
  item: ICouponInfo;
  used?: string;
}

const CouponCard = ({ used, item, handler }: Props) => {
  const { setSelectedCoupon } = useCoupon();

  return (
    <>
      <StyledTopImgWrapper>
        <StyledTopImg
          filter={used === 'USED'}
          src={
            process.env.PUBLIC_URL +
            '/icons/coupon/' +
            String(item.month - 12) +
            '_' +
            String(item.subscriptionName.split('구독권')[1]) +
            '.png'
          }
        />
      </StyledTopImgWrapper>
      <StyledBottomWrapper
        onClick={() => {
          if (used !== 'USED') {
            setSelectedCoupon(item);
            handler(true);
          }
        }}>
        <StyledBottomImg
          src={
            used === 'NOT_USED'
              ? process.env.PUBLIC_URL + '/icons/coupon/use.png'
              : used === 'USED'
              ? process.env.PUBLIC_URL + '/icons/coupon/used.png'
              : process.env.PUBLIC_URL + '/icons/coupon/expired.png'
          }
        />
      </StyledBottomWrapper>
      <StyledSpace height="3vh" />
    </>
  );
};

export default memo(CouponCard);
