import styled from 'styled-components';

export const StyledItemWrapper = styled.div`
  height: 70%;
  width: 100%;
  background-color: ${(props) => props.theme.backGroundColor};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const StyledPassWordInputBox = styled.input`
  height: 10%;
  width: 90%;
  border: none;
  padding-left: 5%;
  border-radius: 16px;
`;

export const StyledBtnImgWrapper = styled.div`
  width: 100%;
  height: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledBtnImg = styled.img`
  width: 80%;
  object-fit: fill;
`;
