import { useRecoilState } from 'recoil';
import { globalWidthHeight } from 'store/utils';
import { IWidthHeigth } from 'types/Utils.type';

const useGetWidthHeight = () => {
  const [useWidthHeight, setUseWidthHeight] =
    useRecoilState<IWidthHeigth>(globalWidthHeight);

  return {
    useWidthHeight,
    setUseWidthHeight,
  };
};

export default useGetWidthHeight;
