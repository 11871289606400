import React, { memo, useState, useEffect } from 'react';
import Header from 'components/Header';
import {
  StyledMainWrapper,
  StyledBtnWrapper,
  StyledSuccessTopButtonWrapper,
  StyledSuccessBottomButton,
  StyledSuccessBottomText,
  StyledSuccessTopButton,
  StyledSuccesTopText,
  StyledMainImgWrapper,
  StyledMainImg,
  StyledProgressWrapper,
  StyledSuccessBottomButtonImg,
} from './styles';
import { StyledSpace } from 'utils/styledSpace';
import useBilling from 'hooks/useBilling';

const Fail = () => {
  const { navigate, setOpenUploadModal } = useBilling();

  return (
    <StyledMainWrapper>
      <Header handler={setOpenUploadModal} />
      <StyledMainImgWrapper>
        <StyledMainImg src={process.env.PUBLIC_URL + '/icons/fail.png'} />
      </StyledMainImgWrapper>

      <StyledSpace height="3vh" />
      <StyledBtnWrapper>
        <StyledSuccessBottomButton
          onClick={() => {
            navigate('/main');
          }}>
          <StyledSuccessBottomText>메인 화면</StyledSuccessBottomText>
        </StyledSuccessBottomButton>
      </StyledBtnWrapper>
    </StyledMainWrapper>
  );
};

export default memo(Fail);
