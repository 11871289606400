import styled from 'styled-components';

export const StyledMainWrapper = styled.div`
  height: 95%;
  width: 100%;
  background-color: ${(props) => props.theme.backGroundColor};
`;

export const StyledAllImgWrapper = styled.div`
  width: 100%;
`;

export const StyledAllImg = styled.img`
  width: 100%;
  object-fit: fill;
`;
