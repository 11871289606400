import React, { memo, useState, useEffect } from 'react';
import Header from 'components/Header';
import {
  StyledMainWrapper,
  StyledTopWrapper,
  StyledBottomWrapper,
  StyledTopText,
  StyledCarImgWrapper,
  StyledCarImg,
} from './styles';
import useSubscription from 'hooks/useSubscription';
import { ISubscriptionInfo } from 'types/Subscription.type';
import PortalForOption from 'components/common/ModalForOption/PortalForOption';
import ModalForOption from 'components/common/ModalForOption';
import SubscriptionDetailInfo from 'components/SubscriptionDetailInfo';
import { moveToTop } from 'utils/moveToTop';

const SubscriptionInfo = () => {
  const {
    subscriptionInfoList,
    userInfo,
    getSubscriptionList,
    openUploadModal,
    setOpenUploadModal,
    navigate,
    selectedSubscription,
    setSelectedSubscription,
  } = useSubscription();

  useEffect(() => {
    getSubscriptionList();
    moveToTop();
  }, []);

  const ModalContent = () => {
    return (
      <PortalForOption>
        <ModalForOption
          width="50%"
          height="30%"
          isNoButton
          isYesButton
          onClose={() => setOpenUploadModal(false)}
          onNo={() => setOpenUploadModal(false)}
          onYes={() => {}}>
          {/* <StyledModalText>{'삭제하시겠습니까?'}</StyledModalText> */}
        </ModalForOption>
      </PortalForOption>
    );
  };

  return (
    <StyledMainWrapper>
      <Header handler={setOpenUploadModal} />
      {selectedSubscription.subscriptionId === 0 && (
        <>
          <StyledTopWrapper>
            <StyledTopText>
              {userInfo.userName + '님께서 구독하고 계신 구독권입니다'}
            </StyledTopText>
          </StyledTopWrapper>
          <StyledBottomWrapper>
            {subscriptionInfoList ? (
              subscriptionInfoList.map(
                (item: ISubscriptionInfo, index: number) => {
                  return (
                    <StyledCarImgWrapper
                      key={index}
                      onClick={() => {
                        setSelectedSubscription(item);
                      }}>
                      <StyledCarImg
                        src={
                          process.env.PUBLIC_URL +
                          '/icons/subscription/subInfo_' +
                          String(item.month) +
                          '_' +
                          item.name.split('구독권')[1] +
                          '.png'
                        }
                      />
                    </StyledCarImgWrapper>
                  );
                },
              )
            ) : (
              <StyledTopWrapper>
                <text style={{ fontSize: '1rem', fontWeight: '700' }}>
                  현재 구독중인 서비스가 없습니다
                </text>
              </StyledTopWrapper>
            )}
          </StyledBottomWrapper>
        </>
      )}
      {selectedSubscription.subscriptionId !== 0 && (
        <SubscriptionDetailInfo
          item={selectedSubscription}
          setSelectedSubscription={setSelectedSubscription}
        />
      )}
      {openUploadModal && ModalContent()}
    </StyledMainWrapper>
  );
};

export default memo(SubscriptionInfo);
