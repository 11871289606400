import cryptoJs from 'crypto-js';

const cryptoFn = () => {
  const sha256FN = async (secretStr: string) => {
    const cipher = cryptoJs.SHA256(secretStr);
    return String(cipher);
  };

  return {
    sha256FN,
  };
};

export default cryptoFn;
