import styled from 'styled-components';

export const StyledMainWrapper = styled.div`
  padding-top: 10vh;
  width: 100%;
  height: 95%;
  background-color: ${(props) => props.theme.backGroundColor};
`;

export const StyledCategoryListWrapper = styled.div`
  width: 100%;
  height: 7%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`;

export const StyledCategoryBtn = styled.button<{ selected?: boolean }>`
  height: 80%;
  width: 18%;
  background-color: ${(props) =>
    props.selected ? '#5C1E37' : props.theme.backGroundColor};
  color: ${(props) => (props.selected ? 'white' : props.theme.fourthColor)};
  font-size: 0.8rem;
  font-weight: 700;
  border: none;
  border-radius: 14px;
  cursor: pointer;
  &:focus {
    border: none !important;
    outline: 2px solid ${({ theme }) => theme.mainColor};
  }
`;

export const StyledFAQListWrapper = styled.div`
  width: 100%;
  height: auto;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
`;

export const StyledFAQItemWrapper = styled.div`
  width: 100%;
  height: 10vh;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-top: 1px solid gray;
  background-color: white;
  cursor: pointer;
  &:focus {
    border: none !important;
    outline: 2px solid ${({ theme }) => theme.mainColor};
  }
`;

export const StyledTextWrapper = styled.div<{ answer?: boolean }>`
  width: 100%;
  height: 9vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  background-color: ${(props) => (props.answer ? '#BDBDBD' : 'white')};
`;

export const StyledMainText = styled.text`
  font-size: 1rem;
  font-weight: 700;
  color: ${(props) => props.theme.fourthColor};
`;

export const StyledSubText = styled.text<{ answer?: boolean }>`
  font-size: 0.8rem;
  font-weight: 400;
  color: ${(props) => (props.answer ? 'black' : 'gray')};
  white-space: pre-wrap;
  padding-left: 5%;
  line-height: 120%;
`;

export const StyledBtnWrapper = styled.div`
  width: 12%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledBtnImg = styled.img`
  width: 30%;
  object-fit: contain;
`;

export const StyledItemMainWrapper = styled.div`
  width: 100%;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
`;
