import React, {
  CSSProperties,
  memo,
  ReactNode,
  RefObject,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import {
  StyledModal,
  StyledModalBackground,
  StyledCodeInputBox,
  StyledCodeInputBoxWrapper,
  StyledButtonWrapper,
  StyledButton,
  StyledTextImgWrapper,
  StyledTextImg,
} from './styles';
import PortalForUse from './PortalForUse';
import { StyledSpace } from 'utils/styledSpace';
import useCoupon from 'hooks/useCoupon';

interface IModal {
  onClose: () => void;
  width?: string;
  height?: string;
  customStyle?: CSSProperties;
}

const ModalForUse = ({
  onClose,
  customStyle,
  width = '700px',
  height = '400px',
}: IModal) => {
  const { selectedCoupon, passwordCodeValue, checkPasswordCodeAndUseCoupon } =
    useCoupon();
  const modalRef: RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);

  const onClickOutSide = useCallback(
    (e: MouseEvent): void => {
      if (modalRef.current && !modalRef.current.contains(e.target as Node)) {
        onClose();
      }
    },
    [onClose],
  );

  useEffect(() => {
    document.addEventListener('mousedown', onClickOutSide);
    // 바깥영역클릭시 모달 종료
    return () => {
      document.removeEventListener('mousedown', onClickOutSide);
    };
  }, [onClickOutSide]);

  return (
    <PortalForUse>
      <StyledModalBackground>
        <StyledModal
          width={width}
          height={height}
          style={customStyle}
          ref={modalRef}>
          <StyledSpace height="5%" style={{ borderRadius: '16px' }} />
          <StyledTextImgWrapper>
            <StyledTextImg
              src={
                selectedCoupon.status === 'EXPIRED'
                  ? process.env.PUBLIC_URL + '/icons/coupon/modal2.png'
                  : process.env.PUBLIC_URL + '/icons/coupon/modal1.png'
              }
            />
          </StyledTextImgWrapper>

          <StyledSpace height="3%" style={{ borderRadius: '16px' }} />
          <StyledCodeInputBoxWrapper>
            <StyledCodeInputBox
              onChange={(e) => {
                passwordCodeValue.current = e.target.value;
              }}
              placeholder={'교환권 코드'}
            />
          </StyledCodeInputBoxWrapper>
          <StyledSpace height="2%" style={{ borderRadius: '16px' }} />
          <StyledButtonWrapper>
            <StyledButton
              onClick={checkPasswordCodeAndUseCoupon}
              expired={selectedCoupon.status === 'EXPIRED' ? true : false}>
              사용하기
            </StyledButton>
          </StyledButtonWrapper>
        </StyledModal>
      </StyledModalBackground>
    </PortalForUse>
  );
};

export default memo(ModalForUse);
