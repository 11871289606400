import React, { memo, useState, useEffect } from 'react';
import Header from 'components/Header';
import {
  StyledMainWrapper,
  StyledSommelierInfoImgWrapper,
  StyledSommelierInfoImg,
} from './styles';
import useBilling from 'hooks/useBilling';
import { StyledSpace } from 'utils/styledSpace';
import { ISubscriptionInfo } from 'types/Subscription.type';
import PortalForInfo from './ModalForInfo/PortalForInfo';
import ModalForInfo from './ModalForInfo';
import PortalForOption from 'components/common/ModalForOption/PortalForOption';
import ModalForOption from 'components/common/ModalForOption';
import { moveToTop } from 'utils/moveToTop';

const Billing = () => {
  const {
    openUploadModal,
    setOpenUploadModal,
    subscriptionInfoList,
    getSubscriptionList,
    setSelectedSubscriptionBilling,
    navigate,
    openUploadModalForSommelier,
    setOpenUploadModalForSommelier,
  } = useBilling();

  useEffect(() => {
    moveToTop();
    getSubscriptionList();
  }, []);

  const ModalContentForOption = () => {
    return (
      <PortalForOption>
        <ModalForOption
          width="50%"
          height="30%"
          isNoButton
          isYesButton
          onClose={() => setOpenUploadModal(false)}
          onNo={() => setOpenUploadModal(false)}
          onYes={() => {}}>
          {/* <StyledModalText>{'삭제하시겠습니까?'}</StyledModalText> */}
        </ModalForOption>
      </PortalForOption>
    );
  };

  const ModalContent = () => {
    return (
      <PortalForInfo>
        <ModalForInfo
          width="90%"
          height="50%"
          onClose={() => setOpenUploadModalForSommelier(false)}
        />
      </PortalForInfo>
    );
  };

  return (
    <StyledMainWrapper>
      <Header handler={setOpenUploadModal} />
      <StyledSpace height="3%" />
      <StyledSommelierInfoImgWrapper
        onClick={() => {
          setOpenUploadModalForSommelier(true);
        }}>
        <StyledSommelierInfoImg
          src={process.env.PUBLIC_URL + '/icons/sommelierInfo.png'}
        />
      </StyledSommelierInfoImgWrapper>
      <StyledSpace height="3%" />

      {subscriptionInfoList.map((item: ISubscriptionInfo, index: number) => {
        return (
          <StyledSommelierInfoImgWrapper
            onClick={() => {
              localStorage.setItem('subscription', JSON.stringify(item));
              setSelectedSubscriptionBilling(item);
              navigate('/billingdetail');
            }}>
            <StyledSommelierInfoImg
              src={
                process.env.PUBLIC_URL +
                '/icons/coupon' +
                item.name.split('구독권')[1] +
                '.png'
              }
            />
          </StyledSommelierInfoImgWrapper>
        );
      })}
      <StyledSpace height="5%" />

      {openUploadModalForSommelier && ModalContent()}
      {openUploadModal && ModalContentForOption()}
    </StyledMainWrapper>
  );
};

export default memo(Billing);
