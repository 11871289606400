import { useCallback, useState } from 'react';
import {
  handleGetYoutubeUrlList,
  handleGetPopUpIamge,
} from 'libs/apis/main.api';
import { useRecoilState } from 'recoil';
import { IYoutubeInfo } from 'types/Main.type';
import { youtubeUrlListAtom } from 'store/main';
import { useNavigate } from 'react-router-dom';
import { IGetPopUpImage } from 'types/Main.type';

const useMain = () => {
  const navigate = useNavigate();
  const [value, setValue] = useState(0);
  const [youtueUrlList, setYoutubeUrlList] =
    useRecoilState<IYoutubeInfo[]>(youtubeUrlListAtom);
  const [openUploadModal, setOpenUploadModal] = useState<boolean>(false);
  const [openUploadModalForNotice, setOpenUploadModalForNotice] =
    useState<boolean>(false);
  const [popUpImageUrl, setPopUpImageUrl] = useState<string>('');
  const [popUpImageConnectLinkUrl, setPopUpImageConnectLinkUrl] =
    useState<string>('');

  const getYoutubeUrlList = useCallback(async () => {
    try {
      const { data } = await handleGetYoutubeUrlList();
      let dataTemp: IYoutubeInfo[] = [];
      data.forEach((item: IYoutubeInfo) => {
        item.youtubeShortsUrl = item.youtubeShortsUrl.split(`/shorts/`)[1];
        dataTemp.push(item);
      });
      setYoutubeUrlList(dataTemp);
    } catch (e: any) {}
  }, []);

  const getNoticeValidation = useCallback(async () => {
    const data: IGetPopUpImage = await handleGetPopUpIamge();
    setPopUpImageUrl(data.image);
    setPopUpImageConnectLinkUrl(data.connectLink);
    if (data.enable === 'Y') {
      const checkOneDay = localStorage.getItem('oneDay');
      if (checkOneDay === null || checkOneDay === undefined) {
        setOpenUploadModalForNotice(true);
      } else {
        const currentDay = new Date().getDate();
        if (Number(currentDay) === Number(checkOneDay)) {
          return;
        } else {
          localStorage.removeItem('oneDay');
          setOpenUploadModalForNotice(true);
        }
      }
    } else if (data.enable === 'N') {
      localStorage.removeItem('oneDay');
    }
  }, []);

  const closeNoticeOneDay = () => {
    const currentDay = new Date().getDate();
    localStorage.setItem('oneDay', String(currentDay));
    setOpenUploadModalForNotice(false);
  };

  return {
    value,
    setValue,
    getYoutubeUrlList,
    youtueUrlList,
    openUploadModal,
    setOpenUploadModal,
    navigate,
    openUploadModalForNotice,
    setOpenUploadModalForNotice,
    closeNoticeOneDay,
    getNoticeValidation,
    popUpImageUrl,
    popUpImageConnectLinkUrl,
  };
};

export default useMain;
