import { atom } from 'recoil';
import { IModalItem } from 'types/Subscription.type';
import { ISubscriptionInfo } from 'types/Subscription.type';

export const subscriptionModalItem = atom<IModalItem>({
  key: 'subscriptionModalItem',
  default: {
    value: 0,
  },
});

export const subscriptionInfoListAtom = atom<ISubscriptionInfo[]>({
  key: 'subscriptionInfoListAtom',
  default: [],
});

export const subscriptionInfoAtom = atom<ISubscriptionInfo>({
  key: 'subscriptionInfoAtom',
  default: {
    subscriptionId: 0,
    name: '',
    description: '',
    price: 0,
    month: 0,
  },
});

export const selectSubscriptiontAtom = atom<string>({
  key: 'selectSubscriptiontAtom',
  default: '당월',
});
