import styled from 'styled-components';

export const StyledMainWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.backGroundColor};
`;

export const StyledMidWrapper = styled.div`
  margin-left: 2.5%;
  width: 95%;
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  flex-direction: column;
`;

export const StyledButtonWrapper = styled.div`
  width: 100%;
  height: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledButton = styled.button<{ adultCheck: boolean }>`
  width: 95%;
  height: 70%;
  background-color: ${(props) =>
    props.adultCheck ? props.theme.fourthColor : '#c4c4c4'};
  outline: 0;
  border: none;
  &:focus {
    border: none !important;
    outline: 2px solid ${({ theme }) => theme.mainColor};
  }
  color: white;
  font-size: 1.5rem;
  font-weight: 700;
`;

export const StyledMainTextImgWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledMainTextImg = styled.img`
  width: 100%;
  object-fit: fill;
`;

export const StyledAuthBtnImgWrapper = styled.div`
  width: 100%;
  height: 30%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledAuthBtnImg = styled.img`
  width: 50%;
  object-fit: fill;
`;

export const StyledProgressWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: gray;
  opacity: 0.5;
`;
