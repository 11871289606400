import React, {
  CSSProperties,
  memo,
  ReactNode,
  RefObject,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { StyledModal, StyledModalBackground } from './styles';
import PortalForInfo from './PortalForMainModal';
import MainModalType1 from './Type1';
import MainModalType2 from './Type2';

interface IModal {
  type?: number;
  onClose?: () => void;
  onYes?: () => void;
  onNo?: () => void;
  month?: number;
  later?: string;
}

const MainModal = ({ type, onClose, onYes, onNo, month, later }: IModal) => {
  const modalRef: RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);

  const onClickOutSide = useCallback(
    (e: MouseEvent): void => {
      if (modalRef.current && !modalRef.current.contains(e.target as Node)) {
        onClose!();
      }
    },
    [onClose],
  );

  useEffect(() => {
    document.addEventListener('mousedown', onClickOutSide);
    // 바깥영역클릭시 모달 종료
    return () => {
      document.removeEventListener('mousedown', onClickOutSide);
    };
  }, [onClickOutSide]);

  return (
    <PortalForInfo>
      <StyledModalBackground>
        <StyledModal ref={modalRef}>
          {type === 1 || type === 7 || type === 12 ? (
            <MainModalType1 onClose={onClose} type={type} />
          ) : (
            <MainModalType2
              later={later}
              onClose={onClose}
              onYes={onYes}
              type={type}
              onNo={onNo}
              month={month}
            />
          )}
        </StyledModal>
      </StyledModalBackground>
    </PortalForInfo>
  );
};

export default memo(MainModal);
