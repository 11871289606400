import {
  INameValue,
  IMbti,
  IMbtiResult,
  IMonthSubscriptionId,
} from 'types/Utils.type';

export const TestWineStep: string[] = ['1', '2', '3', '4', '5', '6', '7'];

export const WineSubscriptionText = [
  {
    key: 1,
    value: ['구독권 A : Double', '\n매 월 와인 두 병이 제공됩니다.'],
    imgSrc: '/wine2Img.png',
  },
  {
    key: 2,
    value: ['구독권 B : Single', '\n매 월 와인 한 병이 제공됩니다.'],
    imgSrc: '/wineImg.png',
  },
  {
    key: 3,
    value: ['구독권 C : Double', '\n매 월 와인 두 병이 제공됩니다.'],
    imgSrc: '/wine2Img.png',
  },
  {
    key: 4,
    value: ['구독권 D : Single', '\n매 월 와인 한 병이 제공됩니다.'],
    imgSrc: '/wineImg.png',
  },
  {
    key: 5,
    value: ['구독권 E : Single', '\n매 월 와인 두 병이 제공됩니다.'],
    imgSrc: '/wine2Img.png',
  },
];

export const OptionList: INameValue[] = [
  { name: 'HOME', value: '/main' },
  { name: '계정관리', value: '/mypage' },
  { name: '구독정보', value: '/subscriptioninfo' },
  { name: '결제정보', value: '/billinginfo' },
  { name: 'FAQ', value: '/faq' },
];

export const FAQCategoryList: INameValue[] = [
  { name: '전체', value: '1' },
  { name: '주문/결제', value: '2' },
  { name: '구독 와인', value: '3' },
  { name: '수령', value: '4' },
  { name: '중지/취소', value: '5' },
];

export const userRem: number = window.innerWidth / 390;

export const MbtiQuestionAndAnswer: IMbti[] = [
  {
    value: 1,
    type: 'tendency',
    question: '친구 여려명과 모이는 저녁 모임이 있다.\n어떤 와인을 사갈까?',
    A: '여려명이니까 저렴한거 여러병 사야지!\n넉넉한게 짱이야!',
    B: '꽤 값이 나가는 좋은 와인 한병이 좋겠어!',
  },
  {
    value: 2,
    type: 'tendency',
    question: '배달 어플을 켰다.\n메뉴는 골랐는데, 어느 가게에서 배달 시킬까?',
    A: '모험은 싫어. 늘 시키던 가게에서 시켜야지!',
    B: '지난번에 먹어본 가게 말고 새로운 가게 도전!',
  },
  {
    value: 3,
    type: 'tendency',
    question: '파인다이닝 레스토랑에 갔다.',
    A: '내가 마시고 싶은 와인 1병 골라서 시켜야지!',
    B: '소믈리에가 코스마다 맞춘 와인페어링 마셔봐야지!',
  },
  {
    value: 4,
    type: 'kind',
    question: '두 가지 음식 중 하나를 골라야한다면?',
    A: '슴슴하지만 계속 구미가 당기는 시원하고 맑은 대구탕',
    B: '매콤하고 자극적인 진한 맛의 빨간 대구탕',
  },
  {
    value: 5,
    type: 'kind',
    question: '두 가지 음식 중 하나를 골라야한다면?',
    A: '산뜻한 라임 맛의 스파클링 음료',
    B: '부드러운 목넘김의 딸기 스무디',
  },
  {
    value: 6,
    type: 'kind',
    question: '두 가지 음식 중 하나를 골라야한다면?',
    A: '산미가 도드라지는 코스타리카 원두',
    B: '묵직한 풍미가 도드라지는 과테말라 원두',
  },
  {
    value: 7,
    type: 'sweet',
    question: '두 가지 디저트 중 하나를 골라야한다면?',
    A: '마무리를 상큼하게 할 수 있는 레몬 소르베',
    B: '마무리를 달콤하게 할 수 있는 초콜렛 타르트',
  },
];

export const MbtiResult: IMbtiResult[] = [
  {
    value: 1,
    type: 'A',
    text: '발전된 와린이형\n와린이보다 한발짝 업그레이드 된 당신,\n다양한 와인을 경험해보고 싶지만 본인의 취향을 잘 모르겠는 당신께\n구독권A를 추천드립니다.',
    subscription: '구독권A',
    description: '매월 50,000원 결제\n와인 두 병 제공',
  },
  {
    value: 2,
    type: 'B',
    text: '곧 죽어도 분위기형\n와인을 마시는 이유는 분위기를 내기 위함인 당신,\n홈술에도 좋고 홈파티에 가져가기도 좋을\n구독권B를 추천드립니다.',
    subscription: '구독권B',
    description: '매월 50,000원 결제\n와인 한 병 제공',
  },
  {
    value: 3,
    type: 'C',
    text: '찐 애주가형\n‘술’이란 풍족할수록 좋은 것! 적어도 알코올 섭취 느낌은 있어야하는 것!\n찐 애주가형인 당신께는 다른 주종과는 다른 ‘와인’의 매력을 느낄 수 있는\n구독권B를 추천드립니다.',
    subscription: '구독권B',
    description: '매월 50,000원 결제\n와인 한 병 제공',
  },
  {
    value: 4,
    type: 'D',
    text: '전형적 와린이형\n와인하면 ‘레드와인’만 떠오르는 당신,\n어떤 와인을 어떻게 마셔야할지 모르는 와린이께 좋은 시작점이 될 수 있는\n구독권A를 추천드립니다.',
    subscription: '구독권A',
    description: '매월 50,000원 결제\n와인 두 병 제공',
  },
  {
    value: 5,
    type: 'E',
    text: '반믈리에형\n절반은 소믈리에라고 칭할만큼 와인에 경험이 많을 것 같은 당신,\n좋은 퀄리티의 와인들로 만족시켜줄 수 있는\n구독권C를 추천드립니다.',
    subscription: '구독권E',
    description: '매월 200,000원 결제\n와인 한 병 제공',
  },
  {
    value: 6,
    type: 'F',
    text: '고급 입맛형\n한번 마실 때, 이왕이면 맛있게 마셔야한다는 주의의 당신,\n산뜻하고 섬세한 입맛에 찰떡궁합인\n구독권D를 추천드립니다.',
    subscription: '구독권C',
    description: '매월 100,000원 결제\n와인 두 병 제공',
  },
  {
    value: 7,
    type: 'G',
    text: '잠재적 와인 덕후형\n와인 덕후가 될 잠재력을 다분히 지닌 당신,\n본인의 입맛에 따라 다양한 와인을 선택할 수 있는\n구독권E를 추천드립니다.',
    subscription: '구독권D',
    description: '매월 100,000원 결제\n와인 한 병 제공',
  },
  {
    value: 8,
    type: 'H',
    text: '정체성을 찾고 있어형\n와인에 관심과 애정은 있지만 뭘 마셔야할지 모르겠는 당신,\n본인의 입맛에 따라 다양한 와인을 선택할 수 있는\n구독권E를 추천드립니다.',
    subscription: '구독권D',
    description: '매월 100,000원 결제\n와인 한 병 제공',
  },
];

export const MonthSubscriptionId: IMonthSubscriptionId[] = [
  {
    value: 2,
    ids: [
      7, 12, 17, 22, 27, 32, 37, 42, 47, 52, 57, 62, 67, 72, 77, 82, 87, 92, 97,
    ],
  },
  {
    value: 3,
    ids: [
      8, 13, 18, 23, 28, 33, 38, 43, 48, 53, 58, 63, 68, 73, 78, 83, 88, 93, 98,
    ],
  },
  {
    value: 4,
    ids: [
      9, 14, 19, 24, 29, 34, 39, 44, 49, 54, 59, 64, 69, 74, 79, 84, 89, 94, 99,
    ],
  },
  {
    value: 0,
    ids: [
      10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 60, 75, 80, 85, 90, 95,
      100,
    ],
  },
  {
    value: 1,
    ids: [
      11, 16, 21, 26, 31, 36, 41, 46, 51, 56, 61, 66, 71, 76, 81, 86, 91, 96,
      101,
    ],
  },
];
