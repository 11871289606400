import { useCallback, useState, useRef } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  ISubscriptionInfo,
  IPaySubscriptionRequest,
  ICreateBillingKeyRequest,
  ISubscriptionForPay,
} from 'types/Subscription.type';
import {
  handleGetSubscriptionList,
  handlePaySubscription,
  handleCreateBillingKey,
  handlePay,
} from 'libs/apis/subscription.api';
import {
  subscriptionInfoListAtom,
  subscriptionInfoAtom,
  selectSubscriptiontAtom,
} from 'store/subscription';
import { userInfoAtom } from 'store/user';
import { useNavigate } from 'react-router-dom';
import { handleGetBillingList } from 'libs/apis/billing.api';
import { IBillingInfo } from 'types/Billing.type';
import { IUserInfo } from 'types/User.type';
import { handleGetLoginUserInfo } from 'libs/apis/user.api';
import { MonthSubscriptionId } from 'constants/constants';

const useBilling = () => {
  const navigate = useNavigate();
  const [openUploadMainModal, setOpenUploadMainModal] =
    useState<boolean>(false);
  const [openUploadModal, setOpenUploadModal] = useState<boolean>(false);
  const [subscriptionInfoList, setSubscriptionInfoList] = useRecoilState<
    ISubscriptionInfo[]
  >(subscriptionInfoListAtom);
  const [selectedSubscription, setSelectedSubscription] =
    useRecoilState<string>(selectSubscriptiontAtom);
  const userInfo = useRecoilValue(userInfoAtom);
  const [selectedSubscriptionBilling, setSelectedSubscriptionBilling] =
    useRecoilState<ISubscriptionInfo>(subscriptionInfoAtom);
  const [checkPayValidation, setCheckPayValidation] = useState<boolean>(false);
  const savedUserInfo = JSON.parse(String(localStorage.getItem('userInfo')));
  const savedSubscription = JSON.parse(
    String(localStorage.getItem('subscription')),
  );
  const [openUploadModalForSommelier, setOpenUploadModalForSommelier] =
    useState<boolean>(false);
  const [loadProgress, setLoadProgress] = useState<boolean>(false);
  const [billingInfoList, setBillingInfoList] = useState<IBillingInfo[]>();
  const [modalType, setModalType] = useState<number>(0);
  const billingValidation = useRef<boolean>(false);
  const initModalType = useRef<number>(0);
  const succesValidation = useRef<boolean>(false);
  const [currentPageType, setCurrentPageType] = useState<boolean>(false);

  const getSubscriptionList = useCallback(async () => {
    try {
      const currentDate = new Date();
      const data: ISubscriptionInfo[] = await handleGetSubscriptionList(
        Number(currentDate.getMonth() + 1),
      );
      setSubscriptionInfoList(data);
    } catch (e: any) {}
  }, []);

  const paySubscription = useCallback(async () => {
    const kindOfSubscription = String(localStorage.getItem('kind'));
    const monthIndex: number = new Date().getMonth() + 1;
    const currentSubscriptionMonthId = savedSubscription.subscriptionId;
    const currentSubscriptionIdTemp = currentSubscriptionMonthId % 5;
    const currentSubscriptionId = MonthSubscriptionId.find(
      (e) => e.value === currentSubscriptionIdTemp,
    )!.ids[monthIndex + 7];

    const req: ISubscriptionForPay = {
      userId: savedUserInfo.userId,
      subscriptionId: currentSubscriptionId,
    };
    try {
      billingValidation.current = true;
      succesValidation.current = true;
      setOpenUploadMainModal(false);
      const data = await handlePay(
        req,
        kindOfSubscription === '당월' ? 'immediate' : 'later',
      );
      if (data.message) {
        setOpenUploadMainModal(true);
        setModalType(1);
        return;
      }
      const temp =
        savedSubscription.subscriptionId +
        (kindOfSubscription === '당월' ? 0 : 5);
      const newUserInfo = {
        ...savedUserInfo,
        subscriptionIdList: savedUserInfo.subscriptionIdList + temp + '#',
        cardStatus: 'Y',
      };
      localStorage.setItem('userInfo', JSON.stringify(newUserInfo));
      setCheckPayValidation(true);
      setLoadProgress(true);
    } catch (e: any) {
      console.log(e);
    }
  }, [loadProgress, setLoadProgress]);

  const createBillingKey = useCallback(async (authKey: string) => {
    try {
      const req: ICreateBillingKeyRequest = {
        userId: savedUserInfo.userId,
        authKey: authKey,
      };
      await handleCreateBillingKey(req);
      const userDataTemp = {
        userId: savedUserInfo.userId,
        userName: savedUserInfo.userName,
        email: savedUserInfo.email,
        recommendationId: savedUserInfo.recommendationId,
        subscriptionIdList: savedUserInfo.subscriptionIdList,
        isAdult: savedUserInfo.isAdult,
        customerKey: savedUserInfo.customerKey,
        cardStatus: 'Y',
      };
      localStorage.setItem('userInfo', JSON.stringify(userDataTemp));
    } catch (e: any) {}
  }, []);

  const selectKindOfSubscription = (value: string) => {
    setSelectedSubscription(value);
    localStorage.setItem('kind', value);
  };

  const checkSubscriptioinStatus = () => {
    const alreadySubscription = savedUserInfo.subscriptionIdList.split('#');
    const selectedSubscriptionId = String(savedSubscription.subscriptionId);
    let flagFrist = false;
    if (alreadySubscription.lenth === 1) {
      return false;
    }
    alreadySubscription.forEach((item: String) => {
      if (item === '' || item === null || item === undefined) {
        return;
      }
      if (
        item === selectedSubscriptionId ||
        Number(item) % 5 === Number(selectedSubscriptionId) % 5
      ) {
        flagFrist = true;
      }
    });
    if (flagFrist) {
      return true;
    }

    return false;
  };

  const checkCardStatusForBilling = () => {
    const savedUserInfo = JSON.parse(String(localStorage.getItem('userInfo')));
    if (checkSubscriptioinStatus()) {
      //이미 구독중
      return 1;
    } else {
      if (savedUserInfo.cardStatus === 'Y') {
        // 카드등록됨
        return 2;
      } else {
        // 카드등록안됨
        return 5;
      }
    }
  };

  const getBillingInfo = useCallback(async () => {
    try {
      const data = await handleGetBillingList(savedUserInfo.userId);
      setBillingInfoList(data);
    } catch (e: any) {}
  }, [billingInfoList, setBillingInfoList]);

  const syncUserInfo = async () => {
    const userInfo = JSON.parse(String(localStorage.getItem('userInfo')));
    try {
      const userData: IUserInfo = (
        await handleGetLoginUserInfo(Number(userInfo.userId))
      ).data;
      const userDataTemp = {
        userId: userInfo.userId,
        userName: userData.userName,
        email: userData.email,
        recommendationId: userData.recommendationId,
        subscriptionIdList: userData.subscriptionIdList,
        isAdult: userData.isAdult,
        customerKey: userData.customerKey,
        cardStatus: userData.cardStatus,
      };
      localStorage.setItem('userInfo', JSON.stringify(userDataTemp));
      const initType = checkCardStatusForBilling();
      initModalType.current = initType;
      setModalType(initType);
    } catch (e: any) {}
  };

  return {
    openUploadModal,
    setOpenUploadModal,
    subscriptionInfoList,
    selectedSubscription,
    setSelectedSubscription,
    getSubscriptionList,
    userInfo,
    selectedSubscriptionBilling,
    navigate,
    setSelectedSubscriptionBilling,
    paySubscription,
    checkPayValidation,
    createBillingKey,
    savedUserInfo,
    savedSubscription,
    openUploadModalForSommelier,
    setOpenUploadModalForSommelier,
    selectKindOfSubscription,
    openUploadMainModal,
    setOpenUploadMainModal,
    checkCardStatusForBilling,
    loadProgress,
    setLoadProgress,
    getBillingInfo,
    billingInfoList,
    modalType,
    setModalType,
    billingValidation,
    initModalType,
    succesValidation,
    currentPageType,
    setCurrentPageType,
    syncUserInfo,
  };
};

export default useBilling;
