import styled from 'styled-components';

export const StyledModalBackground = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  text-align: center;
  position: fixed;
  bottom: 0;
  background-color: ${({ theme }) => theme.black}95;
  z-index: 99999;
`;

export const StyledModal = styled.div<{
  width: string;
  height: string;
}>`
  position: relative;
  width: ${(props) => props.width};
  max-height: ${(props) => props.height};
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.backGroundColor};
  justify-content: flex-start;
  align-items: center;
  overflow-y: scroll;
`;

export const StyledTitleWrapper = styled.div`
  margin-top: 5%;
  width: 100%;
  height: 5%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledTitle = styled.text`
  font-size: 1.25rem;
  font-weight: 900;
  color: black;
  white-space: pre-wrap;
`;

export const StyledText = styled.text<{
  size: string;
  weight: number;
  color: string;
}>`
  font-size: ${(props) => props.size};
  font-weight: ${(props) => props.weight};
  color: ${(props) => props.color};
  white-space: pre-wrap;
  line-height: 120%;
  margin: 5%;
`;

export const StyledBottomWrapper = styled.div`
  width: 90%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 5%;
  background-color: white;
  border-radius: 8px;
  border: 1px solid #797979;
  flex-direction: column;
`;

export const StyledBtnWrapper = styled.div`
  width: 90%;
  height: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5%;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 10%;
`;

export const StyledBtnImg = styled.img`
  width: 100%;
  object-fit: fill;
`;

export const StyledCarImgWrapper = styled.div`
  margin-top: 5%;
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledCarImg = styled.img`
  width: 100%;
  object-fit: fill;
`;

export const StyledTextImgWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 5%;
  margin-bottom: 5%;
  position: relative;
`;

export const StyledTextImg = styled.img`
  margin-left: 5%;
  margin-right: 5%;
  width: 90%;
  object-fit: fill;
`;

export const StyledTextNameWrapper = styled.div`
  width: 23%;
  height: 25%;
  position: absolute;
  margin-left: 5%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  /* background-color: yellow; */
`;
