const convertFN = () => {
  const convertMoneyString = (moneyString: string) => {
    return moneyString.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const getCurrentDateWithKorean = (type: string) => {
    const now = new Date();
    const utc = now.getTime() + now.getTimezoneOffset() * 60000;
    const kstOffset = 9 * 60;
    const kst = new Date(utc + kstOffset * 60000);
    const currentData = kst.toISOString().split('-');
    return (
      currentData[0] +
      '년 ' +
      (type === '당월' ? currentData[1] : setMonth(currentData[1])) +
      '월 ' +
      (type === '당월' ? currentData[2].split('T')[0] : '01') +
      '일'
    );
  };

  const setMonth = (text: string) => {
    if (text === '09') {
      return '10';
    } else {
      const str = text;
      let arr = str.split('');
      arr[1] = String(Number(arr[1]) + 1);
      const newStr = arr.join('');
      return newStr;
    }
  };

  return {
    convertMoneyString,
    getCurrentDateWithKorean,
  };
};

export default convertFN;
