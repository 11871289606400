import React, { memo, useState, useEffect } from 'react';
import Header from 'components/Header';
import {
  StyledMainWrapper,
  StyledSmallBoxWrapper,
  StyledBoxWrapper,
  StyledBtnWrapper,
  StyledBtn,
  StyledSmallBoxMainTextWrapper,
  StyledSmallBoxSubTextWrapper,
  StyledSmallBoxMainText,
  StyledSmallBoxSubText,
  StyledBoxMainTextWrapper,
  StyledBoxSubTextWrapper,
  StyledBoxSubText,
  StyledBoxSubTextRightWrapper,
  StyledBoxSubTextItemWrapper,
  StyledInfoImgWrapper,
  StyledInfoImg,
  StyledProgressWrapper,
} from './styles';
import useBilling from 'hooks/useBilling';
import PortalForOption from 'components/common/ModalForOption/PortalForOption';
import ModalForOption from 'components/common/ModalForOption';
import { StyledSpace } from 'utils/styledSpace';
import BillingCard from './BillingCard';
import customTossPayments from 'libs/CustomTossPayment';
import MainModal from 'components/MainModal';
import PortalForMainModal from 'components/MainModal/PortalForMainModal';
import CircularProgress from '@mui/material/CircularProgress';
import { moveToTop } from 'utils/moveToTop';

const BillingDetail = () => {
  const {
    openUploadModal,
    setOpenUploadModal,
    userInfo,
    openUploadMainModal,
    setOpenUploadMainModal,
    checkCardStatusForBilling,
    modalType,
    setModalType,
    paySubscription,
    navigate,
    setLoadProgress,
    loadProgress,
    billingValidation,
    initModalType,
  } = useBilling();
  const { loadTossPaymentsPayView } = customTossPayments();

  useEffect(() => {
    moveToTop();
    const initType = checkCardStatusForBilling();
    initModalType.current = initType;
    setModalType(initType);
  }, []);

  useEffect(() => {
    if (billingValidation.current) {
      setTimeout(() => {
        setLoadProgress(false);
        navigate('/billingsuccess');
      }, 3000);
    }
  }, [loadProgress]);

  const ModalContent = () => {
    return (
      <PortalForOption>
        <ModalForOption
          width="50%"
          height="30%"
          isNoButton
          isYesButton
          onClose={() => setOpenUploadModal(false)}
          onNo={() => setOpenUploadModal(false)}
          onYes={() => {}}>
          {/* <StyledModalText>{'삭제하시겠습니까?'}</StyledModalText> */}
        </ModalForOption>
      </PortalForOption>
    );
  };

  const GetStyledBillingInfo = (title: string, value: string) => {
    return (
      <StyledSmallBoxWrapper>
        <StyledSpace width="5%" backGroundColor="white" />
        <StyledSmallBoxMainTextWrapper>
          <StyledSmallBoxMainText>{title}</StyledSmallBoxMainText>
        </StyledSmallBoxMainTextWrapper>
        <StyledSmallBoxSubTextWrapper>
          <StyledSmallBoxSubText>{value}</StyledSmallBoxSubText>
        </StyledSmallBoxSubTextWrapper>
        <StyledSpace width="5%" backGroundColor="white" />
      </StyledSmallBoxWrapper>
    );
  };

  const MainModalContent = () => {
    const savedSubscription = JSON.parse(
      String(localStorage.getItem('subscription')),
    );
    const kindOfSubscription = String(localStorage.getItem('kind'));
    return (
      <PortalForMainModal>
        <MainModal
          later={kindOfSubscription}
          month={savedSubscription.month}
          type={modalType}
          onClose={() => {
            setModalType(initModalType.current);
            setOpenUploadMainModal(false);
          }}
          onYes={() => {
            if (modalType === 2) {
              setModalType(4);
            } else if (modalType === 4) {
              paySubscription();
            } else if (modalType === 3) {
              loadTossPaymentsPayView('billing');
            } else if (modalType === 5) {
              loadTossPaymentsPayView('billing');
            }
          }}
          onNo={() => {
            if (modalType === 2) {
              setModalType(3);
            } else if (modalType === 4) {
              setModalType(initModalType.current);
              setOpenUploadMainModal(false);
            } else if (modalType === 3) {
              setModalType(initModalType.current);
              setOpenUploadMainModal(false);
            } else if (modalType === 5) {
              setModalType(initModalType.current);
              setOpenUploadMainModal(false);
            }
          }}
        />
      </PortalForMainModal>
    );
  };

  return (
    <StyledMainWrapper>
      <Header handler={setOpenUploadModal} />
      {loadProgress && (
        <StyledProgressWrapper>
          <CircularProgress size={100} thickness={10} />
        </StyledProgressWrapper>
      )}

      <StyledSpace height="5%" />
      {GetStyledBillingInfo('주문자', userInfo.userName)}

      <StyledSpace height="2%" />
      {GetStyledBillingInfo('아이디', userInfo.email)}

      <StyledSpace height="2%" />
      <BillingCard />

      <StyledSpace height="3%" />
      <StyledInfoImgWrapper>
        <StyledInfoImg
          src={process.env.PUBLIC_URL + '/icons/subscriptionInfo.png'}
        />
      </StyledInfoImgWrapper>

      <StyledSpace height="1%" />
      <StyledBtnWrapper>
        <StyledBtn
          onClick={() => {
            setOpenUploadMainModal(true);
          }}>
          결제하기
        </StyledBtn>
      </StyledBtnWrapper>
      {openUploadModal && ModalContent()}
      {openUploadMainModal && MainModalContent()}
    </StyledMainWrapper>
  );
};

export default memo(BillingDetail);
