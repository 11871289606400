import customAxios from 'libs/CustomAxios';
import {
  ISubscriptionInfo,
  IPaySubscriptionRequest,
  ICreateBillingKeyRequest,
  ISubscriptionForPay,
} from 'types/Subscription.type';

export const handleGetSubscriptionList = async (
  month: number,
): Promise<ISubscriptionInfo[]> => {
  const url: string = `/subscription/findbymonth?month=${month}`;
  const { data } = await customAxios.get(url);
  return data.data;
};

export const handlePaySubscription = async (
  req: IPaySubscriptionRequest,
): Promise<boolean> => {
  const url: string = `/tossPayment/pay`;
  const { data } = await customAxios.post(url, req);
  return data;
};

export const handleCreateBillingKey = async (
  req: ICreateBillingKeyRequest,
): Promise<boolean> => {
  const url: string = `/tossPayment/card`;
  const { data } = await customAxios.post(url, req);
  return data;
};

export const handleGetSubscriptionListByUserId = async (
  userId: number,
): Promise<ISubscriptionInfo[]> => {
  const url: string = `/subscription/user?userId=${userId}`;
  const { data } = await customAxios.get(url);
  return data.data;
};

export const handlePay = async (
  req: ISubscriptionForPay,
  type: string,
): Promise<any> => {
  const url: string = `/subscription/subscribe/` + type;
  const { data } = await customAxios.post(url, req);
  return data.data;
};

export const handleGetSubscriptionByMonthAndName = async (
  month: number,
  name: string,
): Promise<ISubscriptionInfo> => {
  const url: string = `/subscription/findByMonthAndName?month=${month}&name=${name}`;
  const { data } = await customAxios.get(url);
  return data.data;
};

export const handleUnsubscribe = async (
  userId: number,
  subscriptionId: number,
): Promise<void> => {
  const req = {
    userId: userId,
    subscriptionId: subscriptionId,
  };
  const url: string = `/subscription/unsubscribe`;
  const { data } = await customAxios.post(url, req);
  return data;
};
