import React, {
  CSSProperties,
  memo,
  RefObject,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import {
  StyledModal,
  StyledModalBackground,
  StyledImgWrapper,
  StyledImg,
  StyledCloseBtnWrapper,
  StyledCloseBtn,
} from './styles';
import PortalForInfo from './PortalForInfo';

interface IModal {
  onClose: () => void;
  width?: string;
  height?: string;
  customStyle?: CSSProperties;
}

const ModalForCoupon = ({ onClose, customStyle, width, height }: IModal) => {
  const modalRef: RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);

  const onClickOutSide = useCallback(
    (e: MouseEvent): void => {
      if (modalRef.current && !modalRef.current.contains(e.target as Node)) {
        onClose();
      }
    },
    [onClose],
  );

  useEffect(() => {
    document.addEventListener('mousedown', onClickOutSide);
    // 바깥영역클릭시 모달 종료
    return () => {
      document.removeEventListener('mousedown', onClickOutSide);
    };
  }, [onClickOutSide]);

  return (
    <PortalForInfo>
      <StyledModalBackground>
        <StyledModal style={customStyle} ref={modalRef}>
          <StyledCloseBtnWrapper>
            <StyledCloseBtn onClick={onClose} />
          </StyledCloseBtnWrapper>
          <StyledImgWrapper>
            <StyledImg
              src={process.env.PUBLIC_URL + '/icons/sommelierText.png'}
            />
          </StyledImgWrapper>
        </StyledModal>
      </StyledModalBackground>
    </PortalForInfo>
  );
};

export default memo(ModalForCoupon);
