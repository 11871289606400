import styled from 'styled-components';

export const StyledSmallBoxMainText = styled.text`
  font-size: 1.5rem;
  font-weight: 700;
`;

export const StyledBigBoxWrapper = styled.div`
  width: 100%;
  background-color: white;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const StyledBigBoxMainTextWrapper = styled.div`
  width: 100%;
  height: 20%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid gray;
`;

export const StyledRadioBtn = styled.div`
  height: 50%;
  aspect-ratio: 1/1;
  border-radius: 100%;
  border: 1px solid ${(props) => props.theme.fourthColor};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledSelectedRadioBtn = styled.div`
  width: 80%;
  aspect-ratio: 1/1;
  border-radius: 100%;
  background-color: ${(props) => props.theme.fourthColor};
`;

export const StyledSelectMainText = styled.text`
  font-size: 1rem;
  color: ${(props) => props.theme.fourthColor};
  font-weight: 700;
`;

export const StyledSelectSubText = styled.text`
  font-size: 0.8rem;
  color: gray;
  font-weight: 700;
`;

export const StyledBigBoxAmountWrapper = styled.div`
  width: 100%;
  height: 20%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-top: 1px solid gray;
`;

export const StyledAmtWrapper = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledAmtText = styled.text`
  font-size: 1.25rem;
  color: red;
  font-weight: 900;
`;

export const StyledWineCardWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid gray;
`;

export const StyledWineCard = styled.img`
  width: 100%;
  object-fit: fill;
`;

export const StyledOptionWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid gray;
`;

export const StyledOptionTextImg = styled.img`
  object-fit: fill;
  width: 30%;
`;

export const StyledOptionSelectWrapper = styled.div`
  width: 70%;
  height: 100%;
  flex-direction: column;
`;

export const StyledoptionSelectTopWrapper = styled.div`
  height: 50%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledoptionSelectBottomWrapper = styled.div`
  height: 50%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledSelectImg = styled.img`
  width: 100%;
  object-fit: fill;
`;

export const StyledAmountWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid gray;
`;

export const StyledAmountTextImg = styled.img`
  width: 50%;
  height: 100%;
  object-fit: fill;
`;

export const StyledMainTextWrapper = styled.div`
  width: 100%;
`;

export const StyledMainTextImg = styled.img`
  width: 100%;
  object-fit: fill;
`;
