import React, { memo, useState, useEffect } from 'react';
import Header from 'components/Header';
import {
  StyledMainWrapper,
  StyledBtnWrapper,
  StyledSuccessTopButtonWrapper,
  StyledSuccessBottomButton,
  StyledSuccessBottomText,
  StyledSuccessTopButton,
  StyledSuccesTopText,
  StyledMainImgWrapper,
  StyledMainImg,
  StyledProgressWrapper,
  StyledSuccessBottomButtonImg,
} from './styles';
import { StyledSpace } from 'utils/styledSpace';
import useQueryString from 'hooks/useQueryString';
import useBilling from 'hooks/useBilling';
import PortalForOption from 'components/common/ModalForOption/PortalForOption';
import ModalForOption from 'components/common/ModalForOption';
import CircularProgress from '@mui/material/CircularProgress';
import MainModal from 'components/MainModal';
import PortalForMainModal from 'components/MainModal/PortalForMainModal';
import customTossPayments from 'libs/CustomTossPayment';
import useHome from 'hooks/useHome';

const Success = () => {
  const { status, authKey, type } = useQueryString();
  const {
    navigate,
    createBillingKey,
    openUploadModal,
    setOpenUploadModal,
    paySubscription,
    loadProgress,
    setLoadProgress,
    succesValidation,
    currentPageType,
    setCurrentPageType,
    initModalType,
    modalType,
    setModalType,
    openUploadMainModal,
    setOpenUploadMainModal,
    checkCardStatusForBilling,
    syncUserInfo,
  } = useBilling();
  const { loadTossPaymentsPayView } = customTossPayments();

  useEffect(() => {
    syncUserInfo();
    if (status === 'success') {
      createBillingKey(String(authKey));
    }

    if (type === 'mypage') {
      setCurrentPageType(true);
    }

    // const initType = checkCardStatusForBilling();
    // initModalType.current = initType;
    // setModalType(initType);
  }, []);

  useEffect(() => {
    if (succesValidation.current) {
      setTimeout(() => {
        setLoadProgress(false);
        navigate('/billingsuccess');
      }, 3000);
    }
  }, [loadProgress]);

  const ModalContent = () => {
    return (
      <PortalForOption>
        <ModalForOption
          width="50%"
          height="30%"
          isNoButton
          isYesButton
          onClose={() => setOpenUploadModal(false)}
          onNo={() => setOpenUploadModal(false)}
          onYes={() => {}}>
          {/* <StyledModalText>{'삭제하시겠습니까?'}</StyledModalText> */}
        </ModalForOption>
      </PortalForOption>
    );
  };

  const MainModalContent = () => {
    const savedSubscription = JSON.parse(
      String(localStorage.getItem('subscription')),
    );
    const kindOfSubscription = String(localStorage.getItem('kind'));
    const savedUserInfo = JSON.parse(String(localStorage.getItem('userInfo')));
    return (
      <PortalForMainModal>
        <MainModal
          later={kindOfSubscription}
          month={savedSubscription.month}
          type={
            modalType === 5
              ? savedUserInfo.cardStatus === 'Y'
                ? 2
                : 5
              : modalType
          }
          onClose={() => {
            setModalType(initModalType.current);
            setOpenUploadMainModal(false);
          }}
          onYes={() => {
            if (modalType === 2) {
              setModalType(4);
            } else if (modalType === 4) {
              paySubscription();
            } else if (modalType === 3) {
              loadTossPaymentsPayView('billing');
            } else if (modalType === 5) {
              if (savedUserInfo.cardStatus === 'Y') {
                setModalType(4);
              } else {
                loadTossPaymentsPayView('billing');
              }
            }
          }}
          onNo={() => {
            if (modalType === 2) {
              setModalType(3);
            } else if (modalType === 4) {
              setModalType(initModalType.current);
              setOpenUploadMainModal(false);
            } else if (modalType === 3) {
              setModalType(initModalType.current);
              setOpenUploadMainModal(false);
            } else if (modalType === 5) {
              if (savedUserInfo.cardStatus === 'Y') {
                setModalType(3);
              } else {
                setModalType(initModalType.current);
                setOpenUploadMainModal(false);
              }
            }
          }}
        />
      </PortalForMainModal>
    );
  };

  return (
    <StyledMainWrapper>
      <Header handler={setOpenUploadModal} />
      <StyledMainImgWrapper>
        <StyledMainImg
          src={process.env.PUBLIC_URL + '/icons/cardSuccess.png'}
        />
      </StyledMainImgWrapper>

      <StyledSpace height="3vh" />
      {!currentPageType && (
        <StyledBtnWrapper>
          <StyledSuccessTopButtonWrapper>
            <StyledSuccessTopButton
              onClick={() => {
                navigate('/mypage');
              }}>
              <StyledSuccesTopText>계정 관리</StyledSuccesTopText>
            </StyledSuccessTopButton>
            <StyledSuccessTopButton
              onClick={() => {
                navigate('/main');
              }}>
              <StyledSuccesTopText>메인 화면</StyledSuccesTopText>
            </StyledSuccessTopButton>
          </StyledSuccessTopButtonWrapper>
          <StyledSpace height="20%" />
          <StyledSuccessBottomButton
            onClick={() => {
              setOpenUploadMainModal(true);
            }}>
            <StyledSuccessBottomText>결제 하기</StyledSuccessBottomText>
          </StyledSuccessBottomButton>
        </StyledBtnWrapper>
      )}
      {currentPageType && (
        <StyledBtnWrapper>
          <StyledSuccessBottomButtonImg
            onClick={() => {
              navigate('/mypage');
            }}
            src={process.env.PUBLIC_URL + '/icons/BTN/account.png'}
          />
          <StyledSpace height="20%" />
          <StyledSuccessBottomButtonImg
            onClick={() => {
              navigate('/main');
            }}
            src={process.env.PUBLIC_URL + '/icons/BTN/main.png'}
          />
        </StyledBtnWrapper>
      )}

      {openUploadModal && ModalContent()}
      {openUploadMainModal && MainModalContent()}
      {loadProgress && (
        <StyledProgressWrapper>
          <CircularProgress size={100} thickness={10} />
        </StyledProgressWrapper>
      )}
    </StyledMainWrapper>
  );
};

export default memo(Success);
