import styled from 'styled-components';

export const StyledSignUpWrapper = styled.div`
  width: 100%;
  height: 100vh;
  background-color: ${(props) => props.theme.backGroundColor};
`;

export const StyledHeaderWrapper = styled.div`
  width: 100%;
  height: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
`;

export const StyledHeaderTextWrapper = styled.div`
  width: 40%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledHeaderText = styled.text`
  font-size: 1.5rem;
  font-weight: 700;
`;

export const StyledBackButton = styled.button`
  width: 30%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: 0;
  border: none;
  background-color: ${(props) => props.theme.backGroundColor};
  &:focus {
    border: none !important;
    outline: 2px solid ${({ theme }) => theme.mainColor};
  }
`;

export const StyledButtonText = styled.text`
  font-size: 1rem;
  font-weight: 400;
  color: #b689b0;
`;

export const StyledItemWrapper = styled.div`
  height: 45vh;
  width: 100%;
  background-color: ${(props) => props.theme.backGroundColor};
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
`;

export const StyledPlaceHoldWrapper = styled.div`
  height: 15%;
  width: 90%;
  background-color: #cfcfcf;
  padding-left: 5%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const StyledPassWordInputBox = styled.input<{ selected?: boolean }>`
  height: 15%;
  width: 90%;
  border: none;
  padding-left: 5%;
  background-color: ${(props) => (props.selected ? '#938D8D' : 'white')};
  color: ${(props) => (props.selected ? '#DBD5D5' : 'black')};
`;

export const StyledButtonWrapper = styled.div`
  width: 100%;
  height: 15vh;
  background-color: ${(props) => props.theme.backGroundColor};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const StyledButton = styled.button`
  height: 40%;
  width: 80%;
  background-color: ${(props) => props.theme.fourthColor};
  outline: 0;
  border: none;
  border-radius: 100px;
  &:focus {
    border: none !important;
    outline: 2px solid ${({ theme }) => theme.mainColor};
  }
`;

export const StyledMainButtonText = styled.text`
  font-size: 1.5rem;
  font-weight: 700;
  color: white;
`;

export const StyledNameText = styled.text`
  font-size: 1rem;
  font-weight: 400;
  color: #7c7c7c;
`;
