import React from 'react';
import { QRCodeCanvas } from 'qrcode.react';
import { useSearchParams, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import QueryString from 'qs';
import { useState, useEffect } from 'react';

const PcQrPage = () => {
  const [qrValue, setQrValue] = useState('');
  const [user, setUser] = useState('');
  const location = useLocation();
  useEffect(() => {
    const queryData = QueryString.parse(location.search, {
      ignoreQueryPrefix: true,
    });
    const params = new URLSearchParams(location.search);
    const user = params.get('user');
    setUser(user === null ? '' : user);
  }, []);

  useEffect(() => {
    setQrValue('https://test.winelotteshopping.com?user=' + user);
    // setQrValue('https://www.winelotteshopping.com?user=' + user);
  }, [user]);

  return (
    <PcQrPageBackground>
      <PcQrPageBody>
        <PcQrPageTitle>
          임직원 와인 추천 서비스는 pc 에서 사용하실 수 없으므로, QR 코드를 통해
          모바일 웹으로 접속 바랍니다.
        </PcQrPageTitle>
        <QRCodeCanvas value={qrValue} />
      </PcQrPageBody>
    </PcQrPageBackground>
  );
};

const PcQrPageBackground = styled.div`
  text-align: center;
`;

const PcQrPageBody = styled.div`
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
`;

const PcQrPageTitle = styled.text`
  margin: 30px;
`;

export default PcQrPage;
