import React, { memo, useEffect } from 'react';
import {
  StyledPassWordInputBox,
  StyledItemWrapper,
  StyledBtnImgWrapper,
  StyledBtnImg,
} from './styles';
import useLogin from 'hooks/useLogin';
import { StyledSpace } from 'utils/styledSpace';
import MainModal from 'components/MainModal';
import PortalForMainModal from 'components/MainModal/PortalForMainModal';

const PasswordChange = () => {
  const {
    passwordValue,
    changePassword,
    originPasswordValue,
    openUploadModalForPassword,
    logout,
  } = useLogin();

  const MainModalContentForPassword = () => {
    return (
      <PortalForMainModal>
        <MainModal type={12} onClose={logout} onYes={logout} onNo={logout} />
      </PortalForMainModal>
    );
  };

  return (
    <>
      <StyledItemWrapper>
        <StyledPassWordInputBox
          type="password"
          onChange={(e) => {
            originPasswordValue.current = e.target.value;
          }}
          placeholder={'Origin password'}
          maxLength={16}
        />
        <StyledSpace height="5vh" />
        <StyledPassWordInputBox
          type="password"
          onChange={(e) => {
            passwordValue.current[0] = e.target.value;
          }}
          placeholder={'Change password'}
          maxLength={16}
        />
        <StyledSpace height="5vh" />
        <StyledPassWordInputBox
          type="password"
          onChange={(e) => {
            passwordValue.current[1] = e.target.value;
          }}
          placeholder={'Confirm password'}
          maxLength={16}
        />
      </StyledItemWrapper>

      <StyledBtnImgWrapper>
        <StyledBtnImg
          src={process.env.PUBLIC_URL + '/icons/passwordChange.png'}
          onClick={changePassword}
        />
      </StyledBtnImgWrapper>
      {openUploadModalForPassword && MainModalContentForPassword()}
    </>
  );
};

export default memo(PasswordChange);
