import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const StyledModalBackground = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  text-align: center;
  position: fixed;
  left: 0;
  top: 0;
  background-color: ${({ theme }) => theme.black}95;
  z-index: 9999999 !important;
  margin-top: 10vh;
`;

export const StyledModal = styled.div<{
  width: string;
  height: string;
}>`
  position: relative;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.backGroundColor};
  border: 2px solid ${(props) => props.theme.fourthColor};
`;

export const StyledItemWrapper = styled(Link)<{ selected?: boolean }>`
  height: 20%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) =>
    props.selected ? props.theme.fourthColor : props.theme.background};
`;

export const StyledItemText = styled.text<{ selected?: boolean }>`
  height: 20%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  font-weight: bold;
  color: ${(props) => (props.selected ? 'white' : props.theme.fourthColor)};
`;

export const StyledButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  gap: 16px;
  margin-top: auto;
`;

export const StyledModalContent = styled.div`
  width: 100%;
  height: 100%;
`;

export const StyledCloseImage = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  margin-right: 12px;
  margin-top: 12px;
  cursor: pointer;
  background: url("data:image/svg+xml,%3Csvg width='16px' height='16px' viewBox='0 0 16 16' class='bi bi-x' fill='currentColor' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' d='M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E")
    no-repeat right top;
  background-size: 36px;
  width: 36px;
  height: 36px;
`;
