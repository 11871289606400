import React, { memo, useState, useEffect } from 'react';
import {
  StyledMainWrapper,
  StyledMidWrapper,
  StyledButtonWrapper,
  StyledButton,
  StyledMainTextImgWrapper,
  StyledMainTextImg,
  StyledAuthBtnImgWrapper,
  StyledAuthBtnImg,
  StyledProgressWrapper,
} from './styles';
import { StyledSpace } from 'utils/styledSpace';
import useNiceAuth from 'hooks/useNiceAuth';
import CircularProgress from '@mui/material/CircularProgress';

const NiceAuth = () => {
  const {
    goToNiceAuth,
    adultCheck,
    checkAdultAuth,
    navigate,
    loadProgress,
    adultCheckValidation,
  } = useNiceAuth();

  useEffect(() => {
    setInterval(() => {
      if (!adultCheckValidation.current) {
        checkAdultAuth();
      }
    }, 2000);
  }, []);

  return (
    <StyledMainWrapper>
      {loadProgress && (
        <StyledProgressWrapper>
          <CircularProgress size={100} thickness={10} />
        </StyledProgressWrapper>
      )}
      <StyledSpace height="5%" />
      <StyledMidWrapper>
        <StyledMainTextImgWrapper>
          <StyledMainTextImg src={process.env.PUBLIC_URL + '/icons/auth.png'} />
        </StyledMainTextImgWrapper>
        <StyledAuthBtnImgWrapper>
          <StyledAuthBtnImg
            src={
              !adultCheck
                ? process.env.PUBLIC_URL + '/icons/cellphone.png'
                : process.env.PUBLIC_URL + '/icons/cellphone2.png'
            }
            onClick={() => {
              if (!adultCheck) {
                goToNiceAuth();
              }
            }}
          />
        </StyledAuthBtnImgWrapper>
      </StyledMidWrapper>
      <StyledSpace height="3%" />
      <StyledButtonWrapper>
        <StyledButton
          adultCheck={adultCheck}
          onClick={() => {
            if (adultCheck) {
              navigate('/main');
            }
          }}>
          홈화면으로 가기
        </StyledButton>
      </StyledButtonWrapper>
    </StyledMainWrapper>
  );
};

export default memo(NiceAuth);
