import React, { memo, Dispatch, SetStateAction, useEffect } from 'react';
import { StyledSpace } from 'utils/styledSpace';
import {
  StyledBigBoxCardWrapper,
  StyledItemWrapper,
  StyledText,
  StyledCardImg,
  StyledCardTextWrapper,
  StyledCardMainText,
  StyledCardSubText,
  StyledBigBoxWrapper,
  StyledButton,
  StyledMBTIImgWrapper,
  StyledMBTIImg,
  StyledRecommendTextImg,
  StyledSubscriptionImgWrapper,
  StyledSubscriptionImg,
  StyledBtnImgWrapper,
  StyledBtnImg,
} from './styles';
import { IMbtiResult } from 'types/Utils.type';
import { NavigateFunction } from 'react-router-dom';

interface Props {
  mbtiResult: IMbtiResult;
  stepHandler: Dispatch<SetStateAction<number>>;
  navigate: NavigateFunction;
  getSubscriptionList: () => void;
}

const TestWineResult = ({
  mbtiResult,
  stepHandler,
  navigate,
  getSubscriptionList,
}: Props) => {
  return (
    <>
      <StyledSpace height="5%" />
      <StyledMBTIImgWrapper>
        <StyledMBTIImg
          src={
            process.env.PUBLIC_URL + '/icons/mbti' + mbtiResult.type + '.png'
          }
        />
      </StyledMBTIImgWrapper>

      <StyledSpace height="2%" />
      <StyledRecommendTextImg
        src={process.env.PUBLIC_URL + '/icons/recommendText.png'}
      />

      <StyledSubscriptionImgWrapper>
        <StyledSubscriptionImg
          src={
            process.env.PUBLIC_URL +
            '/icons/coupon' +
            mbtiResult.subscription.split('구독권')[1] +
            '.png'
          }
        />
      </StyledSubscriptionImgWrapper>

      <StyledSpace height="5%" />
      <StyledBtnImgWrapper
        onClick={() => {
          getSubscriptionList();
        }}>
        <StyledBtnImg
          src={
            process.env.PUBLIC_URL +
            '/icons/mbtiBTN1_' +
            mbtiResult.subscription.split('구독권')[1] +
            '.png'
          }
        />
      </StyledBtnImgWrapper>

      <StyledSpace height="2%" />
      <StyledBtnImgWrapper
        onClick={() => {
          stepHandler(0);
        }}>
        <StyledBtnImg src={process.env.PUBLIC_URL + '/icons/mbtiBTN2.png'} />
      </StyledBtnImgWrapper>

      <StyledSpace height="2%" />
      <StyledBtnImgWrapper
        onClick={() => {
          navigate('/main');
        }}>
        <StyledBtnImg src={process.env.PUBLIC_URL + '/icons/mbtiBTN3.png'} />
      </StyledBtnImgWrapper>
    </>
  );
};

export default memo(TestWineResult);
