import styled from 'styled-components';

export const StyledFooterWrapper = styled.div`
  height: 10vh;
  width: 100%;
  position: fixed;
  top: auto;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #eee5e8;
  border-top: 2px solid #cfcfcf;
  z-index: 999999 !important;
`;

export const StyledSubscriptionButton = styled.img`
  max-height: 90%;
  width: 95%;
  cursor: pointer;
`;
