import { atom } from 'recoil';
import { IYoutubeInfo } from 'types/Main.type';
import { INameValue } from 'types/Utils.type';

export const youtubeUrlAtom = atom<IYoutubeInfo>({
  key: 'youtubeUrlAtom',
  default: {
    youtubeShortsId: 0,
    youtubeShortsUrl: '',
    ordering: 0,
  },
});

export const youtubeUrlListAtom = atom<IYoutubeInfo[]>({
  key: 'youtubeUrlListAtom',
  default: [],
});

export const optionListAtom = atom<INameValue>({
  key: 'optionListAtom',
  default: {
    name: 'HOME',
    value: '/main',
  },
});
