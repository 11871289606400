import { useCallback, useState } from 'react';
import {
  handleGetSubscriptionListByUserId,
  handleUnsubscribe,
} from 'libs/apis/subscription.api';
import { ISubscriptionInfo } from 'types/Subscription.type';
import { useNavigate } from 'react-router-dom';
import { handleCheckCouponUserd } from 'libs/apis/coupon.api';
import { ICouponUsedAndWineInfoRequest } from 'types/Coupon.tpye';
import { MonthSubscriptionId } from 'constants/constants';

const useSubscription = () => {
  const [openUploadModal, setOpenUploadModal] = useState<boolean>(false);
  const userInfo = JSON.parse(String(localStorage.getItem('userInfo')));
  const [subscriptionInfoList, setSubscriptionInfoList] = useState<
    ISubscriptionInfo[]
  >([]);
  const navigate = useNavigate();
  const [selectedSubscription, setSelectedSubscription] =
    useState<ISubscriptionInfo>({
      subscriptionId: 0,
      name: '',
      description: '',
      price: 0,
      month: 0,
    });
  const [openUploadModalForUse, setOpenUploadModalForUse] =
    useState<boolean>(false);
  const [
    openUploadModalForUnSubscription,
    setOpenUploadModalForUnSubscription,
  ] = useState<boolean>(false);
  const [usedCheck, setUsedCheck] = useState<boolean>(false);
  const [wineInfo, setWineInfo] = useState<ICouponUsedAndWineInfoRequest[]>([]);

  const getSubscriptionList = useCallback(async () => {
    try {
      const data: ISubscriptionInfo[] = await handleGetSubscriptionListByUserId(
        userInfo.userId,
      );
      setSubscriptionInfoList(data);
    } catch (e: any) {}
  }, []);

  const handlerUnSubscription = useCallback(
    async (userId: number, subscriptionId: number) => {
      try {
        await handleUnsubscribe(userId, subscriptionId);
        const subscriptionIdList = userInfo.subscriptionIdList.replace(
          subscriptionId + '#',
          '',
        );
        const newUserInfo = {
          ...userInfo,
          subscriptionIdList: subscriptionIdList,
        };
        localStorage.setItem('userInfo', JSON.stringify(newUserInfo));
        navigate('/main');
      } catch (e: any) {}
    },
    [],
  );

  const handlerCheckCouponUserd = useCallback(
    async (subscriptionId: number) => {
      const monthIndex: number = new Date().getMonth() + 1;
      const currentSubscriptionIdTemp = subscriptionId % 5;
      const currentSubscriptionId = MonthSubscriptionId.find(
        (e) => e.value === currentSubscriptionIdTemp,
      )!.ids[monthIndex - 5];
      try {
        const data = await handleCheckCouponUserd(
          userInfo.userId,
          currentSubscriptionId,
        );

        setWineInfo(data);
        if (data[0].status === 'USED') {
          setUsedCheck(true);
        }
      } catch (e: any) {}
    },
    [usedCheck, setUsedCheck],
  );

  return {
    openUploadModal,
    setOpenUploadModal,
    subscriptionInfoList,
    userInfo,
    getSubscriptionList,
    navigate,
    selectedSubscription,
    setSelectedSubscription,
    openUploadModalForUse,
    setOpenUploadModalForUse,
    openUploadModalForUnSubscription,
    setOpenUploadModalForUnSubscription,
    handlerUnSubscription,
    handlerCheckCouponUserd,
    usedCheck,
    wineInfo,
  };
};

export default useSubscription;
