import styled from 'styled-components';

export const StyledMainWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.backGroundColor};
`;

export const StyledButtonWrapper = styled.div`
  width: 100%;
  height: 15vh;
  background-color: ${(props) => props.theme.backGroundColor};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const StyledButton = styled.button`
  height: 50%;
  width: 80%;
  background-color: ${(props) => props.theme.fourthColor};
  outline: 0;
  border: none;
  border-radius: 100px;
  &:focus {
    border: none !important;
    outline: 2px solid ${({ theme }) => theme.mainColor};
  }
`;

export const StyledButtonText = styled.text`
  font-size: 1.5rem;
  font-weight: 700;
  color: white;
`;

export const StyledLoginWrapper = styled.div`
  width: 100%;
  height: 10vh;
  background-color: ${(props) => props.theme.backGroundColor};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
`;

export const StyledLoginText = styled.text`
  font-size: 1rem;
  font-weight: 900;
  color: ${(props) => props.theme.fourthColor};
  text-decoration: underline;
  cursor: pointer;
  &:focus {
    border: none !important;
    outline: 2px solid ${({ theme }) => theme.mainColor};
  }
`;

export const StyledMainImgWrapper = styled.div`
  height: 65vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledMainImg = styled.img`
  width: 95%;
  object-fit: fill;
`;
