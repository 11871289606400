import React, { memo, useEffect, useState, useRef } from 'react';
import {
  StyledMainWrapper,
  StyledVideoBtnWrapper,
  StyledVideoBtn,
  StyledVideoWrapper,
  StyledSommelierInfoImgWrapper,
  StyledSommelierInfoImg,
} from './styles';
import YouTube from 'react-youtube';
import getWidthHeight from 'utils/getWidthHeight';
import Skeleton from '@mui/material/Skeleton';
import useMain from 'hooks/useMain';
import { StyledSpace } from 'utils/styledSpace';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';

const Recommend = () => {
  const { useWidthHeight } = getWidthHeight();
  const { getYoutubeUrlList, youtueUrlList } = useMain();
  const [onload, setOnload] = useState<boolean>(false);
  const currentVideo = useRef<number>(0);
  const [checkOnload, setCheckOnload] = useState<boolean>(true);
  const video1Status = useRef<any>(null);
  const video2Status = useRef<any>(null);
  const video3Status = useRef<any>(null);
  const firstValidation = useRef<boolean>(false);

  useEffect(() => {
    getYoutubeUrlList();
    setTimeout(() => {
      setOnload(true);
    }, 1000);
  }, []);

  useEffect(() => {
    if (firstValidation.current) {
      if (currentVideo.current === 0) {
        video2Status.current.internalPlayer.stopVideo();
        video3Status.current.internalPlayer.stopVideo();
      } else if (currentVideo.current === 1) {
        video1Status.current.internalPlayer.stopVideo();
        video3Status.current.internalPlayer.stopVideo();
      } else {
        video1Status.current.internalPlayer.stopVideo();
        video2Status.current.internalPlayer.stopVideo();
      }
    } else {
      firstValidation.current = true;
    }
  }, [checkOnload]);

  return (
    <StyledMainWrapper>
      <StyledSommelierInfoImgWrapper>
        <StyledSommelierInfoImg
          src={process.env.PUBLIC_URL + '/icons/sommelierInfo2.png'}
        />
      </StyledSommelierInfoImgWrapper>
      <StyledSpace height="5vh" />
      {onload ? (
        <StyledVideoWrapper>
          <Swiper
            navigation={true}
            modules={[Navigation]}
            onSlideChange={(e) => {
              currentVideo.current = e.realIndex;
              setCheckOnload(!checkOnload);
            }}>
            <SwiperSlide>
              <YouTube
                ref={video1Status}
                videoId={youtueUrlList[0].youtubeShortsUrl}
                opts={{
                  width: useWidthHeight.width,
                  height: useWidthHeight.height * 0.8,
                  playerVars: {
                    autoplay: 1,
                    rel: 0,
                    controls: 0,
                    modestbranding: 1,
                  },
                }}
              />
            </SwiperSlide>
            <SwiperSlide>
              <YouTube
                ref={video2Status}
                videoId={youtueUrlList[1].youtubeShortsUrl}
                opts={{
                  width: useWidthHeight.width,
                  height: useWidthHeight.height * 0.8,
                  playerVars: {
                    autoplay: 1,
                    rel: 0,
                    controls: 0,
                    modestbranding: 1,
                  },
                }}
              />
            </SwiperSlide>
            <SwiperSlide>
              <YouTube
                ref={video3Status}
                videoId={youtueUrlList[2].youtubeShortsUrl}
                opts={{
                  width: useWidthHeight.width,
                  height: useWidthHeight.height * 0.8,
                  playerVars: {
                    autoplay: 1,
                    rel: 0,
                    controls: 0,
                    modestbranding: 1,
                  },
                }}
              />
            </SwiperSlide>
          </Swiper>
        </StyledVideoWrapper>
      ) : (
        <Skeleton
          variant="rectangular"
          width={useWidthHeight.width}
          height={useWidthHeight.height * 0.8}
          animation="wave"
        />
      )}
      <StyledVideoBtnWrapper>
        {checkOnload}
        <StyledVideoBtn selected={currentVideo.current === 0} />
        <StyledSpace width="3%" />
        <StyledVideoBtn selected={currentVideo.current === 1} />
        <StyledSpace width="3%" />
        <StyledVideoBtn selected={currentVideo.current === 2} />
      </StyledVideoBtnWrapper>
    </StyledMainWrapper>
  );
};

export default memo(Recommend);
