import React, { memo, useEffect, useState } from 'react';
import Header from 'components/Header';
import { StyledMainWrapper, StyledText } from './styles';
import { StyledSpace } from 'utils/styledSpace';
import useMain from 'hooks/useMain';
import PortalForOption from 'components/common/ModalForOption/PortalForOption';
import ModalForOption from 'components/common/ModalForOption';
import useQueryString from 'hooks/useQueryString';
import { moveToTop } from 'utils/moveToTop';

const Notice = () => {
  const { openUploadModal, setOpenUploadModal } = useMain();
  const { status } = useQueryString();
  const [currentStatus, setCurrentStatus] = useState<string>('');

  useEffect(() => {
    moveToTop();
    if (status === 'use') {
      setCurrentStatus('use');
    } else {
      setCurrentStatus('privacy');
    }
  }, []);

  const ModalContent = () => {
    return (
      <PortalForOption>
        <ModalForOption
          width="50%"
          height="30%"
          isNoButton
          isYesButton
          onClose={() => setOpenUploadModal(false)}
          onNo={() => setOpenUploadModal(false)}
          onYes={() => {}}>
          {/* <StyledModalText>{'삭제하시겠습니까?'}</StyledModalText> */}
        </ModalForOption>
      </PortalForOption>
    );
  };

  return (
    <StyledMainWrapper>
      <Header handler={setOpenUploadModal} />
      <StyledSpace height="30vh" />
      <StyledText>
        {currentStatus === 'privacy'
          ? `개인정보처리방침


롯데쇼핑 주식회사(이하 ‘회사’라 한다)는 개인정보보호법 등 개인정보 보호 관련 법률을 준수하며, 수집된 개인정보 처리현황 및 권리와 의무를 안내하기 위하여 개인정보 처리방침을 제정하여 운영하고 있습니다. 개인정보 처리방침은 관련 법률 및 지침의 변경과 회사 내부 운영방침의 변경에 따라 변경될 수 있으니 회사가 운영하는 사이트(사이트 기재 요망, 이하 ‘사이트’라 한다) 방문 시 수시로 확인하여 주시기 바랍니다. 

제1조(개인정보의 처리목적)
①	회사는 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며, 이용 목적이 변경되는 경우에는 개인정보 보호법 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.
② 개인정보의 처리 목적에 관한 세부항목
1. 회원관리 : 사이트 회원 가입 및 관리 회원 가입의사 확인, 회원제 서비스 제공에 따른 본인 식별, 인증, 회원자격 유지 및 관리, 본인확인, 서비스 부정이용 방지, 각종 고지와 통지, 분쟁조정을 위한 기록 보존 및 불만처리 등을 위한 원활한 의사소통의 경로 확보 등
2. 계약 이행 및 정산 : 상품 또는 서비스 제공, 배송,  계약서 또는 청구서 발송, 콘텐츠 제공, 맞춤서비스 제공, 본인인증, 연령인증, 결제 및 정산, 채권추심 등
3. 신규 서비스 개발 및 마케팅 활용 : 신고서비스 개발 및 맞춤형 서비스 제공, 이벤트 및 광고성 정보 제공, 참여기회 제공, 서비스 이용에 대한 통계 작성 등

제2조(수집하는 개인정보의 항목)
①	 회사는 회원 가입, 주문 등 서비스 제공에 필요한 아래 정보를 수집합니다. 
1.	성명
2.	생년월일
3.	주소
4.	연락처
5.	전자메일 주소
6.	ID 
②	서비스 이용과정에서 수집될 수 있는 개인정보의 범위는 다음과 같습니다.
1.	IP주소, 방문 일시, 쿠키 등의 서비스 이용기록
2.	단말기 식별정보, 단말기 OS정보, 모델명 등 기기정보 및 위치정보
3.	이름, 주소, 연락처 등 물품 배송을 위한 배송지 정보
4.	휴대폰 번호 등 제휴 포인트 적립 시 필요한 정보
5.	카드사, 카드번호, 구매 정보 등 구매 및 요금 결제 시 수집되는 정보
6.	이름, 연락처, 주소 등 경품, 이벤트 참여 시 필요한 정보

제3조(개인정보의 처리 및 보유기간)
① 회사는 법령에 따른 개인정보 보유 및 이용기간 또는 정보주체로부터 개인정보를 수집시에 동의받은 개인정보 보유 및 이용기간 내에서 개인정보를 처리하고 보유합니다.
② 각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.
1. 회원 가입 및 관리 : 회원 탈퇴시까지. 다만, 다음의 사유에 해당하는 경우에는 해당 사유 종료시까지
1) 관계 법령 위반에 따른 수사, 조사 등이 진행중인 경우에는 해당 수사, 조사 종료시까지
2) 서비스 이용에 따른 채권, 채무 관계 잔존시에는 해당 채권, 채무 관계 정산시까지
2. 재화 또는 서비스 제공 : 재화 또는 서비스 공급 완료, 요금결제 및 정산 완료시까지. 

제4조(개인정보의 처리 위탁)
①	 회사는 원활한 업무 처리를 위해 관계법령에 따라 회원의 동의를 얻거나 관련 사항을 공개 또는 고지 후 회원의 개인정보를 외부에 위탁하여 처리하고 있습니다.
②	회사는 위탁계약 체결 시 개인정보가 안전하게 관리될 수 있도록 필요한 사항을 규정해 관계법령을 준수하고 이를 감독하고 있습니다.

제5조(개인정보의 제3자 제공)
①	 회사는 원칙적으로 회원의 개인정보를 제3자에게 제공하지 않으며, 개인정보를 제3자에게 제공해야 하는 경우 법령에 따른 동의를 받고 있습니다. 
②	회사는 회원의 개인정보를 제1조(개인정보의 처리 목적)에서 명시한 범위 내에서만 처리하며, 정보주체의 동의, 법률의 특별한 규정 등 개인정보보호법 제17조 및 제18조에 해당하는 경우에만 개인정보를 제3자에게 제공합니다.

제6조(정보주체(회원 및 회원의 법정대리인)의 권리, 의무 및 행사방법)
① 정보주체는 회사에 대해 언제든지 다음 각 호의 개인정보 보호 관련 권리(개인정보 열람 요구, 오류 등이 있을 경우 정정요구, 삭제요구, 처리 정지 요구)를 행사할 수 있습니다. 
② 제1항에 따른 권리 행사는 회사에 대해 서면, 전화, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며 회사는 이에 대해 지체없이 조치하겠습니다.
③ 정보주체가 개인정보의 오류 등에 대한 정정 또는 삭제를 요구한 경우에는 회사는 정정 또는 삭제를 완료할 때까지 당해 개인정보를 이용하거나 제공하지 않습니다.
④ 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다. 이 경우 개인정보 보호법 시행규칙 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.
⑤ 정보주체는 개인정보 보호법 등 관계법령을 위반하여 회사가 처리하고 있는 정보주체 본인이나 타인의 개인정보 및 사생활을 침해해서는 안됩니다.
⑥ 회원은 개인정보를 보호받을 권리와 함께 스스로를 보호하고 타인의 정보를 침해하지 않을 의무도 가지고 있습니다.
1. 회원이 입력한 부정확한 정보로 인해 발생하는 사고의 책임은 회원 자신에게 있으며 타인 정보의 도용 등 허위정보를 입력할 경우 회원자격이 상실될 수 있습니다.
2. 회원이 자발적으로 공개한 개인정보로 인해 회원 개인에게 발생하는 손실이나 문제는 전적으로 회원 개인의 책임이며, 회사의 고의 또는 과실에 의한 것이 아닌 한 이에 대한 어떠한 책임도 부담하지 않습니다.
3. 회원은 비밀번호를 포함한 회원의 개인정보가 유출되지 않도록 하고 관계법령에 따라 게시물을 포함한 타인의 개인정보를 훼손하지 않도록 유의하여야 합니다.

제7조(개인정보의 파기)
① 회사는 개인정보의 수집 및 제공받은 목적이 달성된 경우, 고객의 동의 철회, 해당 서비스의 폐지, 사업의 종료 등 그 개인정보가 불필요하게 되었을 경우에는 해당 개인정보를 지체 없이 파기합니다.


②	 제 1 항 에도 불구하고, 회사는 회원의 요청에 따라 기간을 달리 정한 경우에는 그 기간 동안 해당 개인정보를 보존하고, 관련법령에 의해 개인정보를 보존할 필요가 있는 경우에는 다음의 기간 동안 보존합니다.
1. 계약 또는 청약철회 등에 관한 기록: 5년(전자상거래 등에서의 소비자보호에 관한 법률)
2. 대금결제 및 재화 등의 공급에 관한 기록: 5년(전자상거래 등에서의 소비자보호에 관한 법률)
3. 소비자의 불만 또는 분쟁처리에 관한 기록: 3년(전자상거래 등에서의 소비자보호에 관한 법률)
4. 전자금융 거래에 관한 기록: 5년(전자금융거래법)
5. 거래정보의 수집/처리 및 이용 등에 관한 기록: 5년(신용정보의 이용 및 보호에 관한 법률)
6. 서비스 접속 및 이용기록: 3개월(통신비밀보호법)
③ 개인의 정보를 파기하는 때에는 다음과 같은 방법에 의하고 있습니다.
1. 파기 절차
- 서비스 이용을 위해 입력하신 정보는 목적이 달성된 후 별도의 DB로 옮겨져(종이의 경우 별도의 서류함) 내부 방침 및 관련 법령에 의한 사유에 따라 일정 기간 저장된 후 파기됩니다.
2. 파기방법
- 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기하고, 전자적 파일형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.
④ 회사는 개인정보 유효기간제에 따라1년간 서비스를 이용하지 않은 회원의 개인정보를 별도로 분리 보관하여 관리하고 있습니다. 분리, 보관된 회원의 개인정보는 관련 법령에 따라 일정기간 보유하며, 해당 기간 종료 후 파기됩니다.
⑤ 개인정보의 파일 30일전 까지 개인정보가 분리 보관되는 사실을 이메일로 이용자에게 통하고 있습니다.
   - 분리보관 항목 : 이름, 휴대폰번호, 주소

제8조(개인정보의 자동 수집 장치의 설치/운영 및 거부에 관한 사항)
① 회사는 쿠키를 설치, 운영하고 있고 이용자는 이를 거부할 수 있습니다. 쿠키는 HTTP서버에서 이용자의 브라우저에 보내는 소량의 정보입니다. 쿠키는 이용자의 컴퓨터는 식별하지만 회원을 개인적으로 식별하지 않습니다.
② 이용자는 웹브라우저에서 옵션을 조정하여 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 모든 쿠키의 저장을 거부할 수 있습니다. 다만, 쿠키의 저장을 거부할 경우에는 로그인이 필요한 회사 일부 서비스는 이용에 어려움이 있을 수 있습니다.
1. Safari를 사용하는 경우
가. Mac OS 상단 좌측 메뉴바에서 [Safari] ->[환경설정]을 선택
나. [환경설정] 창에서 [보안]으로 이동하여 쿠키 허용여부 선택

2. Chrome을 사용하는 경우
가. 브라우저 툴바의 공구모양 아이콘 클릭
나. [옵션(Mac과 Linux에서는 환경설정, 크롬북에서는 설정)]을 선택
다. [고급설정] 탭을 클릭
라. 콘텐츠 설정 대화상자가 나타나면 [쿠키]탭을 클릭
마. 쿠키 차단 관련한 선택 사항을 선택

3. Edge를 사용하는 경우
가. 브라우저 오른쪽 상단 설정(톱니바퀴) 아이콘 클릭
나. 업데이트 및 보안(자물쇠 모양) 아이콘 클릭
다. 쿠키 차단 관련한 선택사항을 선택

제9조(개인정보의 안전성 확보조치)
회사는 회원의 개인정보를 처리함에 있어 개인정보가 분실, 도난, 유출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 기술적/관리적/물리적 보호대책을 강구하고 있습니다
1. 정기적인 자체 감사 실시
- 개인정보 취급 관련 안정성 확보를 위해 정기적으로 자체 감사를 실시하고 있습니다.
2. 개인정보 취급 직원의 최소화 및 교육
- 개인정보를 취급하는 직원을 지정하고 담당자에 한정시켜 최소화하여 개인정보를 관리하는 대책을 시행하고 있습니다.
3. 개인정보보호 규정의 수립 및 시행
- 개인정보의 안전한 처리를 위하여 개인정보보호 규정을 수립하고 시행하고 있습니다.
4. 해킹 등에 대비한 기술적 대책
- 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여 보안프로그램을 설치하고 주기적인 갱신∙점검을 하며 외부로부터 접근이 통제된 구역에 시스템을 설치하고 기술적 / 물리적으로 감시 및 차단하고 있습니다.
5. 개인정보의 암호화
- 개인정보 중 고유식별번호, 비밀번호는 암호화 되어 저장 및 관리되고 있어, 중요한 데이터는 파일 및 전송 데이터를 암호화 하거나 파일 잠금 기능을 사용하는 등의 별도 보안기능을 사용하고 있습니다.
6. 접속기록의 보관 및 위·변조 방지
- 개인정보처리시스템에 접속한 기록을 최소 1년 이상 보관, 관리하고 있으며, 접속 기록이 위·변조 및 도난, 분실되지 않도록 보안기능을 사용하고 있습니다.
7. 개인정보에 대한 접근 제한
- 개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의 부여, 변경, 말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한 조치를 하고 있으며 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있습니다.
8. 문서보안을 위한 잠금장치 사용
- 개인정보가 포함된 서류, 보조저장매체 등을 잠금 장치가 있는 안전한 장소에 보관하고 있습니다.
9. 비인가자에 대한 출입 통제
- 개인정보를 보관하고 있는 물리적 보관 장소를 별도로 두고 이에 대해 출입통제 절차를 수립, 운영하고 있습니다.
10.그 밖의 정보보호 활동
- 안전한 고객정보 관리를 위해 정보보호 관리체계(ISMS) 인증을 취득 및 운영하고 있습니다.

제10조(개인정보 보호책임자)
① 회사는 회원의 개인정보를 보호하고 개인정보와 관련된 불만사항 및 문의를 처리하기 위하여 아래와 같이 개인정보보호 책임자 및 개인정보보호 담당자를 지정하고 있습니다.
[개인정보 책임자]
·이름 : 김종환 
·소속 : 롯데백화점 본점
·직위 : 점장
·연락처 02-772-3862: 
[개인정보보호 담당부서/연락처]
·부서명 :롯데백화점 본점 영업기획팀
·연락처 : 02-772-3862
② 정보주체는 개인정보침해로 인한 구제를 받기 위하여 개인정보분쟁조정위원회, 한국인터넷진흥원 개인정보침해신고센터 등에 분쟁 해결이나 상담 등을 신청할 수 있습니다. 이 밖에 기타 개인정보침해의 신고, 상담에 대하여는 아래의 기관에 문의하시기 바랍니다.
1. 개인정보분쟁조정위원회 : (국번없이) 1833-6972 (www.kopico.go.kr)
2. 개인정보침해신고센터 : (국번없이) 118 (privacy.kisa.or.kr)
3. 대검찰청 사이버수사과 : (국번없이) 1301 (www.spo.go.kr)
4. 사이버범죄 신고시스템(ECRM) : (국번없이) 182 (ecrm.cyber.go.kr)

제11조(고지의 의무)
①	현 개인정보 처리방침은 2023. 5. 15 부터 적용됩니다. 내용의 추가, 삭제 및 수정이 있을 시에는 개정 최소 7일전부터 홈페이지의 공지사항을 통하여 고지할 것입니다.
②	개인정보의 수집 및 활용, 제3자 제공 등이 변경으로 개인정보처리방침이 변경된 경우에는 회원에게 별도의 동의를 받습니다.
`
          : `이용약관


제1조(목적)
이 약관은 롯데쇼핑(주)(이하 “회사“라 한다.)이 회사의 임직원을 대상으로 운영하는 사이트, 온라인쇼핑몰 및 매장에서 제공하는 관련 서비스(모바일 및 인터넷 관련 서비스 포함, 이하 “서비스“라 한다)를 이용함에 있어 회사와 회사의 서비스를 이용하는 자간의 권리, 의무 및 책임사항을 규정함을 목적으로 합니다. 이용자가 되고자 하는 자가 회사가 정한 소정의 절차를 거쳐서 “이용약관 동의” 버튼에 체크하면 본 약관에 동의하는 것으로 간주합니다. 


제2조(정의)
①	“사이트”란 회사가 재화 또는 용역(이하 “재화 등“이라 함)을 이용자에게 제공하기 위하여 운영하는 가상의 영업장을 의미하며, 아울러 사이트를 운영하는 사업자의 의미로도 사용됩니다.
②	 “이용자“란 이 약관에 따라 회사가 제공하는 서비스를 받는 회사 임직원 회원을 말합니다.
③	“회원“이라 함은 회사에 개인정보를 제공하여 회원등록을 한 자로서, 회사의 정보를 지속적으로 제공받으며, 회사가 제공하는 서비스를 계속적으로 이용할 수 있는 자를 말합니다.
④ “정기구독 서비스”라 함은, 회사에서 시행하고 있는 서비스로 회원이 구독예약을 통해 신청완료 후 매달  롯데백화점 오프라인 매장(이하 “매장”)에서 주류 상품을 받아 가는 서비스를 의미합니다.
⑤ 본 조에서 정의되지 아니한 이 약관상의 용어의 의미는 일반적인 거래 관행에 의합니다.

제3조 (약관 등의 명시와 설명 및 개정)
①	회사는 이 약관의 내용과 상호 및 대표자 성명, 영업소 소재지 주소(소비자의 불만을 처리할 수 있는 곳의 주소를 포함), 전화번호, 전자우편주소, 사업자등록번호, 통신판매업신고번호, 개인정보 처리방침, 개인정보관리책임자 등을 이용자가 쉽게 알 수 있도록 회사의 초기 서비스화면(전면)에 게시합니다. 다만, 약관, 개인정보 처리방침의 내용은 이용자가 연결화면을 통하여 볼 수 있도록 할 수 있습니다.
②	회사는 「전자상거래 등에서의 소비자보호에 관한 법률」, 「약관의 규제에 관한 법률」, 「전자문서 및 전자거래기본법」, 「전자금융거래법」, 「전자서명법」, 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」, 「방문판매 등에 관한 법률」, 「소비자기본법」 등 관련 법을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.
③	회사가 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행약관과 함께 사이트의 초기화면에 그 적용일자 7일 이전부터 적용일자 전일까지 공지합니다. 다만, 이용자에게 불리하게 약관내용을 변경하는 경우에는 최소한 30일 이상의 사전 유예기간을 두고 공지합니다. 이 경우 “롯데쇼핑’은 개정 전 내용과 개정 후 내용을 명확하게 비교하여 이용자가 알기 쉽도록 표시합니다.
④	약관의 변경 내용 공지 및 유예기간이 지난 후에도 계속적으로 서비스를 이용하시면 이용자가 개정내용에 동의한 것으로 간주합니다.
⑤	이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 전자상거래 등에서의 소비자보호에 관한 법률, 약관의 규제 등에 관한 법률, 공정거래위원회가 정하는 전자상거래 등에서의 소비자 보호지침 및 관계법령 또는 상관례에 따릅니다.

제4조 (서비스의 제공 및 변경)
①	회사는 다음과 같은 업무를 수행합니다.
1. 재화 또는 용역에 대한 정보 제공 및 구매계약의 체결
2. 구매 계약이 체결된 재화 또는 용역의 제공
3. 기타 회사가 정하는 업무
②	회사는 이용자에게 제공하는 재화 등이 이용자의 개별적 특성(신체적 특성 포함)에 적합하다거나 이용자의 개별적 이용목적에 부합할 것이라는 점에 대하여 어떠한 보증도 하지 않습니다.
③	회사는 상품 또는 용역의 품절 또는 기술적 사양의 변경 등의 경우에는 장차 체결되는 계약에 의해 제공할 상품 또는 용역의 내용을 변경할 수 있습니다. 이 경우에는 변경된 상품 또는 용역의 내용 및 제공일자를 명시하여 현재의 상품 또는 용역의 내용을 게시한 곳에 즉시 공지합니다. 
④	회사가 제공하기로 이용자와 계약을 체결한 서비스의 내용을 재화 등의 품절 또는 기술적 사양의 변경 등의 사유로 변경할 경우에는 그 사유를 이용자에게 통지 가능한 주소로 즉시 통지합니다.
⑤	본 조 제4항의 경우 회사는 이로 인하여 이용자가 입은 손해를 배상합니다. 단, 회사에 고의 또는 과실이 없는 경우에는 그러하지 아니합니다.

제5조 (서비스의 중단)
①	본 약관에 따른 서비스 기간은 회원이 본 약관에 대해 동의한 날로부터 회사의 회원탈퇴(해지) 요청 수리 및 모든 권리·의무의 이행이 종료된 날까지입니다.
②	회사는 컴퓨터 등 정보통신설비의 보수점검, 교체 및 고장, 통신의 두절 등의 사유가 발생한 경우에는 서비스의 제공을 일시적으로 중단할 수 있습니다. 이 경우 서비스 일시 중단 사실과 그 사유를 제8조에 정한 방법으로 이용자에게 통지합니다.
③	회사는 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 서비스의 제공을 제한하거나 일시 중단할 수 있습니다.
④	사업종목의 전환, 사업의 포기, 업체 간의 통합 등의 이유로 서비스를 일시적 또는 영구적으로 중단할 수 있으며, 이 경우 회사는  제8조에 정한 방법으로 이용자에게 통지합니다.

제6조 (회원가입)
①	회원은 최소 만 19세 이상이어야 하며 회원이 19세 미만이라고 의심되는 경우 이용자 계정과 관련 정보는 별도의 통지나 예고 없이 삭제될 수 있습니다.
②	이용자는 회사가 정한 가입 양식에 따라 회원정보를 기입한 후 이 약관에 동의한다는 의사표시를 함으로서 회원가입을 신청합니다.
③	회사는 제1항과 같이 회원으로 가입할 것을 신청한 이용자 중 다음 각호에 해당하지 않는 한 회원으로 등록합니다.
1.	가입신청자가 이 약관 제7조 제3항에 의하여 이전에 회원자격을 상실한 적이 있는 경우, 다만 제7조 제2항에 의한 회원자격 상실 후 3년이 경과한 자로서 회사의 회원재가입 승낙을 얻은 경우에는 예외로 한다.
2.	등록 내용에 허위, 기재누락, 오기가 있는 경우
3.	기타 회원으로 등록하는 것이 회사의 기술상 현저히 지장이 있다고 판단되는 경우
④	회원가입계약의 성립시기는 회사의 승낙이 이용자에게 도달한 시점으로 합니다.
⑤	회원은 회원가입 시 등록한 등록사항에 변경이 있는 경우, 상당한 기간 이내에 회사에 회원정보 수정 등의 방법으로 그 변경사항을 알려야 합니다. 이를 게을리하여 발생하는 회원의 불이익이 있는 경우 이는 회사의 책임으로 보지 않습니다.

제7조(회원 탈퇴 및 자격 상실 등)
①	회원은 회사에 언제든지 탈퇴를 요청하여 이용계약을 해지할 수 있습니다. 단, 해지의사를 통지하기 전에 현재 진행중인 모든 상품의 거래를 완료하거나 철회 또는 취소하여야 하며, 거래의 철회 또는 취소로 인한 불이익은 회원 본인이 부담하여야 합니다. 회원 탈퇴로 인해 발생한 불이익에 대한 책임은 회원 본인이 져야 하며, 이용계약이 종료되면 회사는 회원에게 부가적으로 제공한 각종 혜택을 회수할 수 있습니다.
②	회원이 다음 각호의 사유에 해당하는 경우, 회사는 회원자격을 제한 및 정지시킬 수 있습니다.
1.	가입 신청 시에 허위 내용을 등록한 경우
2.	서비스를 이용하여 구입한 재화 등의 대금, 기타 서비스 이용에 관련하여 회원이 부담하는 채무를 기일에 지급하지 않는 경우
3.	다른 사람의 서비스 이용을 방해하거나 그 정보를 도용하는 등 전자상거래 질서를 위협하는 경우
4.	서비스를 이용하여 법령 또는 이 약관이 금지하거나 공서양속에 반하는 행위를 하는 경우
5.	서비스의 원활한 진행을 방해하는 행위를 하거나 시도한 경우
6.	기타 회사가 합리적인 판단에 기하여 서비스의 제공을 거부할 필요가 있다고 인정할 경우
③	회사가 회원 자격을 제한/정지 시킨 후, 동일한 행위가 2회 이상 반복되거나 30일 이내에 그 사유가 시정되지 아니하는 경우 회사는 회원자격을 상실시킬 수 있습니다.
④	회사가 회원자격을 상실시키는 경우에는 회원등록을 말소합니다. 이 경우 회원에게 이를 통지하고, 회원등록 말소전에 최소한 30일 이상의 기간을 정하여 소명할 기회를 부여합니다.

제8조(이용자에 대한 통지)
①	회사가 이용자에 대한 통지를 하는 경우, 이용자가 회사와 미리 약정하여 지정한 문자메시지나 알림톡의 수신번호 또는 전자우편 주소 등으로 할 수 있습니다.
②	회사는 불특정다수 이용자에 대한 통지의 경우 1주일 이상 사이트 게시판에 게시함으로서 개별 통지에 갈음할 수 있습니다. 다만, 이용자 본인의 거래와 관련하여 중대한 영향을 미치는 사항에 대하여는 개별통지를 합니다.

제9조 (이용자의 서비스 이용)
①	이용자는 사이트 또는 시스템을 통하여 서비스 이용을 신청하며, 회사는 이용자가 서비스 신청을 함에 있어서 다음의 각 내용을 알기 쉽게 제공하여야 합니다.
1.	재화 등의 검색 및  
2.	약관내용, 청약 철회권이 제한되는 서비스, 배송료 등의 비용 부담과 관련한 내용에 대한 확인
3.	서비스 이용신청 및 이에 관한 확인 
4.	회사 또는 매장의 확인요청 사항에 대한 동의
5.	결제방법의 선택
6.	기타 관련 법령에 따라 필요한 사항
②	이용자는 서비스 이용 의사 없이 서비스 이용표시를 하여서는 안되며, 이를 통하여 다른 이용자의 서비스 이용 기회를 방해한 것이 적발되는 경우 회사는 해당 계정에 대하여 회원 자격을 정지하는 등의 조치를 취할 수 있습니다.
③	이용자는 서비스를 이용하기 전에 반드시 안내된 거래의 조건을 정확하게 확인한 후 서비스 이용을 하여야 합니다. 이용하려는 거래의 조건을 확인하지 않고 서비스 이용으로 인하여 발생한 모든 손실, 손해는 이용자 본인에게 있습니다.
④	이용자는 이용한 서비스에 청약철회의 원인이 발생한 경우 수령한 상품을 임의로 사용하거나 훼손되도록 방치하여서는 안됩니다. 청약철회 상품의 임의 사용이나 상품 보관의 미흡으로 인한 상품훼손에 대하여 합당한 비용을 부담하여야 합니다.
⑤	이용자는 서비스 이용신청 시 결제 방법을 사용함에 있어 반드시 본인 명의의 결제수단을 사용하여야 하며, 타인의 결제 수단의 임의 사용 등을 하여서는 안됩니다. 타인의 결제수단을 임의 사용함으로써 발생하는 회사, 결제수단 소유자의 손실과 손해에 대한 모든 책임은 이용자에게 있습니다.
⑥	서비스 이용대금의 결제와 관련하여 이용자가 입력한 정보 및 그 정보와 관련하여 발생한 책임과 불이익은 전적으로 이용자가 부담하여야 합니다.
⑦	회사는 이용자가 이용대금 결제 시 사용한 결제 수단에 대해 정당한 사용권한을 가지고 있는지의 여부를 확인할 수 있으며, 이에 대한 확인이 완료될 때까지 거래 진행을 중지하거나 해당 거래를 취소할 수 있습니다.
⑧	회사가 제3자에게 이용자의 개인정보를 제공할 필요가 있는 경우 1) 개인정보를 제공받는 자, 2)개인정보를 제공받는 자의 개인정보 이용목적, 3) 제공하는 개인정보의 항목, 4) 개인정보를 제공받는 자의 개인정보 보유 및 이용기간을 이용자에게 알리고 동의를 받아야 하며, 이는 이미 동의를 받은 사항이 변경되는 경우에도 동일하게 적용합니다. 단, 관련 법령에 달리 정함이 있거나 관련 법령이 개정되는 경우 그에 따릅니다.
⑨	회사가 제3자에게 이용자의 개인정보를 취급할 수 있도록 업무를 위탁하는 경우에는 1) 개인정보 취급위탁을 받는 자, 2) 개인정보 취급위탁을 하는 업무의 내용을 구매회원에게 알리고 동의를 받아야 하며, 이는 이미 기존에 동의를 받은 사항이 변경되는 경우에도 동일하게 적용합니다. 다만, 서비스 제공에 관한 계약이행을 위해 필요하고 이용자의 편의증진과 관련된 경우에는 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」에서 정하고 있는 방법으로 개인정보 처리방침을 통해 알림으로써 고지절차와 동의절차를 거치지 않아도 됩니다.

제10조 (계약의 성립)
①	“정기구독 서비스” 또는 상품 등의 매매계약은 제9조와 같은 서비스 이용신청에 대하여 회사가 승낙의 의사표시를 함으로써 체결됩니다. 다만, 회사는 다음 각호에 해당하면 승낙하지 않을 수 있습니다.
1.	신청 내용에 허위, 기재누락, 오기가 있는 경우
2.	미성년자가 담배, 주류 등 청소년보호법에서 금지하는 재화 및 용역을 구매하는 경우
3.	회원자격이 제한 및 정지된 회원의 신청임이 확인된 경우
4.	기타 이용신청에 승낙하는 것이 회사의 기술상 현저히 지장이 있다고 판단하는 경우
②	회사의 승낙이 온라인 상 수신확인 통지형태로 이용자에게 도달한 시점에 계약이 성립한 것으로 봅니다.
③	회사의 승낙의 의사표시에는 이용자의 서비스 이용 신청에 대한 확인 및 판매가능 여부, 서비스 이용신청의 정정 취소 등에 관한 정보 등을 포함하여야 합니다.

제11조 (지급방법)
상품 대금 또는 “정기구독 서비스” 이용에 따른 재화 또는 용역에 대한 대금지급방법은 선불카드, 직불카드, 신용카드로만 할 수 있습니다.

제12조 (수신 확인 통지/서비스 이용신청 변경 및 취소)
①	 이용자의 “정기구독서비스 이용 신청이 있는 경우 이용자에게 수신확인통지를 합니다.
②	수신확인통지를 받은 이용자는 의사표시의 불일치 등이 있는 경우에는 수신확인통지를 받은 후 즉시 서비스 이용신청 변경 및 취소를 요청할 수 있고 회사는 상품 배송 전에 이용자의 요청이 있는 경우에는 지체 없이 그 요청에 따라 처리하여야 합니다. 다만 이미 대금을 지불한 경우에는 제15조의 청약철회 등에 관한 규정에 따릅니다.

제13조 (상품 등의 공급)
①	상품의 수령기간은 결제일로부터 당월 말일까지의 기간을 말합니다. 화재, 홍수, 지진, 폭풍, 전쟁, 혁명, 정부의 규제 등 불가항력에 의해 본 계약에 기초한 의무를 이행할 수 없는 경우 회사는 이로 인한 이행지체 또는 불이행의 책임을 부담하지 않습니다.
②	회사는 이용자와 상품 등의 공급시기에 관하여 별도의 약정이 없는 이상 이용자의 결제일 당일부터 상품 등을 수령할 수 있도록 포장 등 기타의 필요한 조치를 취합니다. 
③	회사는 이용자에게 상품의 수령 방법, 수령기간 등을 명시하여 안내하여야 합니다.
④	이용자는 롯데백화점 본점 주류 매장에서만 상품을 수령할 수 있습니다.

제14조(반품/교환/환불/취소/)
①	 이용자는 상품 수령 완료 후 이용자의 변심 또는 훼손 등에 의한 반품/교환을 할 수 없습니다.
②	회사는 결제 대금의 환불이 발생하는 경우 거래가 취소된 날로부터 3 영업일 이내에 이용자에게 환불에 필요한 조치를 합니다. 카드 결제를 통한 서비스 이용건의 환불은 카드결제 취소를 통해서만 가능합니다. 이는 “정기구독 서비스”도 동일하게 적용됩니다.
③	회사는 이용자가 구매 신청한 상품 등이 품절 등의 사유로 인도 또는 제공을 할 수 없을 때에는 지체 없이 그 사유를 이용자에게 통지하고 사전에 재화 등의 대금을 받은 경우에는 대금을 받은 날부터 3 영업일 이내에 환급하거나 환급에 필요한 조치를 취합니다.
④	회원은 상품을 수령하기 *영업일전까지 구입을 취소할 수 있습니다. 단, “정기구독 서비스”는 본 조 타 항에서 별도 명시하는 바와 같습니다.
⑤	“정기구독 서비스”의 경우 구독 예약 시 설정한 익월 결제일의 *영업일 전까지 취소가 가능합니다. 이 경우 제15조 제1항의 청약 철회를 제외하고는, 익월부터 서비스가 취소됩니다.
⑥	“정기구독 서비스”의 경우  당월 결제일로부터 익월 결제일의 *영업일 전까지는 중지가 가능하며 그 이후 중지를 요청하시는 경우 익월부터 서비스가 중지됩니다.

제15조(청약철회 등)
①	회사와 “정기구독 서비스” 등 이용에 관한 계약을 체결한 이용자는 수신확인의 통지를 받은 날부터 7일 이내에는 청약의 철회를 할 수 있습니다. 단, 상품을 수령한 이후에는 청약 철회가 불가능합니다.
②	이용자는 상품 등의 내용이 계약내용과 다르게 이행된 때에는 당해 상품 등을 수령한 날부터 3월 이내, 그 사실을 안 날 또는 알 수 있었던 날부터 30일 이내에 청약철회 등을 할 수 있습니다.
③	상품 등의 내용이 표시·광고 내용과 다르거나 계약내용과 다르게 이행되어 청약철회를 하는 경우 상품 등의 반환에 필요한 비용은 회사가 부담합니다.
④	이용자가 상품 등을 배송 받은 후, 다음 각호의 1에 해당하는 경우에는 반품 및 교환을 할 수 없습니다.
1.	이용자에게 책임 있는 사유로 상품 등이 멸실 또는 훼손된 경우
2.	이용자의 사용 또는 일부 소비에 의하여 재화 등의 가치가 현저히 감소한 경우
3.	이용자의 단순 변심

제17조(개인정보보호)
①	 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」, 「개인정보 보호법」 등 관계법령이 정하는 바에 따라 이용자의 개인정보를 보호하기 위하여 노력합니다.
②	 회사의 개인정보 보호 등에 관한 자세한 사항은 관계법령 및 “개인정보처리방침”을 준용합니다. 

제18조(회사의 의무)
①	회사는 법령과 이 약관이 금지하거나 공서양속에 반하는 행위를 하지 않으며 이 약관이 정하는 바에 따라 지속적이고, 안정적으로 상품과 서비스를 제공하는데 최선을 다하여야 합니다.
②	회사는 이용자가 안전하게 인터넷 서비스를 이용할 수 있도록 이용자의 개인정보(신용정보 포함)보호를 위한 보안 시스템을 갖추어야 합니다.

제19조(회원의 ID 및 비밀번호에 대한 의무)
①	제17조 및 제18조의 경우를 제외한 ID와 비밀번호에 관한 관리책임은 회원에게 있습니다.
②	회원은 자신의 ID 및 비밀번호를 제3자에게 양도 또는 대여 등의 방법으로 이용하게 해서는 안됩니다.
③	회사의 귀책사유 없이 ID 또는 비밀번호의 유출, 양도, 대여로 인하여 발생하는 손실이나 손해에 대하여는 회원 본인이 그에 대한 책임을 부 
④	회원이 자신의 ID 및 비밀번호를 도난 당하거나 제3자가 사용하고 있음을 인지한 경우에는 바로 회사에 통보하고 회사의 안내가 있는 경우에는 그에 따라야 합니다.

제20조(이용자의 의무)
이용자는 다음 행위를 하여서는 안됩니다.
1.	개인정보의 등록 또는 변경 시 허위 내용의 등록
2.	타인의 개인정보, 결제정보 등의 임의 수집 또는 도용
3.	회사가 사이트 또는 매장에 게시된 정보의 변경
4.	회사가 정한 정보 이외의 정보(컴퓨터 프로그램 등) 등의 송신 또는 게시
5.	회사 및 기타 제3자의 저작권 등 지적재산권에 대한 침해
6.	회사 및 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위
7.	외설 또는 폭력적인 메시지, 화상, 음성, 기타 공서양속에 반하는 정보를 회사가 운영하는 사이트 또는 매장에 공개 또는 게시하는 행위
8.	기타 관련법령이나 회사의 각 세부 서비스 정책에 위반되는 행위

제21조(지식재산권의 귀속 및 이용제한)
①	회사가 작성한 저작물에 대한 저작권 기타 지적재산권은 회사에 귀속합니다.
②	이용자는 회사의 사이트, 매장 또는 회사가 제공하는 서비스를 이용함으로써 얻은 정보 중 회사에게 지적재산권이 귀속된 정보를 회사의 사전 승낙 없이 복제, 송신, 출판, 배포, 방송 기타 방법에 의하여 영리목적으로 이용하거나 제3자에게 이용하게 하여서는 안됩니다.
③	이용자가 회사의 사이트 혹은 매장 이용시 작성한 상품평, 상품 관련 질문 등의 게시물에 대한 저작권 및 이에 대한 모든 책임은 이용자에게 귀속합니다. 단, 회사는 이용자에게 서비스를 제공하기 위하여, 해당 게시물에 대한 저장, 복제, 수정, 공중 송신, 전시, 배포, 2차적 저작물 작성 등의 이용 권한(기한과 지역 제한에 정함이 없으며, 별도 대가 지급이 없는 라이선스)을 부여 받습니다. 회사는 이러한 이용 권한에 따라, 사이트 및 매장, 온/오프라인 제휴매장에서, 저작권법 등 관련 법령에서 정하는 범위 내에서 이용자 게시물의 노출, 회사의 각종 전자상거래 관련 서비스 홍보를 위한 활용, 서비스 운영, 개선 및 새로운 서비스 개발을 위한 연구, 상품 관련 정보 통합 관리 및 상품 마케팅을 위한 목적으로 활용할 수 있습니다.
④	회사는 이용자가 작성한 게시물이 각 호에 해당하는 경우 사전 통지 없이 해당 게시물을 삭제하는 등 관련법령이나 회사의 방침에 따라 조치할 수 있으며 이에 대해 회사는 어떠한 책임도 지지 않습니다.
1. 관련법령을 위반하는 내용을 포함하는 경우
2. 관련법령에 의거 판매가 금지된 불법제품 또는 음란물을 게시, 광고하는 경우
3. 허위 또는 과대광고의 내용을 포함하는 경우
4. 타인의 권리나 명예, 신용 기타 정당한 이익을 침해하는 경우
5. 직거래 유도 또는 타 사이트의 링크를 게시하는 경우
6. 정보통신기기의 오작동을 일으킬 수 있는 악성코드나 데이터를 포함하는 경우
7. 사회 공공질서나 미풍양속에 위배되는 경우
8. 범죄행위와 관련된 내용을 포함하는 경우
9. 정치, 경제적 분쟁을 야기하는 내용을 포함하는 경우
10. 기타 회사가 제공하는 서비스의 원활한 진행을 방해하는 것으로 판단되는 경우


제22조(분쟁해결)
①	회사를 통한 거래에 따른 분쟁은 이용자 간의 자율적인 해결이 원칙입니다.
②	회사는 회사와 이용자, 이용자간 또는 이용자와 제3자 사이의 분쟁의 합리적이고 원활한 조정을 위하여 고객센터를 통하여 예외적으로 해당 분쟁에 개입할 수 있습니다.
③	이용자는 고객센터의 결정을 신의성실의 원칙에 따라 최대한 존중해야 합니다.
④	이용자는 고객센터를 통하여 해결되지 않는 분쟁에 대해 공정거래위원회 또는 시·도지사가 의뢰하는 분쟁조정기관의 조정기관에 피해구체신청을 의뢰할 수 있으며, 귀책 당사자는 해당 기관의 조정에 따를 수 있습니다.

제23조(재판관할 및 준거법)
①	회사와 이용자간에 발생한 분쟁에 관한 소송은 서울중앙지방법원을 전속 관할로 합니다. 
②	회사와 이용자 간에 제기된 소송에는 대한민국법령을 적용합니다.


본 약관은 2023년 5월 15일부터 시행됩니다.
`}
      </StyledText>
      <StyledSpace height="10vh" />
      {openUploadModal && ModalContent()}
    </StyledMainWrapper>
  );
};

export default memo(Notice);
