import React, { memo, useEffect } from 'react';
import { StyledSpace } from 'utils/styledSpace';
import {
  StyledMainWrapper,
  StyledStepperWrapper,
  StyledLine,
  StyledCheckedLine,
  StyledCheckedCircle,
  StyledCheckedBigCircle,
  StyledMainImgWrapper,
  StyledMainImg,
  StyledSelectImgWrapper,
  StyledSelectImg,
  StyledQuestionImg,
} from './styles';
import useTestWine from 'hooks/useTestWine';
import { MbtiQuestionAndAnswer } from 'constants/constants';
import TestWineResult from './TestWineResult';
import { moveToTop } from 'utils/moveToTop';

const TestWine = () => {
  const {
    currentStep,
    setCurrentStep,
    navigate,
    selectAnswer,
    mbtiResult,
    getSubscriptionList,
  } = useTestWine();

  useEffect(() => {
    moveToTop();
  }, []);

  return (
    <StyledMainWrapper>
      {currentStep === 0 && (
        <>
          <StyledMainImgWrapper>
            <StyledMainImg
              src={process.env.PUBLIC_URL + '/icons/testMain.png'}
            />
          </StyledMainImgWrapper>

          <StyledSpace height="10vh" />
          <StyledSelectImgWrapper
            onClick={() => {
              setCurrentStep(currentStep + 1);
            }}>
            <StyledSelectImg
              src={process.env.PUBLIC_URL + '/icons/test_1.png'}
            />
          </StyledSelectImgWrapper>

          <StyledSpace height="2vh" />
          <StyledSelectImgWrapper
            onClick={() => {
              navigate('/main');
            }}>
            <StyledSelectImg
              src={process.env.PUBLIC_URL + '/icons/test_2.png'}
            />
          </StyledSelectImgWrapper>
        </>
      )}
      {currentStep !== 0 && currentStep <= 7 && (
        <>
          <StyledStepperWrapper>
            <StyledLine />
            <StyledCheckedLine width={String(13.3 * (currentStep - 1)) + '%'} />
            <StyledCheckedCircle margin={'9%'} selected={currentStep === 1} />
            <StyledCheckedCircle
              margin={'22.3%'}
              selected={currentStep === 2}
            />
            <StyledCheckedCircle
              margin={'35.6%'}
              selected={currentStep === 3}
            />
            <StyledCheckedCircle
              margin={'48.9%'}
              selected={currentStep === 4}
            />
            <StyledCheckedCircle
              margin={'62.2%'}
              selected={currentStep === 5}
            />
            <StyledCheckedCircle
              margin={'75.5%'}
              selected={currentStep === 6}
            />
            <StyledCheckedCircle
              margin={'88.8%'}
              selected={currentStep === 7}
            />
            <StyledCheckedBigCircle
              margin={'7%'}
              selected={currentStep === 1}
            />
            <StyledCheckedBigCircle
              margin={'20.3%'}
              selected={currentStep === 2}
            />
            <StyledCheckedBigCircle
              margin={'33.6%'}
              selected={currentStep === 3}
            />
            <StyledCheckedBigCircle
              margin={'46.9%'}
              selected={currentStep === 4}
            />
            <StyledCheckedBigCircle
              margin={'60.2%'}
              selected={currentStep === 5}
            />
            <StyledCheckedBigCircle
              margin={'73.5%'}
              selected={currentStep === 6}
            />
            <StyledCheckedBigCircle
              margin={'86.8%'}
              selected={currentStep === 7}
            />
          </StyledStepperWrapper>

          <StyledSpace height="3vh" />
          <StyledQuestionImg
            src={process.env.PUBLIC_URL + '/icons/Q' + currentStep + '.png'}
          />

          <StyledSpace height="8vh" />
          <StyledSelectImgWrapper
            onClick={() => {
              selectAnswer(
                'A',
                currentStep,
                MbtiQuestionAndAnswer.find((e) => e.value === currentStep)!
                  .type,
              );
            }}>
            <StyledSelectImg
              src={process.env.PUBLIC_URL + '/icons/Q' + currentStep + '_1.png'}
            />
          </StyledSelectImgWrapper>

          <StyledSpace height="2vh" />
          <StyledSelectImgWrapper
            onClick={() => {
              selectAnswer(
                'B',
                currentStep,
                MbtiQuestionAndAnswer.find((e) => e.value === currentStep)!
                  .type,
              );
            }}>
            <StyledSelectImg
              src={process.env.PUBLIC_URL + '/icons/Q' + currentStep + '_2.png'}
            />
          </StyledSelectImgWrapper>
        </>
      )}
      {currentStep === 8 && (
        <TestWineResult
          mbtiResult={mbtiResult.current}
          stepHandler={setCurrentStep}
          navigate={navigate}
          getSubscriptionList={getSubscriptionList}
        />
      )}
    </StyledMainWrapper>
  );
};

export default memo(TestWine);
