import React, { memo, useEffect } from 'react';
import Introduce from './Introduce';
import Method from './Method';
import Recommend from './Recommend';
import Header from 'components/Header';
import {
  StyledTabWrapper,
  StyledPageWrapper,
  StyledMainWrapper,
  StyledTabImg1,
  StyledTabImg2,
  StyledTabImg3,
} from './styles';
import Footer from 'components/Footer';
import useMain from 'hooks/useMain';
import PortalForOption from 'components/common/ModalForOption/PortalForOption';
import ModalForOption from 'components/common/ModalForOption';
import ModalForNotice from './NoticeModal';
import PortalForNoticeModal from './NoticeModal/PortalForNoticeModal';
import { optionListAtom } from 'store/main';
import { Resetter, useResetRecoilState } from 'recoil';
import { StyledSpace } from 'utils/styledSpace';
import { moveToTop } from 'utils/moveToTop';

const Main = () => {
  const {
    value,
    setValue,
    openUploadModal,
    setOpenUploadModal,
    navigate,
    openUploadModalForNotice,
    setOpenUploadModalForNotice,
    closeNoticeOneDay,
    getNoticeValidation,
    popUpImageUrl,
    popUpImageConnectLinkUrl,
  } = useMain();
  const resetSelectedOption: Resetter = useResetRecoilState(optionListAtom);

  useEffect(() => {
    resetSelectedOption();
    getNoticeValidation();
  }, []);

  useEffect(() => {
    moveToTop();
  }, [value]);

  const ModalForNoitce = () => {
    return (
      <PortalForNoticeModal>
        <ModalForNotice
          onClose={() => setOpenUploadModalForNotice(false)}
          onYes={closeNoticeOneDay}
          url={popUpImageUrl}
          connectLink={popUpImageConnectLinkUrl}
        />
      </PortalForNoticeModal>
    );
  };

  const ModalContent = () => {
    return (
      <PortalForOption>
        <ModalForOption
          width="50%"
          height="30%"
          isNoButton
          isYesButton
          onClose={() => setOpenUploadModal(false)}
          onNo={() => setOpenUploadModal(false)}
          onYes={() => {}}>
          {/* <StyledModalText>{'삭제하시겠습니까?'}</StyledModalText> */}
        </ModalForOption>
      </PortalForOption>
    );
  };

  return (
    <StyledMainWrapper>
      <Header handler={setOpenUploadModal} />
      <StyledTabWrapper>
        <StyledTabImg1
          src={
            value === 0
              ? process.env.PUBLIC_URL + '/icons/tab1Y.png'
              : process.env.PUBLIC_URL + '/icons/tab1N.png'
          }
          onClick={() => {
            setValue(0);
          }}
        />
        <StyledSpace width="0.5%" />
        <StyledTabImg2
          src={
            value === 1
              ? process.env.PUBLIC_URL + '/icons/tab2Y.png'
              : process.env.PUBLIC_URL + '/icons/tab2N.png'
          }
          onClick={() => {
            setValue(1);
          }}
        />
        <StyledSpace width="0.5%" />
        <StyledTabImg3
          src={
            value === 2
              ? process.env.PUBLIC_URL + '/icons/tab3Y.png'
              : process.env.PUBLIC_URL + '/icons/tab3N.png'
          }
          onClick={() => {
            setValue(2);
          }}
        />
      </StyledTabWrapper>

      <StyledSpace height="15vh" />
      <StyledPageWrapper>
        {value === 0 && <Introduce navigate={navigate} />}
        {value === 1 && <Method />}
        {value === 2 && <Recommend />}
      </StyledPageWrapper>

      <Footer
        handleClick={() => {
          navigate('/billing');
        }}
      />
      {openUploadModal && ModalContent()}
      {openUploadModalForNotice && ModalForNoitce()}
    </StyledMainWrapper>
  );
};

export default memo(Main);
