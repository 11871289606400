import React, { memo } from 'react';
import {
  StyledMainImg,
  StyledMainImgWrapper,
  StyledMainWrapper,
  StyledInfoWrapper,
  StyledInfoTextWrapper,
  StyledAllImgWrapper,
  StyledAllImg,
  StyledTestWineImgWrapper,
  StyledTestWineImg,
  StyledMain2Img,
  StyledMain4ImgWrapper,
  StyledMain4Img,
} from './styles';
import { StyledSpace } from 'utils/styledSpace';
import { NavigateFunction } from 'react-router-dom';

interface Props {
  navigate: NavigateFunction;
}

const Introduce = ({ navigate }: Props) => {
  return (
    <StyledMainWrapper>
      <StyledSpace height="2vh" />
      <StyledMainImgWrapper>
        <StyledMainImg
          src={process.env.PUBLIC_URL + '/icons/main/introduce_202403.png'}
        />
      </StyledMainImgWrapper>

      <StyledSpace height="5vh" />
      <StyledAllImgWrapper>
        <StyledAllImg src={process.env.PUBLIC_URL + '/icons/main/main1.png'} />
      </StyledAllImgWrapper>

      <StyledSpace height="5vh" />
      <StyledAllImgWrapper>
        <StyledMain2Img
          src={process.env.PUBLIC_URL + '/icons/main/main2.png'}
        />
      </StyledAllImgWrapper>
      <StyledTestWineImgWrapper
        onClick={() => {
          navigate('/testwine');
        }}>
        <StyledTestWineImg
          src={process.env.PUBLIC_URL + '/icons/main/main3.png'}
        />
      </StyledTestWineImgWrapper>

      <StyledSpace height="5vh" />
      <StyledInfoWrapper>
        <StyledSpace height="5%" backGroundColor="white" />
        <StyledInfoTextWrapper
          style={{ display: 'flex', flexDirection: 'row' }}>
          <text
            onClick={() => {
              navigate('/notice?status=use');
            }}
            style={{
              fontWeight: '900',
              fontSize: '1rem',
              textDecoration: 'underline',
              color: '#6C3B52',
            }}>
            {'이용약관'}
          </text>
          <StyledSpace width="10%" backGroundColor="white" />
          <text
            onClick={() => {
              navigate('/notice?status=privacy');
            }}
            style={{
              fontWeight: '900',
              fontSize: '1rem',
              textDecoration: 'underline',
              color: '#6C3B52',
            }}>
            {'개인정보처리방침'}
          </text>
        </StyledInfoTextWrapper>
        <StyledSpace height="5%" backGroundColor="white" />
        <StyledMain4ImgWrapper>
          <StyledMain4Img
            src={process.env.PUBLIC_URL + '/icons/main/main4.png'}
          />
        </StyledMain4ImgWrapper>
      </StyledInfoWrapper>
      <StyledSpace height="15vh" />
    </StyledMainWrapper>
  );
};

export default memo(Introduce);
