import customAxios from 'libs/CustomAxios';
import {
  ILogin,
  ILoginResponse,
  ISignUpRequest,
  IAudltCheckResponse,
  IMaintainResponse,
} from 'types/Login.type';

export const handlePostLogin = async (req: ILogin): Promise<ILoginResponse> => {
  const url: string = `/auth/login`;
  const data: ILoginResponse = await customAxios.post(url, req);
  return data;
};

export const handleSignUp = async (req: ISignUpRequest): Promise<any> => {
  const url: string = `/auth/signUp`;
  const data: any = await customAxios.post(url, req);
  return data;
};

export const handleAuthCheck = async (
  userId: number,
): Promise<IAudltCheckResponse> => {
  const url: string = `/user/isAdult?userId=${userId}`;
  const { data }: { data: IAudltCheckResponse } = await customAxios.get(url);
  return data;
};

export const handleCardCheck = async (userId: number): Promise<string> => {
  const url: string = `/user/cardCheck?userId=${userId}`;
  const { data } = await customAxios.get(url);
  return data.data;
};

export const handleMaintain = async (): Promise<IMaintainResponse> => {
  const url: string = `/maintain`;
  const { data } = await customAxios.get(url);
  return data.data;
};
