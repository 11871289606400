import React, { memo, useState, useEffect } from 'react';
import Header from 'components/Header';
import {
  StyledMainWrapper,
  StyledTopWrapper,
  StyledBottomWrapper,
  StyledCarImgWrapper,
  StyledCarImg,
  StyledCardTextWrapper,
  StyledCardTextWrapper1,
  StyledCardTextWrapper2,
  StyledCardText1,
  StyledCardText2,
} from './styles';
import { StyledSpace } from 'utils/styledSpace';
import PortalForOption from 'components/common/ModalForOption/PortalForOption';
import ModalForOption from 'components/common/ModalForOption';
import convertFN from 'utils/convertFN';
import useBilling from 'hooks/useBilling';
import { IBillingInfo } from 'types/Billing.type';
import { moveToTop } from 'utils/moveToTop';

const BillingInfo = () => {
  const {
    getBillingInfo,
    openUploadModal,
    setOpenUploadModal,
    billingInfoList,
  } = useBilling();
  const { convertMoneyString } = convertFN();

  useEffect(() => {
    moveToTop();
    getBillingInfo();
  }, []);

  const ModalContent = () => {
    return (
      <PortalForOption>
        <ModalForOption
          width="50%"
          height="30%"
          isNoButton
          isYesButton
          onClose={() => setOpenUploadModal(false)}
          onNo={() => setOpenUploadModal(false)}
          onYes={() => {}}>
          {/* <StyledModalText>{'삭제하시겠습니까?'}</StyledModalText> */}
        </ModalForOption>
      </PortalForOption>
    );
  };

  return (
    <StyledMainWrapper>
      <Header handler={setOpenUploadModal} />

      <StyledSpace height="5vh" />
      <StyledBottomWrapper>
        {billingInfoList && billingInfoList.length !== 0 ? (
          billingInfoList.map((item: IBillingInfo, index: number) => {
            return (
              <StyledCarImgWrapper key={index}>
                <StyledCarImg
                  src={
                    process.env.PUBLIC_URL +
                    '/icons/billinginfo/sub' +
                    item.subscriptionName
                      .split('구독권')[1]
                      .split(' ')[1]
                      .split(':')[0] +
                    '.png'
                  }
                />
                <StyledCardTextWrapper>
                  <StyledCardTextWrapper1>
                    <StyledCardText1>
                      {item.dateTime.split('T')[0] +
                        ' ' +
                        item.dateTime.split('T')[1].substring(0, 5) +
                        ' | ' +
                        item.cardCompany +
                        '카드'}
                    </StyledCardText1>
                  </StyledCardTextWrapper1>
                  <StyledCardTextWrapper2>
                    <StyledCardText2>
                      {convertMoneyString(String(item.amount)) + '원'}
                    </StyledCardText2>
                  </StyledCardTextWrapper2>
                </StyledCardTextWrapper>
              </StyledCarImgWrapper>
            );
          })
        ) : (
          <StyledTopWrapper>
            <text style={{ fontSize: '1rem', fontWeight: '700' }}>
              현재 결제 내역이 없습니다
            </text>
          </StyledTopWrapper>
        )}
      </StyledBottomWrapper>
      {openUploadModal && ModalContent()}
    </StyledMainWrapper>
  );
};

export default memo(BillingInfo);
