import React, { memo, useState, useEffect } from 'react';
import Header from 'components/Header';
import {
  StyledMainWrapper,
  StyledTopWrapper,
  StyledCheckImgWrapper,
  StyledCheckImg,
  StyledTopTextWrapper,
  StyledTopText,
  StyledBottomWrapper,
  StyledBtnWrapper,
  StyledBtn,
  StyledBottomHeaderAndFooter,
  StyledBottomCardWrapper,
  StyledCardTextWrapper,
  StyledCardMainText,
  StyledCardSubText,
  StyledCardImg,
  StyledOptionWrapper,
  StyledOptionValueWrapper,
  StyledOptionTextWrapper,
  StyledOptionText,
  StyledOptionValueTextWrapper,
  StyledOptionValueText,
  StyledAmountTextWrapper,
  StyledAmountWrapper,
  StyledAmount,
  StyledImgWrapper,
  StyledSuccessTopButtonWrapper,
  StyledSuccessBottomButton,
  StyledSuccesButtonWrapper,
  StyledSuccessBottomText,
  StyledSuccessTopButton,
  StyledSuccesTopText,
} from './styles';
import { StyledSpace } from 'utils/styledSpace';
import useQueryString from 'hooks/useQueryString';
import useBilling from 'hooks/useBilling';
import convertFN from 'utils/convertFN';
import PortalForOption from 'components/common/ModalForOption/PortalForOption';
import ModalForOption from 'components/common/ModalForOption';

const BillingSuccess = () => {
  const { status, authKey } = useQueryString();
  const {
    paySubscription,
    selectedSubscription,
    checkPayValidation,
    navigate,
    createBillingKey,
    savedSubscription,
    openUploadModal,
    setOpenUploadModal,
  } = useBilling();
  const { convertMoneyString, getCurrentDateWithKorean } = convertFN();
  const kindOfSubscription = String(localStorage.getItem('kind'));

  useEffect(() => {
    if (status === 'success') {
      createBillingKey(String(authKey));
    }
  }, []);

  const ModalContent = () => {
    return (
      <PortalForOption>
        <ModalForOption
          width="50%"
          height="30%"
          isNoButton
          isYesButton
          onClose={() => setOpenUploadModal(false)}
          onNo={() => setOpenUploadModal(false)}
          onYes={() => {}}>
          {/* <StyledModalText>{'삭제하시겠습니까?'}</StyledModalText> */}
        </ModalForOption>
      </PortalForOption>
    );
  };

  return (
    <StyledMainWrapper>
      <Header handler={setOpenUploadModal} />
      <StyledSpace height="5%" />
      <StyledTopWrapper>
        <StyledCheckImgWrapper>
          <StyledCheckImg
            src={
              checkPayValidation
                ? process.env.PUBLIC_URL + '/icons/smileIcon.png'
                : process.env.PUBLIC_URL + '/icons/checkIcon.png'
            }
          />
        </StyledCheckImgWrapper>
        <StyledTopTextWrapper>
          <StyledTopText>
            {checkPayValidation
              ? kindOfSubscription === '당월'
                ? '결제가 정상적으로\n  완료되었습니다!'
                : '구독이 정상적으로\n  완료되었습니다!'
              : '구독이 정상적으로\n  완료되었습니다!'}
          </StyledTopText>
        </StyledTopTextWrapper>
      </StyledTopWrapper>

      <StyledSpace height="3%" />
      <StyledBottomWrapper>
        <StyledBottomHeaderAndFooter>
          <text
            style={{ fontSize: '2rem', fontWeight: '700', paddingLeft: '5%' }}>
            구독상품
          </text>
        </StyledBottomHeaderAndFooter>
        <StyledBottomCardWrapper>
          <StyledImgWrapper>
            <StyledCardImg
              src={
                savedSubscription.name.split('구독권')[1] === 'B' ||
                savedSubscription.name.split('구독권')[1] === 'D'
                  ? process.env.PUBLIC_URL + '/icons/wineImg.png'
                  : process.env.PUBLIC_URL + '/icons/wine2Img.png'
              }
              size={
                savedSubscription.name === 'B' || savedSubscription.name === 'D'
                  ? true
                  : false
              }
            />
          </StyledImgWrapper>
          <StyledCardTextWrapper>
            <StyledCardMainText>{savedSubscription.name}</StyledCardMainText>
            <StyledSpace width="1%" backGroundColor="white" />
            <StyledCardSubText>
              {savedSubscription.description}
            </StyledCardSubText>
          </StyledCardTextWrapper>
        </StyledBottomCardWrapper>
        <StyledBottomCardWrapper>
          <StyledOptionWrapper>
            <StyledOptionTextWrapper>
              <StyledOptionText>{'구독옵션'}</StyledOptionText>
            </StyledOptionTextWrapper>
            <StyledOptionTextWrapper>
              <StyledOptionText>{'구독일자'}</StyledOptionText>
            </StyledOptionTextWrapper>
          </StyledOptionWrapper>
          <StyledOptionValueWrapper>
            <StyledOptionValueTextWrapper>
              <StyledOptionValueText>
                {kindOfSubscription === '당월' ? '당월구독  ' : '익월구독  '}
              </StyledOptionValueText>
              <text
                style={{
                  fontSize: '1rem',
                  fontWeight: '400',
                  color: '#7C7C7C',
                }}>
                {kindOfSubscription === '당월'
                  ? '(당월부터 와인수령)'
                  : '(익월부터 와인수령)'}
              </text>
            </StyledOptionValueTextWrapper>
            <StyledOptionValueTextWrapper>
              <StyledOptionValueText>
                {getCurrentDateWithKorean(kindOfSubscription)}
              </StyledOptionValueText>
            </StyledOptionValueTextWrapper>
          </StyledOptionValueWrapper>
        </StyledBottomCardWrapper>

        <StyledBottomHeaderAndFooter>
          <StyledAmountTextWrapper>
            <StyledOptionTextWrapper>
              <StyledOptionText>{'구독금액'}</StyledOptionText>
            </StyledOptionTextWrapper>
          </StyledAmountTextWrapper>
          <StyledAmountWrapper>
            <StyledAmount>
              {convertMoneyString(String(savedSubscription.price)) + '원'}
            </StyledAmount>
          </StyledAmountWrapper>
        </StyledBottomHeaderAndFooter>
      </StyledBottomWrapper>

      <StyledSpace height="3vh" />
      <StyledBtnWrapper>
        <StyledSuccessTopButtonWrapper>
          <StyledSuccessTopButton
            onClick={() => {
              navigate('/subscriptioninfo');
            }}>
            <StyledSuccesTopText>구독 내역</StyledSuccesTopText>
          </StyledSuccessTopButton>
          <StyledSuccessTopButton
            onClick={() => {
              navigate('/coupon');
            }}>
            <StyledSuccesTopText>교환권 내역</StyledSuccesTopText>
          </StyledSuccessTopButton>
        </StyledSuccessTopButtonWrapper>
        <StyledSpace height="20%" />
        <StyledSuccessBottomButton
          onClick={() => {
            navigate('/main');
          }}>
          <StyledSuccessBottomText>메인 화면</StyledSuccessBottomText>
        </StyledSuccessBottomButton>
      </StyledBtnWrapper>

      {openUploadModal && ModalContent()}
    </StyledMainWrapper>
  );
};

export default memo(BillingSuccess);
